import { DataFrame, Field, FieldType } from '@grafana/data';
import { ASTNode, Kind, parse } from 'graphql';
import { GraphQLResponse, WideSkyQueryJSONTarget } from 'types';
import { Column, Config, FLATTEN_NONE_VALUE } from './config';

export function createTableData(
    graphQLRoot: Readonly<GraphQLResponse>,
    target: WideSkyQueryJSONTarget
) {
    const newColumnOptions = processQuery(target.query);
    if (target.setColumnOptions) {
        target.setColumnOptions(Array.from(newColumnOptions));
    }

    const config = target.config as Config | undefined;

    if (config?.columns === undefined) {
        throw Error('No initial column');
    }

    // Make a mutable copy of the current columns
    const columnsCopy = config.columns.slice();
    const initialColumn = columnsCopy.at(0)?.base;

    if (!initialColumn) {
        throw Error('No initial column');
    }

    if (!(graphQLRoot.haystack instanceof Object)) {
        throw Error(
            'Graph response Haystack value is not an object. Check Query Inspector for more info'
        );
    }

    const rootTableNode = new TableNode();
    createGraphTable(graphQLRoot.haystack, columnsCopy, rootTableNode);
    performFlatten(rootTableNode, columnsCopy, config.fillFlatten);

    const rootNodes = rootTableNode.findChildren(initialColumn);
    const floatingNodes = columnsCopy
        .filter(
            (column, index) =>
                (column.flatten === undefined || column.flatten === FLATTEN_NONE_VALUE) &&
                column.base !== undefined &&
                index !== 0
        )
        .map((column) => rootTableNode.findChildren(column.base!));
    const allNodes: TableNode[] = rootNodes.concat(...floatingNodes);

    const rows: Cell[][] = allNodes.flatMap((node) =>
        node.values.length === 0 ? [[node.value]] : node.values.map<Cell[]>((value) => value)
    );

    const sortedColumns = sortColumns(columnsCopy);

    const fields: Field[] = sortedColumns.map((column, index) => {
        const values = rows.map((row) => (row[index] === undefined ? '' : row[index]));
        const firstValue = values.find((value) => value !== '');
        let type: FieldType = FieldType.other;

        if (firstValue !== undefined) {
            switch (typeof firstValue) {
                case 'string':
                    type = FieldType.string;
                    if (!isNaN(Number(firstValue))) {
                        type = FieldType.number;
                    }
                    break;
                case 'number':
                    type = FieldType.number;
                    break;
                case 'boolean':
                    type = FieldType.boolean;
                    break;
            }
        }

        return {
            type,
            values,
            config: {},
            name: column,
        };
    });

    const data: DataFrame = {
        fields,
        meta: {
            preferredVisualisationType: 'table',
        },
        length: rows.length,
    };

    return [data];
}

function createGraphTable(
    currentNode: any,
    selectedColumns: Column[],
    parentNode: TableNode,
    branch = 0
) {
    Object.keys(currentNode).forEach((key) => {
        const value = currentNode[key];
        const nextPath = parentNode.path + '.' + key;
        const nextBranch = parentNode.branch + '_' + branch;

        const nextNode = new TableNode(nextPath, nextBranch);
        parentNode.children.push(nextNode);

        if (value instanceof Array) {
            let isAllValues = true;
            value.forEach((valueNode, index) => {
                if (valueNode instanceof Array || valueNode instanceof Object) {
                    createGraphTable(valueNode, selectedColumns, nextNode, index);
                    isAllValues = false;
                }
            });

            if (!isAllValues) {
                return;
            }

            // Edge case: tagSummary
            parentNode.children = parentNode.children.slice(0, -1);
            const childPath = nextPath;
            const childBranch = parentNode.branch;
            const childNode = new TableNode(childPath, childBranch);
            childNode.value = value.join(', ');
            parentNode.children.push(childNode);
        } else if (value instanceof Object) {
            createGraphTable(value, selectedColumns, nextNode, branch);
        }

        const valueIsInColumn = selectedColumns.some(
            (selectedColumn) => selectedColumn.base === nextPath
        );

        if (valueIsInColumn) {
            nextNode.value = value;
        }
    });
}

function performFlatten(rootNode: TableNode, columns: Column[], fillFlatten?: boolean) {
    // Keep track of the length of rows so that if a value is not present in
    //   a given set it can be filled on the next pass
    const desiredLengthMap: Map<string, number | undefined> = new Map(); // Column flatten => length
    const memoChildNodes: Map<string, TableNode[]> = new Map();

    columns.reverse().forEach((column, index) => {
        if (column.base === undefined || index === columns.length - 1) {
            return;
        }

        if (column.flatten === undefined || column.flatten === FLATTEN_NONE_VALUE) {
            return;
        }

        const sourceNodes = memoChildNodes.has(column.flatten)
            ? memoChildNodes.get(column.flatten)!
            : rootNode.findChildren(column.flatten);

        const allChildren = memoChildNodes.has(column.base)
            ? memoChildNodes.get(column.base)!
            : rootNode.findChildren(column.base);

        const initialLength = desiredLengthMap.get(column.flatten!);

        sourceNodes.forEach((node) => {
            const targetNodes = rootNode.findRelated(node.branch, false, allChildren);
            node.flattenWithRows(targetNodes, fillFlatten, desiredLengthMap.get(column.flatten!));
            desiredLengthMap.set(column.flatten!, node.values.at(0)?.length);
        });

        // There is a case where no values are present in the flatten nodes
        // Add to the initial length and add the padded value to maintain column alignment
        if (initialLength !== undefined && initialLength === desiredLengthMap.get(column.flatten)) {
            const paddingColumn = desiredLengthMap.get(column.base);
            const paddingLength = paddingColumn === undefined ? 1 : paddingColumn;

            desiredLengthMap.set(column.flatten, initialLength + paddingLength);
        }

        // When there are no source nodes we need to add to the desired length of the
        if (sourceNodes.length === 0) {
            const desiredLength = desiredLengthMap.get(column.flatten);
            if (desiredLength === undefined) {
                desiredLengthMap.set(column.flatten, 2);
            }
        }

        const desiredLength = desiredLengthMap.get(column.flatten!);
        sourceNodes.forEach((node) => {
            if (node.values.at(0)?.length !== desiredLength) {
                node.flattenWithRows([], fillFlatten, desiredLength);
            }
        });

        if (!memoChildNodes.has(column.flatten)) {
            memoChildNodes.set(column.flatten, sourceNodes);
        }

        if (!memoChildNodes.has(column.base)) {
            memoChildNodes.set(column.base, allChildren);
        }
    });

    columns.reverse().forEach((column) => {
        if (column.base === undefined) {
            return;
        }

        if (column.flatten === undefined || column.flatten === FLATTEN_NONE_VALUE) {
            return;
        }

        rootNode.findChildren(column.flatten).forEach((node) => {
            const desiredLength = desiredLengthMap.get(column.flatten!);

            if (desiredLength !== undefined && node.values.at(0)!.length < desiredLength) {
                const fillWith = new Array(desiredLength - node.values.at(0)!.length).fill('');
                node.values = node.values.map((value) => [
                    ...value.slice(0, 2),
                    ...fillWith,
                    ...value.slice(2),
                ]);
            }
        });
    });

    // Deal with flatten = 'None' columns
    columns
        .filter(
            (column, index): column is Omit<Column, 'base'> & { base: string } =>
                column.base !== undefined &&
                index !== 0 &&
                (column.flatten === undefined || column.flatten === FLATTEN_NONE_VALUE)
        )
        .forEach((column) => {
            const firstColumn = memoChildNodes.has(columns.at(0)!.base!)
                ? memoChildNodes.get(columns.at(0)!.base!)!
                : rootNode.findChildren(columns.at(0)!.base!);

            const allChildren = memoChildNodes.has(column.base)
                ? memoChildNodes.get(column.base)!
                : rootNode.findChildren(column.base);

            firstColumn.forEach((node) => {
                const targetNodes = rootNode.findRelated(node.branch, true, allChildren);
                node.appendValue(targetNodes);
            });

            if (!memoChildNodes.has(columns.at(0)!.base!)) {
                memoChildNodes.set(columns.at(0)!.base!, firstColumn);
            }

            if (!memoChildNodes.has(column.base)) {
                memoChildNodes.set(column.base, allChildren);
            }
        });
}

function sortColumns(columns: Column[]) {
    const columnReferenceMap = new Map<string, string[]>();
    columns.forEach((column) => {
        if (column.base === undefined) {
            return;
        }

        if (columnReferenceMap.get(column.base) === undefined) {
            columnReferenceMap.set(column.base, []);
        }

        if (column.flatten !== undefined) {
            const columnFlattenReferences = columnReferenceMap.get(column.flatten);
            if (columnFlattenReferences) {
                columnReferenceMap.set(column.flatten, [...columnFlattenReferences, column.base]);
            }
        }
    });

    const sortedColumns: string[] = [];
    const visited = new Set<string>();

    const traverseColumnMap = (key: string) => {
        if (!visited.has(key)) {
            visited.add(key);
            sortedColumns.push(key);
            const children = columnReferenceMap.get(key);
            if (children) {
                children.forEach(traverseColumnMap);
            }
        }
    };

    for (const key of columnReferenceMap.keys()) {
        traverseColumnMap(key);
    }

    return sortedColumns;
}

function processQuery(query: string) {
    if (query.trimStart().at(0) !== '{') {
        query = `{${query}}`;
    }

    const queryNode = parse(query).definitions[0];
    const columns: Set<string> = new Set();

    if (queryNode.kind !== Kind.OPERATION_DEFINITION) {
        return columns;
    }

    const insertColumns = (node: ASTNode, name: string): void => {
        if (node.kind !== Kind.INLINE_FRAGMENT && node.kind !== Kind.FIELD) {
            return;
        }

        const nextName =
            node.kind === Kind.INLINE_FRAGMENT
                ? name
                : `${name}${name ? '.' : ''}${node.alias ? node.alias.value : node.name.value}`;

        if (!node.selectionSet) {
            if (!columns.has(nextName)) {
                columns.add(nextName);
            }
            return;
        }

        node.selectionSet.selections.forEach((subSelection) =>
            insertColumns(subSelection, nextName)
        );
    };

    insertColumns(queryNode.selectionSet.selections[0], '');
    return columns;
}

type Cell = string | number | boolean | undefined;

class TableNode {
    public children: TableNode[] = [];
    public path: string;
    public branch: string;
    public value: Cell;
    public values: Cell[][];

    constructor(path = 'haystack', branch = '0') {
        this.path = path;
        this.branch = branch;
        this.children = [];
        this.values = [];
    }

    public findChildren(path: string): TableNode[] {
        const allChildren: TableNode[] = [];

        this.children.forEach((child) => {
            if (child.path === path) {
                allChildren.push(child);
                return;
            }
            allChildren.push(...child.findChildren(path));
        });

        return allChildren;
    }

    public findRelated(
        targetBranch: string,
        flattenNone: boolean,
        allChildren: TableNode[]
    ): TableNode[] {
        const firstChild = allChildren.at(0);

        // Cover return early cases before doing anything expenseive
        if (firstChild === undefined) {
            return [];
        }

        const sameLineage = allChildren.filter((child) => child.branch === targetBranch);

        if (sameLineage.length !== 0) {
            return sameLineage;
        }

        if (flattenNone) {
            return allChildren;
        }

        const targetBranchArray = targetBranch.split('_');
        const childBranches = allChildren.map((child) => child.branch.split('_'));
        let closestMatch: { nodes: TableNode[]; proximity: number } = { nodes: [], proximity: 0 };

        for (let i = 0; i < allChildren.length; i++) {
            const child = allChildren[i];
            const childBranch = childBranches[i];
            const checkLength = Math.min(targetBranchArray.length, childBranch.length);
            let proximity = 0;

            for (; proximity < checkLength; proximity++) {
                if (targetBranchArray[proximity] !== childBranch[proximity]) {
                    break;
                }
            }

            if (proximity > closestMatch.proximity) {
                closestMatch = { nodes: [child], proximity };
            } else if (proximity === closestMatch.proximity) {
                if (
                    targetBranchArray.length < childBranch.length &&
                    (childBranch[proximity] === targetBranchArray[proximity] ||
                        proximity === targetBranchArray.length)
                ) {
                    closestMatch.nodes.push(child);
                } else {
                    closestMatch.nodes = [];
                }
            }
        }

        // Edge case: There might only be one node in the dataset, the node might be the only match not best match
        if (allChildren.length === 1) {
            const firstChildBranch = childBranches[0];
            const fakeNodeBranch = [
                ...firstChildBranch.slice(0, firstChildBranch.length - 1),
                '-1',
            ];

            const checkLength = Math.min(targetBranchArray.length, fakeNodeBranch.length);
            let proximity = 0;

            for (; proximity < checkLength; proximity++) {
                if (targetBranchArray[proximity] !== fakeNodeBranch[proximity]) {
                    break;
                }
            }

            if (
                proximity === closestMatch.proximity &&
                !(
                    firstChildBranch.at(proximity) === targetBranchArray.at(proximity) ||
                    proximity === targetBranchArray.length
                )
            ) {
                return [];
            }
        }

        return closestMatch.nodes;
    }

    public flattenWithRows(rows: TableNode[], fillFlatten?: boolean, desiredLength?: number) {
        if (rows.length === 0) {
            if (this.values.length === 0) {
                this.values = [[this.value, '']];
            } else if (desiredLength !== undefined) {
                if (desiredLength >= this.values.at(0)!.length) {
                    const fillWith = new Array(desiredLength - this.values.at(0)!.length).fill('');
                    this.values = this.values.map((value) => [
                        value.at(0),
                        ...fillWith,
                        ...value.slice(1),
                    ]);
                }
            } else {
                this.values = this.values.map((value) => [value.at(0), '', ...value.slice(1)]);
            }

            return;
        }

        const rowsHaveBeenFlattened = rows.some((row) => row.values.length !== 0);
        const valuesFn = (row: TableNode) => (rowsHaveBeenFlattened ? row.values[0] : [row.value]);
        const fillFirstIndexWith = fillFlatten === true ? this.value : '';

        if (this.values.length === 0) {
            if (!rowsHaveBeenFlattened) {
                this.values = rows.map((row) => [fillFirstIndexWith, row.value]);
            } else {
                this.values = rows.flatMap((row) =>
                    row.values.map((values) => [fillFirstIndexWith, ...values])
                );
            }
        } else {
            if (this.values.length <= rows.length) {
                if (!rowsHaveBeenFlattened) {
                    this.values = rows.map((row, index) => {
                        let remainingValues = this.values.at(index)?.slice(1);
                        if (remainingValues === undefined) {
                            if (fillFlatten === true) {
                                remainingValues = this.values.at(0)?.slice(1) || [];
                            } else {
                                remainingValues = [];
                            }
                        }

                        return [
                            this.values.at(index)?.at(0) || fillFirstIndexWith,
                            ...valuesFn(row),
                            ...remainingValues,
                        ];
                    });
                } else {
                    this.values = rows.flatMap((row, index) => {
                        let remainingValues = this.values.at(index)?.slice(1);
                        if (remainingValues === undefined) {
                            if (fillFlatten === true) {
                                remainingValues = this.values.at(0)?.slice(1) || [];
                            } else {
                                remainingValues = [];
                            }
                        }

                        return row.values.map((values) => [
                            this.values.at(index)?.at(0) || fillFirstIndexWith,
                            ...values,
                            ...remainingValues,
                        ]);
                    });
                }
            } else {
                this.values = this.values.map((currentValues, index) => {
                    const remainingValues = currentValues.slice(1);
                    let row = rows.at(index);

                    if (row) {
                        return [this.values.at(index)?.at(0), ...valuesFn(row), ...remainingValues];
                    }

                    if (fillFlatten) {
                        row = rows.at(0)!;
                        return [this.values.at(index)?.at(0), ...valuesFn(row), ...remainingValues];
                    }

                    return [this.values.at(index)?.at(0), '', ...remainingValues];
                });
            }
        }

        if (desiredLength !== undefined && this.values.at(0)!.length < desiredLength) {
            const fillWith = new Array(desiredLength - this.values.at(0)!.length).fill('');
            this.values = this.values.map((value) => [
                ...value.slice(0, 2),
                ...fillWith,
                ...value.slice(2),
            ]);
        }

        this.values[0][0] = this.value;
    }

    public appendValue(rows: TableNode[]) {
        const firstRow = rows.at(0);

        if (firstRow === undefined) {
            this.values = [[this.value, '']];
            return;
        }

        const rowFillValues = new Array(
            firstRow.values.length === 0 ? 1 : firstRow.values.length
        ).fill('');
        const thisFillValues = new Array(
            this.values.length === 0 ? 1 : this.values.at(0)?.length
        ).fill('');

        if (this.values.length === 0) {
            this.values = [[this.value, ...rowFillValues]];
            rows.forEach((row) => (row.values = [['', row.value, ...thisFillValues.slice(1)]]));
        } else {
            this.values = this.values.map((value) => [...value, ...rowFillValues]);
            rows.forEach((row) => (row.values = [[...thisFillValues, row.value]]));
        }
    }
}
