import { GROUP_TYPE, Position } from 'types';

export interface GraphLayerActions {
    selectLayer: (name: string) => void;
    deleteLayer: (name: string) => void;
    addLayer: (name: string) => void;
}

export type LayerConfig = NodeRenderOptions | EdgeRenderOptions;

export interface GenericLayerElement<TConfig = LayerConfig> {
    options: TConfig;
    isSelected: boolean;
}

export interface GraphState {
    actions: GraphLayerActions;
    layers: GenericLayerElement[];
    aliases: string[];
    onChange: (layer: LayerConfig) => void;
}

export interface NodeGraphOptions {
    viewOptions?: ViewOptions;
    renderOptions?: LayerConfig[];
}

export const VIEW_TYPE = ['Fixed', 'Zoom to fit'] as const;

export interface ViewOptions {
    type: (typeof VIEW_TYPE)[number];
    scale?: number;
    position?: Position;
}

export interface NodeRenderOptions {
    groupType?: (typeof GROUP_TYPE)[number];
    primaryColor?: string;
    secondaryColor?: string;
    textColor?: string;
    showTooltip?: boolean;

    dataLinkingEnabled?: boolean;
    url?: string;
    urlTitle?: string;
    openInNewTab?: boolean;
    
    name: string;
    isAssignedInOptions: boolean;
    type: 'node';
}

export interface EdgeRenderOptions {
    color: string;
    highlight: string;
    type: 'edge';
    isAssignedInOptions: true;
    name: 'Edge'; // Placeholder until we have multiple edge types being rendered
}

const defaultBoxColor = '#00d193';
const defaultColumnColor = '#ff0060';
const defaultNodeColor = '#3c2c48';
export const defaultNodeSecondaryColor = '#ffd600';
export const defaultNodeTextColor = '#ffffff';
export const defaultEdgeColor = '#8e99a4';
export const defaultEdgeHighlight = '#8e99a4';

export const ColorMap: Record<(typeof GROUP_TYPE)[number], string> = {
    box: defaultBoxColor,
    column: defaultColumnColor,
    node: defaultNodeColor,
};
