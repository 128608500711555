import React, { useEffect, useRef, useState } from 'react';
import { SelectableValue } from '@grafana/data';
import { WideSkyIcon, WideSkySelect } from 'components/WideSkyVisualComponents';
import { useWideSkyState } from '../../state/context';
import {
    changeValueAtFilterIndex,
    conjunctionOptions,
    getConjunction,
    getFilterKind,
    setNextSuggestions,
    getOperator,
    operatorOptions,
    rebuildFilterString,
    OperatorType,
    ConjunctionType,
    isConjunction,
    REMOVE_VALUE,
    REMOVE_OPTION,
    isOperator,
    splitHaystackFilter,
} from '../utils';
import { css } from '@emotion/css';
import { UNDEFINED_FILTER_VALUE } from '../types';
import { useTheme2 } from '@grafana/ui';
import { Kind, ValueNode } from 'graphql';

const getStyles = () => ({
    haystackFiltersContainer: css({
        display: 'flex',
        flexWrap: 'wrap',
    }),
});

interface Props {
    value?: string;
    onChange: (value: ValueNode) => void;
}

export function HaystackFilter(props: Props) {
    const { value, onChange } = props;
    const styles = getStyles();
    const filters = splitHaystackFilter(value);

    return (
        <div className={styles.haystackFiltersContainer}>
            <ValueFilter
                filters={filters}
                index={0}
                onChange={(filterValue) => onChange({ kind: Kind.STRING, value: filterValue })}
            />
        </div>
    );
}

interface InternalProps {
    filters: string[];
    index: number;
    onChange: (filterValue: string) => void;
}

function ValueFilter(props: InternalProps) {
    const { filters, index, onChange } = props;
    const [filterValue, setFilterValue] = useState<string | undefined>(undefined);
    const [options, setOptions] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const state = useWideSkyState();
    const theme = useTheme2();
    const prevFiltersRef = useRef<string>();

    useEffect(() => {
        const filter = filters.at(index);
        if (isConjunction(filter) || isOperator(filter)) {
            setFilterValue(undefined);
            return;
        }

        setFilterValue(filter);
    }, [filters, index]);

    useEffect(() => {
        const currentFilterString = JSON.stringify(filters.slice(0, index - 1));
        if (prevFiltersRef.current === currentFilterString) {
            return;
        }

        setNextSuggestions({ ...state }, filters, index, setOptions, setIsLoading);
        prevFiltersRef.current = JSON.stringify(filters.slice(0, index - 1));
    }, [filters, index, state]);

    return (
        <>
            <WideSkySelect
                value={filterValue === UNDEFINED_FILTER_VALUE ? undefined : filterValue}
                color={filterValue?.at(0) === '$' || filterValue?.at(1) === '$' ? theme.colors.text.primary : undefined}
                isLoading={isLoading}
                backspaceRemovesValue={false}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    IndicatorsContainer: () => <div className={css({ paddingRight: '8px' })} />,
                }}
                allowCustomValue={true}
                options={options.map((option) => ({
                    value: option,
                    label: option,
                }))}
                onChange={(option: SelectableValue<string> | null) => {
                    const insertValue = filterValue === undefined;
                    const newFilters = changeValueAtFilterIndex(filters, index, insertValue, option?.value);
                    const filter = rebuildFilterString(newFilters, state.tagKindMap, false);
                    onChange(filter);
                }}
            />

            {filterValue !== undefined && <CombiningFilter index={index + 1} filters={filters} onChange={onChange} />}
            {filterValue === undefined && filters.length > index && (
                <CombiningFilter index={index} filters={filters} onChange={onChange} />
            )}
        </>
    );
}

type CombiningType = ConjunctionType | OperatorType | typeof REMOVE_VALUE;

function CombiningFilter(props: InternalProps) {
    const { filters, index, onChange } = props;
    const [filterValue, setFilterValue] = useState<CombiningType | undefined>(undefined);
    const [addingFilter, setAddingFilter] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<SelectableValue<CombiningType>>>([]);
    const state = useWideSkyState();

    useEffect(() => {
        const operator = getOperator(filters, index);
        const conjunction = getConjunction(filters, index);
        setFilterValue(operator || conjunction);

        if (operator !== undefined || conjunction !== undefined) {
            setAddingFilter(true);
        } else {
            setAddingFilter(false);
        }
    }, [filters, index]);

    useEffect(() => {
        const previousFilterValue = filters.at(index - 1);
        const previousFilterKind = getFilterKind(state.tagKindMap, previousFilterValue);

        if (
            previousFilterValue !== UNDEFINED_FILTER_VALUE &&
            (previousFilterKind === 'Reference' ||
                previousFilterKind === 'Number' ||
                previousFilterKind === 'Boolean' ||
                previousFilterKind === 'String' ||
                previousFilterKind === 'NA')
        ) {
            setOptions([...conjunctionOptions, ...operatorOptions(previousFilterKind), REMOVE_OPTION]);
            return;
        }

        setOptions([...conjunctionOptions, REMOVE_OPTION]);
    }, [filterValue, filters, state.tagKindMap, index]);

    return (
        <>
            {addingFilter && (
                <WideSkySelect
                    value={filterValue}
                    options={options}
                    color={isConjunction(filterValue) ? '#33b5e5' : undefined}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        IndicatorsContainer: () => <div className={css({ paddingRight: '8px' })} />,
                    }}
                    onChange={(option: SelectableValue<CombiningType>) => {
                        setAddingFilter(false);
                        const newFilters = changeValueAtFilterIndex(filters, index, false, option.value);
                        const newFilterString = rebuildFilterString(newFilters, state.tagKindMap, false);
                        onChange(newFilterString);
                    }}
                />
            )}
            {!addingFilter && <WideSkyIcon color={'#33b5e5'} onClick={() => setAddingFilter(true)} name="plus" />}
            {addingFilter && filterValue !== undefined && (
                <ValueFilter filters={filters} index={index + 1} onChange={onChange} />
            )}
        </>
    );
}
