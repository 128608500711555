import React, { createContext, useContext, useEffect, PropsWithChildren, useState, useMemo } from 'react';
import { WideSkyDatasource } from 'datasource';
import { QueryEditorProps } from '@grafana/data';
import { actions, ActionType } from './actions';
import { createStore } from './reducer';
import {
    QueryInfo,
    WideSkyDataSourceOptions,
    WideSkyEditorDependencies,
    WideSkyQueryEditorState,
    WideSkyQueryJSONTarget,
} from 'types';
import { shouldUpdateColumnOptions, shouldUpdateNodeLayers } from './helpers';
import { NodeGraphLayer } from 'formats/node_graph/config';

export type WideSkyQueryEditorProps = QueryEditorProps<
    WideSkyDatasource,
    WideSkyQueryJSONTarget,
    WideSkyDataSourceOptions
>;

const DispatchContext = createContext<React.Dispatch<ActionType>>(() => {});
const StateContext = createContext<WideSkyQueryEditorState>({} as WideSkyQueryEditorState);

export const useDispatch = () => useContext(DispatchContext);
export const useWideSkyState = () => useContext(StateContext);

export const WideSkyQueryEditorContext = ({
    datasource,
    query,
    onRunQuery,
    onChange,
    children,
}: PropsWithChildren<WideSkyQueryEditorProps>) => {
    const [state, setState] = useState<WideSkyQueryEditorState>();
    const [needsRefresh, setNeedsRefresh] = useState(false);
    const [initDispatched, setInitDispatched] = useState(false);

    const [queryInfo, setQueryInfo] = useState<QueryInfo>();
    const [columnOptions, setColumnOptions] = useState<string[]>([]);
    const [nodeLayers, setNodeLayers] = useState<NodeGraphLayer[]>([]);

    const dispatch = useMemo(() => {
        return createStore((newState) => {
            setState(newState);
            setNeedsRefresh(true);
        });
    }, []);

    useEffect(() => {
        if (state === undefined || !needsRefresh) {
            return;
        }

        state.target.hide = query.hide;
        setNeedsRefresh(false);
        onChange(state.target);
        onRunQuery();
    }, [state, needsRefresh, query.hide, setNeedsRefresh, onChange, onRunQuery]);

    useEffect(() => {
        if (state === undefined) {
            return;
        }

        state.queryInfo = queryInfo;
    }, [state, queryInfo]);

    useEffect(() => {
        if (state === undefined) {
            return;
        }

        if (shouldUpdateNodeLayers(state.target.formatType, nodeLayers, state.target.config) === false) {
            return;
        }

        state.target.config = {
            layers: nodeLayers,
        };
    }, [state, nodeLayers]);

    useEffect(() => {
        if (state === undefined) {
            return;
        }

        if (shouldUpdateColumnOptions(state.target.formatType, columnOptions, state.columnOptions) === false) {
            return;
        }

        state.columnOptions = columnOptions;
    }, [state, columnOptions]);

    if (state === undefined && initDispatched === true) {
        return null;
    }

    if (state === undefined) {
        setInitDispatched(true);

        query.setQueryInfo = setQueryInfo;
        query.setColumnOptions = setColumnOptions;
        query.setNodeLayers = setNodeLayers;

        const deps: WideSkyEditorDependencies = {
            datasource,
            target: query,
        };

        dispatch(actions.init(deps));
        return null;
    }

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};
