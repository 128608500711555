import React from 'react';
import { css } from '@emotion/css';
import { borderRadius, halfNodeHeight, NodeDatum, Position } from 'types';
import { findNodeById } from 'utils/utils';

const format = (num: number) => num.toFixed(2);
const formattedRadius = format(borderRadius);

const strokeWidth = 1;
const maxDepth = 7;
type ArcType = 'from-top-left' | 'from-top-right' | 'from-side-left' | 'from-side-right';
type LineType = 'horizontal' | 'vertical';

const getStyles = (hovering: boolean, somethingIsHovered: boolean, stroke: string) => ({
    component: css({
        fill: 'none',
        stroke,
        strokeWidth: hovering ? strokeWidth * 2 : strokeWidth,
        opacity: somethingIsHovered && !hovering ? 0.2 : 1,
    }),
});

interface Props {
    startNode: NodeDatum;
    endNode: NodeDatum;
    nodes: NodeDatum[];
    color: string;
    highlight: string;
    hovering: boolean;
    somethingIsHovered: boolean;
}

export function Edge(props: Props) {
    const { startNode, endNode, nodes, color, highlight, hovering, somethingIsHovered } = props;

    // Initial entry and exit points of the nodes
    const startX = startNode.bounds.left + halfNodeHeight;
    const startY = startNode.bounds.bottom;

    const endX = endNode.bounds.left + halfNodeHeight;
    const endY = endNode.bounds.top;

    let combinedPath = '';

    if (startNode.groupId !== endNode.groupId && endNode.groupId !== undefined) {
        const endNodeGroup = findNodeById(endNode.groupId, nodes);

        if (endNodeGroup === undefined) {
            console.warn(
                `Unable to draw edge from ${startNode.primaryText} to ${endNode.primaryText}. Containing group not found`
            );
            return null;
        }

        const isLineLeft = startX > endX;
        const partialEndX = isLineLeft
            ? endNodeGroup.bounds.left - halfNodeHeight
            : endNodeGroup.bounds.left - halfNodeHeight;

        combinedPath =
            createArcPath({
                start: { x: startX, y: startY },
                end: { x: partialEndX, y: startY + borderRadius * 2 },
                type: startX > partialEndX ? 'from-top-left' : 'from-top-right',
                depth: 0,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            }) +
            createLinePath({
                start: { x: partialEndX, y: startY + borderRadius * 2 },
                end: { x: endX, y: endY },
                type: 'vertical',
                depth: 0,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
    } else {
        combinedPath = createLinePath({
            start: { x: startX, y: startY },
            end: { x: endX, y: endY },
            type: 'vertical',
            depth: 0,
            color,
            highlight,
            hovering,
            somethingIsHovered,
        });
    }

    return (
        <path
            className={getStyles(hovering, somethingIsHovered, hovering ? highlight : color).component}
            d={combinedPath}
        />
    );
}

const createLinePath = ({
    start,
    end,
    type,
    depth,
    color,
    highlight,
    hovering,
    somethingIsHovered,
}: {
    start: Position;
    end: Position;
    type: LineType;
    depth: number;
    color: string;
    highlight: string;
    hovering: boolean;
    somethingIsHovered: boolean;
}): string => {
    if (depth === maxDepth) {
        return '';
    }

    const isLineLeft = start.x < end.x;
    let path = '';

    switch (type) {
        case 'vertical': {
            if (start.x === end.x) {
                if (start.y < end.y) {
                    return '';
                }
                return `M${start.x},${start.y} L${start.x},${end.y}`;
            }
            const nextStartY = end.y - borderRadius * 2;
            if (Math.floor(start.y) === Math.floor(nextStartY)) {
                return '';
            }

            path += `M${start.x},${start.y} L${start.x},${nextStartY}`;
            path += createArcPath({
                start: { x: start.x, y: nextStartY },
                end,
                type: isLineLeft ? 'from-top-right' : 'from-top-left',
                depth: depth + 1,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
            break;
        }
        case 'horizontal': {
            if (start.x === end.x) {
                return '';
            }
            const nextStartX = end.x + (isLineLeft ? -borderRadius : borderRadius);
            if (Math.floor(start.x) === Math.floor(nextStartX)) {
                return '';
            }

            path += `M${start.x},${start.y} L${nextStartX},${start.y}`;
            path += createArcPath({
                start: { x: nextStartX, y: start.y },
                end,
                type: isLineLeft ? 'from-side-right' : 'from-side-left',
                depth: depth + 1,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
            break;
        }
    }
    return path;
};

const createArcPath = ({
    start,
    end,
    type,
    depth,
    color,
    highlight,
    hovering,
    somethingIsHovered,
}: {
    start: Position;
    end: Position;
    type: ArcType;
    depth: number;
    color: string;
    highlight: string;
    hovering: boolean;
    somethingIsHovered: boolean;
}): string => {
    if ((start.x === end.x && start.y === end.y) || depth === maxDepth) {
        return '';
    }

    let path = '';

    const nextStartX = start.x + (type === 'from-side-left' || type === 'from-top-left' ? -borderRadius : borderRadius);
    const nextStartY = start.y + (type === 'from-side-right' ? -borderRadius : borderRadius);

    switch (type) {
        case 'from-side-left': {
            path += `M${format(start.x - borderRadius)},${format(
                start.y + borderRadius
            )} a${formattedRadius},${formattedRadius} 0 0,1 ${formattedRadius},${-formattedRadius}`;
            path += createLinePath({
                start: { x: nextStartX, y: nextStartY },
                end,
                type: 'vertical',
                depth: depth + 1,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
            break;
        }
        case 'from-side-right': {
            path += `M${format(start.x)},${format(
                start.y
            )} a${formattedRadius},${formattedRadius} 0 0,1 ${formattedRadius},${formattedRadius}`;
            path += createLinePath({
                start: { x: nextStartX, y: nextStartY },
                end,
                type: 'vertical',
                depth: depth + 1,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
            break;
        }
        case 'from-top-left': {
            path += `M${format(start.x)},${format(
                start.y
            )} a${formattedRadius},${formattedRadius} 0 0,1 ${-formattedRadius},${formattedRadius}`;
            path += createLinePath({
                start: { x: nextStartX, y: nextStartY },
                end,
                type: 'horizontal',
                depth: depth + 1,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
            break;
        }
        case 'from-top-right': {
            path += `M${format(start.x + borderRadius)},${format(
                start.y + borderRadius
            )} a${formattedRadius},${formattedRadius} 0 0,1 ${-formattedRadius},${-formattedRadius}`;
            path += createLinePath({
                start: { x: nextStartX, y: nextStartY },
                end,
                type: 'horizontal',
                depth: depth + 1,
                color,
                highlight,
                hovering,
                somethingIsHovered,
            });
            break;
        }
    }
    return path;
};
