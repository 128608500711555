const TEMPLATE_VALUE_HINT = 'templateVal';
const TEMPLATE_TEXT_HINT = 'templateText';

interface TemplateVariables {
    templateTexts: string[];
    templateVals: string[];
}

/*****************************************************
 * This algorithm parses a Widesky graph QL response
 * and look for the node that has been alias/labelled
 * as "templateValue".
 * These templateValues are then stored as the key of a
 * javascript object (no duplicate keys) and returned.
 ******************************************************/
export function findTemplateVariables(node: any, nodeName = 'haystack'): TemplateVariables {
    const result: TemplateVariables = {
        templateTexts: [],
        templateVals: [],
    };

    if (node instanceof Array) {
        node.forEach((childNode) => {
            const childResult = findTemplateVariables(childNode, nodeName);
            result.templateTexts.push(...childResult.templateTexts);
            result.templateVals.push(...childResult.templateVals);
        });
    } else if (node instanceof Object) {
        for (const childNodeName in node) {
            const childNode = node[childNodeName];

            if (childNodeName === TEMPLATE_VALUE_HINT) {
                result.templateVals.push(childNode);
            } else if (childNodeName === TEMPLATE_TEXT_HINT) {
                result.templateTexts.push(childNode);
            }

            const childResult = findTemplateVariables(childNode, childNodeName);
            result.templateTexts.push(...childResult.templateTexts);
            result.templateVals.push(...childResult.templateVals);
        }
    }

    return result;
}
