import { GraphQLResponse, QueryType, WideSkyQueryJSONTarget } from 'types';
import { createNodeGraphData } from './parse';

export const NodeGraphQuery: QueryType = {
    parseTarget(target: WideSkyQueryJSONTarget) {
        return target.query.replace(/entity {/gm, 'entity { node_graph_id:id');
    },

    createResponse(target: WideSkyQueryJSONTarget, graphQLRoot: Readonly<GraphQLResponse>) {
        return createNodeGraphData(graphQLRoot, target);
    },
};
