import { css } from '@emotion/css';
import { WideSkyIcon } from 'components/WideSkyVisualComponents';
import {
    getNullableType,
    GraphQLInputType,
    GraphQLList,
    isEnumType,
    isInputObjectType,
    isListType,
    isScalarType,
    Kind,
    ObjectValueNode,
    ValueNode,
} from 'graphql';
import React from 'react';
import { Argument } from './BaseArgument';

const constructEmptyValue = (type: GraphQLInputType): ValueNode => {
    if (isInputObjectType(type)) {
        const obj: ObjectValueNode = { kind: Kind.OBJECT, fields: [] };
        return obj;
    }

    if (isScalarType(type)) {
        switch (type.name) {
            case 'Boolean':
                return { kind: Kind.BOOLEAN, value: false };
            case 'Int':
                return { kind: Kind.INT, value: '' };
            case 'Float':
                return { kind: Kind.FLOAT, value: '' };
            case 'String':
            // Fall through
            case 'iso8601':
                return { kind: Kind.STRING, value: '' };
            default:
                console.error(`Unhandled type ${type.name}`);
                return { kind: Kind.STRING, value: '' };
        }
    }

    if (isEnumType(type)) {
        return { kind: Kind.ENUM, value: '' };
    }

    if (isListType(type)) {
        return { kind: Kind.LIST, values: [] };
    }

    return { kind: Kind.STRING, value: '' };
};

const getStyles = () => ({
    listOption: css({
        display: 'flex',
    }),
});

interface Props {
    type: GraphQLList<GraphQLInputType>;
    value?: ValueNode;
    onChange: (value: ValueNode) => void;
}

export function ListArgument(props: Props) {
    const { type, value, onChange } = props;

    const styles = getStyles();

    if (value !== undefined && value?.kind !== Kind.LIST) {
        console.error(`Argument type does not match value type (${value?.kind})`);
        return null;
    }

    const baseType = getNullableType(type.ofType);
    const emptyValue = constructEmptyValue(baseType);

    if (value === undefined) {
        return <WideSkyIcon name="plus" onClick={() => onChange({ kind: Kind.LIST, values: [emptyValue] })} />;
    }

    const values = value.values;

    const listOption = (valueNode: ValueNode | undefined, index: number) => {
        if (isListType(baseType)) {
            console.warn('List argument inside of list argument not currently supported');
            return null;
        }

        const onChangeList = (listValue: ValueNode) =>
            onChange({
                kind: Kind.LIST,
                values: [...values.slice(0, index), listValue, ...values.slice(index + 1)],
            });

        return (
            <div className={styles.listOption}>
                <Argument
                    type={baseType}
                    isRequired={false}
                    defaultValue={undefined}
                    value={valueNode}
                    onChange={onChangeList}
                />

                <WideSkyIcon
                    name="plus"
                    onClick={() =>
                        onChange({
                            kind: Kind.LIST,
                            values: [...values.slice(0, index + 1), emptyValue, ...values.slice(index + 1)],
                        })
                    }
                />
                <WideSkyIcon
                    name="minus"
                    onClick={() =>
                        onChange({
                            kind: Kind.LIST,
                            values: [...values.slice(0, index), ...values.slice(index + 1)],
                        })
                    }
                />
            </div>
        );
    };

    if (values.length === 0) {
        return <div>{listOption(undefined, 0)}</div>;
    }

    return <div>{values.map(listOption)}</div>;
}
