import memoizeOne from 'memoize-one';
import React, { Component } from 'react';
import { Subscription } from 'rxjs';

import { BusEventWithPayload, DataFrame, FieldCache, LoadingState, PanelData, PanelProps } from '@grafana/data';

import {
    defaultEdgeColor,
    defaultEdgeHighlight,
    GenericLayerElement,
    GraphState,
    LayerConfig,
    NodeGraphOptions,
} from 'editor/editor.types';
import { PanelContext, PanelContextRoot } from '@grafana/ui';
import { getActions } from 'utils/actions';
import { NodeGraph } from './NodeGraph';
import { processFields } from 'utils/preprocessing';
import { EdgeFields, NodeFields } from 'utils/preprocessing.types';
import { cloneDeep } from 'lodash';

function getNodeGraphDataFrames(frames: DataFrame[]) {
    return frames.filter((frame) => {
        if (frame.meta?.preferredVisualisationType === 'nodeGraph') {
            return true;
        }

        if (frame.name === 'nodes' || frame.name === 'edges' || frame.refId === 'nodes' || frame.refId === 'edges') {
            return true;
        }

        const fieldsCache = new FieldCache(frame);
        if (fieldsCache.getFieldByName('id')) {
            return true;
        }

        if (fieldsCache.getFieldByName('groupnames')) {
            return true;
        }

        return false;
    });
}

type Props = PanelProps<NodeGraphOptions>;

export class PanelEditExitedEvent extends BusEventWithPayload<number> {
    static type = 'panel-edit-finished';
}

export class NodeGraphPanel extends Component<Props> {
    declare context: React.ContextType<typeof PanelContextRoot>;
    panelContext: PanelContext | undefined = undefined;
    static contextType = PanelContextRoot;
    private subs = new Subscription();

    public layers: GenericLayerElement[] = [];
    public aliases: string[] = [];
    graphDiv?: HTMLDivElement;

    nodes: NodeFields | undefined;
    edges: EdgeFields | undefined;

    actions = getActions(this);
    memoizedGetNodeGraphDataFrames = memoizeOne(getNodeGraphDataFrames);

    constructor(props: Props) {
        super(props);

        this.subs.add(
            this.props.eventBus.subscribe(PanelEditExitedEvent, (evt) => {
                if (this.graphDiv && this.props.id === evt.payload) {
                    this.initRef(this.graphDiv);
                }
            })
        );

        this.dataChanged(props.data);
    }

    componentDidMount() {
        this.panelContext = this.context;
    }

    componentDidUpdate(prevProps: PanelProps) {
        if (this.props.data === prevProps.data) {
            return;
        }

        this.dataChanged(this.props.data);
    }

    shouldComponentUpdate() {
        return true;
    }

    dataChanged(data: PanelData) {
        if (data.state !== LoadingState.Done) {
            this.nodes = undefined;
            this.edges = undefined;
            return;
        }

        if (!data || !data.series.length) {
            this.nodes = undefined;
            this.edges = undefined;
            return;
        }

        const dataFrames = this.memoizedGetNodeGraphDataFrames(data.series);
        const fields = processFields(dataFrames);

        if (fields === undefined) {
            this.nodes = undefined;
            this.edges = undefined;
            return;
        }

        const { nodeFields, edgeFields, aliases } = fields;

        this.nodes = nodeFields;
        this.edges = edgeFields;

        if (aliases) {
            this.aliases = Array.from(aliases).filter((alias): alias is string => alias !== undefined);
            notifyPanelEditor(this);
        }
    }

    toggleOptionVisibility(layerIndex: number, visible: boolean) {
        const { options, onOptionsChange } = this.props;
        const layers = cloneDeep(this.layers);
        layers.at(layerIndex)!.options.isAssignedInOptions = visible;

        this.layers = layers;

        onOptionsChange({
            ...options,
            renderOptions: layers.map((layer) => layer.options),
        });

        notifyPanelEditor(this);
    }

    toggleSelected(layerIndex: number) {
        const { options, onOptionsChange } = this.props;
        const layers = cloneDeep(this.layers);
        layers.forEach((layer, index) => {
            if (index === layerIndex) {
                layer.isSelected = !layer.isSelected;
            } else {
                layer.isSelected = false;
            }
        });

        this.layers = layers;

        onOptionsChange({
            ...options,
            renderOptions: layers.map((layer) => layer.options),
        });

        notifyPanelEditor(this);
    }

    addNewLayerOption(newOption: GenericLayerElement) {
        const { options, onOptionsChange } = this.props;
        const layers = cloneDeep(this.layers);

        layers.push(newOption);
        this.layers = layers;

        onOptionsChange({
            ...options,
            renderOptions: layers.map((layer) => layer.options),
        });

        notifyPanelEditor(this);
    }

    onLayerChange = (config: LayerConfig) => {
        const { options, onOptionsChange } = this.props;
        const layers = cloneDeep(this.layers);
        const layer = layers.find((layer) => layer.options.name === config.name);
        layer!.options = config;

        this.layers = layers;

        onOptionsChange({
            ...options,
            renderOptions: layers.map((layer) => layer.options),
        });

        notifyPanelEditor(this);
    };

    initRef = async (div: HTMLDivElement | null) => {
        if (div === null) {
            return;
        }

        this.graphDiv = div;

        const { options } = this.props;

        const layers: GenericLayerElement[] = [];
        try {
            if (options.renderOptions) {
                for (const layer of options.renderOptions) {
                    layers.push({ options: layer, isSelected: false });
                }
            } else {
                layers.push({
                    options: {
                        name: 'Edge',
                        type: 'edge',
                        isAssignedInOptions: true,
                        color: defaultEdgeColor,
                        highlight: defaultEdgeHighlight,
                    },
                    isSelected: false,
                });
            }

            if (options.viewOptions === undefined) {
                options.viewOptions = {
                    scale: 1,
                    position: { x: 0, y: 0 },
                    type: 'Zoom to fit',
                };
            }
        } catch (error) {
            console.error('error loading layers', error);
        }

        this.layers = layers;
        notifyPanelEditor(this);
    };

    render() {
        if (this.nodes === undefined || this.edges === undefined) {
            return (
                <div className="panel-empty">
                    <p>No data found in response</p>
                </div>
            );
        }

        return (
            <div style={{ width: this.props.width, height: this.props.height }} ref={this.initRef}>
                <NodeGraph nodeFields={this.nodes} edgeFields={this.edges} options={this.props.options} />
            </div>
        );
    }
}

export const notifyPanelEditor = (panel: NodeGraphPanel) => {
    if (panel.panelContext && panel.panelContext.onInstanceStateChange) {
        const state: GraphState = {
            layers: panel.layers,
            aliases: panel.aliases,
            actions: panel.actions,
            onChange: panel.onLayerChange,
        };

        panel.panelContext.onInstanceStateChange(state);
    }
};
