export const FLATTEN_NONE_VALUE = 'None';

export type Column = {
    base?: string;
    flatten?: string;
};

export interface Config {
    columns?: Column[];
    fillFlatten?: boolean;
}
