import { WithAccessControlMetadata } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { APIServerManager, ApiSeverEndpoint } from 'api_server_manager';
import { Team as TeamBase } from '@grafana/schema';
import { readByFilterQuery } from './graphql_queries';

type Unit = { name: string; url: string };

interface UserDTO extends WithAccessControlMetadata {
    id: number;
    login: string;
    email: string;
    name: string;
    isGrafanaAdmin: boolean;
    isDisabled: boolean;
    isAdmin?: boolean;
    isExternal?: boolean;
    updatedAt?: string;
    authLabels?: string[];
    theme?: string;
    avatarUrl?: string;
    orgId?: number;
    lastSeenAtAge?: string;
    licensedRole?: string;
    permissions?: string[];
    teams?: Unit[];
    orgs?: Unit[];
}

interface Team extends TeamBase {
    id: number;
}

export class ImpersonationQueue {
    private result?: Promise<string | undefined>;
    private inProgress = false;

    public async add(apiServerManager: APIServerManager): Promise<string | undefined> {
        if (this.inProgress === true || this.result !== undefined) {
            return this.result;
        }

        this.inProgress = true;
        this.result = this.getImpersonationId(apiServerManager);
        return this.result.then((impersonationId) => {
            this.inProgress = false;
            return impersonationId;
        });
    }

    private async getImpersonationId(apiServerManager: APIServerManager): Promise<string | undefined> {
        // Resolve impersonation attempt - The user will be allowed to see all data
        const onErrorResolve = (error: string) => {
            console.warn(`Skipping impersonate: ${error}`);
            return Promise.resolve(undefined);
        };

        // Reject impersonation attempt - The user will not be able to see any data
        const onErrorReject = (error: string) => {
            console.warn(`Skipping impersonate: ${error}`);
            return Promise.reject(error);
        };

        let user: UserDTO;
        let appName: string;

        try {
            // Get the backend app name for white labelling
            appName =
                ((await getBackendSrv().get('/api/frontend/settings'))?.wideSkyWhitelabeling
                    ?.applicationName as string) ?? 'WideSky';
        } catch {
            return onErrorReject('Cannot access backend data');
        }

        try {
            user = await getBackendSrv().get<UserDTO>('/api/user');
        } catch {
            return onErrorReject(`Cannot access ${appName} data`);
        }

        if (user.isGrafanaAdmin) {
            return onErrorResolve(`User is a ${appName} admin`);
        }

        let teams: Team[];

        try {
            teams = await getBackendSrv().get<Team[]>('/api/user/teams');
        } catch {
            return onErrorReject(`Cannot access ${appName} data`);
        }

        if (teams.length === 0) {
            return onErrorReject(`User is not in a ${appName} team`);
        }

        const accountIds = (
            await Promise.all(
                teams.map<Promise<string | undefined>>((team) => {
                    if (!team.email) {
                        return Promise.resolve(undefined);
                    }

                    const filter = `account and email==\\\"${team.email}\\\"`;
                    const query = readByFilterQuery(filter, 1);

                    return apiServerManager
                        .requestWithToken(query, ApiSeverEndpoint.READ_ENTITY, true, false)
                        .then((accountResponse: any) => {
                            const account = accountResponse.rows.at(0);
                            return account ? (account.userRef as string) : undefined;
                        })
                        .catch(() => undefined);
                })
            )
        ).filter((result) => result);

        const id = accountIds.at(0);

        if (id === undefined) {
            return onErrorReject(`User has no ${appName} teams to impersonate or emails do not match`);
        } else if (accountIds.length !== 1) {
            console.warn('Multiple teams available to impersonate, selected first');
        }

        return Promise.resolve(id);
    }
}
