import React, { useState } from 'react';
import { GraphQLNamedOutputType, isObjectType, isUnionType } from 'graphql';
import { actions } from '../state/actions';
import { useDispatch } from '../state/context';
import { WideSkyMenu, WideSkyMenuItem } from 'components/WideSkyVisualComponents';
import { resolveNodeName } from './utils';
import { QueryNode } from './types';

type SubMenuLabel = 'Add Field' | 'Change To';

interface SubMenuItem {
    name: string;
    fragmentValue?: string;
    description?: string;
}

interface Props {
    element: QueryNode;
    schema?: GraphQLNamedOutputType;
    parentElement?: QueryNode;
    parentSchema?: GraphQLNamedOutputType;
}

export function QueryElementMenu(props: Props) {
    const { element, schema, parentElement, parentSchema } = props;
    const dispatch = useDispatch();

    const [hoveredLabel, setHoveredLabel] = useState<SubMenuLabel>();

    const getOptions = (optionSchema: GraphQLNamedOutputType | undefined, removeSelf: boolean): SubMenuItem[] => {
        if (optionSchema === undefined) {
            return [];
        }

        const name = resolveNodeName(element, false);

        if (name === 'timeSeries' && removeSelf === false) {
            return [];
        }

        if (isObjectType(optionSchema)) {
            const fields = optionSchema.getFields();

            return Object.keys(fields)
                .filter((field) => removeSelf === false || field !== name)
                .map<SubMenuItem>((key) => ({
                    name: key,
                    description: fields[key].description || undefined,
                }));
        } else if (isUnionType(optionSchema)) {
            const fields = optionSchema.getTypes();

            return fields
                .filter((field) => removeSelf === false || field.name !== name)
                .map<SubMenuItem>((field) => ({
                    name: `... on ${field.name}`,
                    fragmentValue: field.getFields()[Object.keys(field.getFields())[0]].name,
                    description: field.description || undefined,
                }));
        } else {
            return [];
        }
    };

    const childOptions = getOptions(schema, false);
    const siblingOptions = getOptions(parentSchema, true);

    return (
        <WideSkyMenu position="bottom">
            <MenuItemWithSubMenu
                label="Add Field"
                setHoveredLabel={setHoveredLabel}
                hoveredLabel={hoveredLabel}
                options={childOptions}
                onItemClick={(item: string) => dispatch(actions.queryElementAdded({ element, item }))}
            />

            {parentElement && (
                <>
                    <MenuItemWithSubMenu
                        label="Change To"
                        setHoveredLabel={setHoveredLabel}
                        hoveredLabel={hoveredLabel}
                        options={siblingOptions}
                        onItemClick={(item: string) =>
                            dispatch(actions.queryElementChanged({ element, parentElement, item }))
                        }
                    />
                    <WideSkyMenuItem
                        label="Remove"
                        onClick={() => dispatch(actions.queryElementRemoved({ element, parentElement }))}
                        onMouseEnter={() => setHoveredLabel(undefined)}
                    />
                </>
            )}
        </WideSkyMenu>
    );
}

interface MenuItemWithSubMenuProps {
    options: SubMenuItem[];
    label: SubMenuLabel;
    hoveredLabel?: SubMenuLabel;
    setHoveredLabel: (label: SubMenuLabel) => void;
    onItemClick: (item: string) => void;
}

const MenuItemWithSubMenu = (props: MenuItemWithSubMenuProps) => {
    const { options, label, hoveredLabel, setHoveredLabel, onItemClick } = props;

    if (options.length === 0) {
        return null;
    }

    return (
        <WideSkyMenuItem label={label} onMouseEnter={() => setHoveredLabel(label)}>
            {hoveredLabel === label && (
                <WideSkyMenu position="right">
                    {options.map((option, index) => (
                        <WideSkyMenuItem
                            key={index}
                            label={option.name}
                            tooltip={option.description}
                            onClick={() =>
                                onItemClick(
                                    option.fragmentValue === undefined
                                        ? option.name
                                        : `${option.name} ${option.fragmentValue}`
                                )
                            }
                        />
                    ))}
                </WideSkyMenu>
            )}
        </WideSkyMenuItem>
    );
};
