import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';
import { WideSkyLabel } from 'components/WideSkyVisualComponents';
import {
    ArgumentNode,
    getNullableType,
    GraphQLArgument,
    isNonNullType,
    Kind,
    NameNode,
    ObjectFieldNode,
    ValueNode,
} from 'graphql';
import React, { useState } from 'react';
import { Argument } from './BaseArgument';
import { argumentAsString, isArgumentDefault } from '../utils';

const getStyles = (theme: GrafanaTheme2) => ({
    container: css({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    }),

    rowContainer: css({
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    }),

    greyBar: css({
        flexGrow: 1,
        backgroundColor: theme.colors.background.secondary,
        alignItems: 'center',
        border: 'none',
        display: 'flex',
        height: theme.spacing(theme.components.height.md),
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        //! TODO: Change to 'theme.shape.radius.default' when we update to G10
        // eslint-disable-next-line deprecation/deprecation
        borderRadius: theme.shape.borderRadius(2),
    }),

    optionsContainer: css({
        display: 'flex',
        flexDirection: 'row',
    }),
});

interface Props {
    allArguments: readonly GraphQLArgument[];
    isRequired: boolean;
    values?: readonly ObjectFieldNode[] | readonly ArgumentNode[];
    onChange: (value: ValueNode, argument: GraphQLArgument) => void;
}

export function ObjectArgument(props: Props) {
    const { allArguments, values, isRequired, onChange } = props;
    const [showOptions, setShowOptions] = useState<boolean>(false);

    const theme = useTheme2();
    const styles = getStyles(theme);

    type FieldArgument = {
        argument: GraphQLArgument;
        value?: ValueNode;
        name?: NameNode;
        kind?: Kind.ARGUMENT | Kind.OBJECT_FIELD;
        isOptional: boolean;
    };

    const allFieldArguments = allArguments.map<FieldArgument>((argument) => {
        const isOptional = isNonNullType(argument.type);

        if (values === undefined) {
            return {
                argument,
                isOptional,
            };
        }

        const field = (values as Array<ObjectFieldNode | ArgumentNode>).find((arg) => arg.name.value === argument.name);

        if (field === undefined) {
            return {
                argument,
                isOptional,
            };
        }

        return {
            argument,
            value: field.value,
            name: field.name,
            kind: field.kind,
            isOptional,
        };
    });

    const requiredFields = allFieldArguments.filter((argument) => argument.isOptional);
    const notRequiredFields = allFieldArguments.filter((argument) => !argument.isOptional);

    const notRequiredString = notRequiredFields
        .filter(
            (fieldArgument): fieldArgument is Required<FieldArgument> =>
                fieldArgument.value !== undefined &&
                !isArgumentDefault(
                    fieldArgument.value,
                    fieldArgument.argument.type,
                    fieldArgument.argument.defaultValue
                )
        )
        .map((fieldArgument) => argumentAsString(fieldArgument.value, fieldArgument.name))
        .join(', ');

    return (
        <div className={styles.container}>
            <div className={styles.rowContainer}>
                {requiredFields.map((argumentField, index) => (
                    <Argument
                        key={index}
                        name={argumentField.argument.name}
                        description={argumentField.argument.description}
                        isRequired={isRequired}
                        type={getNullableType(argumentField.argument.type)}
                        defaultValue={argumentField.argument.defaultValue}
                        value={argumentField.value}
                        onChange={(newValue) => onChange(newValue, argumentField.argument)}
                    />
                ))}
                {notRequiredFields.length !== 0 && (
                    <WideSkyLabel
                        label={`Options: ${
                            showOptions ? '' : notRequiredString.length === 0 ? '' : `(${notRequiredString})`
                        }`}
                        onClick={() => setShowOptions(!showOptions)}
                        tooltip={'Open to edit additional arguments.'}
                        iconName={showOptions ? 'angle-down' : 'angle-right'}
                    />
                )}

                <div className={styles.greyBar} />
            </div>
            {showOptions &&
                notRequiredFields.map((fieldArgument, index) => (
                    <div key={index} className={styles.optionsContainer}>
                        <Argument
                            isRequired={false}
                            name={fieldArgument.argument.name}
                            description={fieldArgument.argument.description}
                            type={getNullableType(fieldArgument.argument.type)}
                            defaultValue={fieldArgument.argument.defaultValue}
                            value={fieldArgument.value}
                            onChange={(newValue) => onChange(newValue, fieldArgument.argument)}
                        />
                    </div>
                ))}
        </div>
    );
}
