import { Config as TableConfig } from 'formats/table/config';
import { Config as DeprecatingTableConfig } from 'formats/table_deprecating/config';
import { Config as TimeSeriesConfig } from 'formats/time_series/config';
import {
    ArgumentNode,
    buildClientSchema,
    FieldNode,
    getIntrospectionQuery,
    GraphQLArgument,
    IntrospectionQuery,
    Kind,
    SelectionNode,
    ValueNode,
} from 'graphql';
import {
    WideSkyQueryEditorState,
    WideSkyEditorDependencies,
    TagKind,
    InternalQueryError,
    WideSkyQueryJSONTarget,
    FORMAT_TYPES,
} from 'types';
import { APIServerManager } from 'api_server_manager';
import { getFilterTags } from 'components/query/utils';
import { QueryNode, UNSELECTED_VALUE } from 'components/query/types';
import { LegacyWideSkyTarget } from 'types.legacy';
import { DEFAULT_QUERY } from 'utils/graphql_queries';
import { Config, NodeGraphLayer } from 'formats/node_graph/config';
import { getBackendSrv } from '@grafana/runtime';

export async function initState({ datasource, target }: WideSkyEditorDependencies): Promise<WideSkyQueryEditorState> {
    const OnError = (error: InternalQueryError) => {
        console.error(error);

        if (target.setQueryInfo) {
            target.setQueryInfo({
                severity: 'error',
                data: {
                    message: error.message,
                    error: error.additionalInfo,
                },
            });
        }
    };

    if (target.hasOwnProperty('graphq')) {
        target = updateLegacyTarget(target);
    }

    if (target.query === undefined) {
        target.query = DEFAULT_QUERY;
        target.formatType = 'TIME_SERIES';
        target.config = {};
    }

    // Load any helpful graphql queries here so we don't have to create heavy loads later
    const schemaPromise = getSchema(datasource.apiServerManager, OnError);
    const haystackTagsPromise = getFilterTags(datasource.apiServerManager, 'id', OnError);

    const [schema, haystackTags] = await Promise.all([schemaPromise, haystackTagsPromise]);

    const tags = haystackTags.map((tag) => tag.name);
    const tagKindMap = haystackTags.reduce((tagKindMap, tag) => {
        tagKindMap.set(tag.name, tag.kind);
        return tagKindMap;
    }, new Map<string, TagKind>());

    // Get the backend app name for white labelling
    const appName =
        ((await getBackendSrv().get('/api/frontend/settings'))?.wideSkyWhitelabeling?.applicationName as string) ??
        'WideSky';

    return {
        datasource,
        target,
        tags,
        tagKindMap,
        schema,
        appName,
    };
}

const getSchema = (apiServerManager: APIServerManager, onError: (error: InternalQueryError) => void) => {
    return apiServerManager
        .postQuery<IntrospectionQuery>(getIntrospectionQuery())
        .then((response) => buildClientSchema(response))
        .catch((error) => {
            onError(error);
            return undefined;
        });
};

function getAliasedValue(selections: Readonly<SelectionNode[]>, value: string) {
    if (!selections.some((selection) => selection.kind === Kind.FIELD && selection.name.value === value)) {
        return value;
    }

    const similarSelections = selections.filter(
        (selection): selection is FieldNode => selection.kind === Kind.FIELD && selection.name.value === value
    );

    if (!similarSelections.some((selection) => selection.alias === undefined)) {
        return value;
    }

    const samllestNextAlias = similarSelections
        .filter(
            (selection): selection is Required<FieldNode> =>
                selection.alias !== undefined && selection.alias.value.startsWith(value)
        )
        .map((selection) => parseInt(selection.alias.value.substring(value.length), 10))
        .filter((value) => !isNaN(value) && value > 0)
        .sort((value, comparisonValue) => value - comparisonValue)
        .reduce((smallestValue, value) => (value === smallestValue ? smallestValue + 1 : smallestValue), 1);

    return `${value}${samllestNextAlias}:${value}`;
}

function appendAutoFill(value: string, indent: string, aliasedValue?: string) {
    if (aliasedValue === undefined) {
        aliasedValue = value;
    }

    if (value === 'timeSeries') {
        return `${aliasedValue} {\n${indent}    dataPoints {\n${indent}      time\n${indent}      value\n${indent}    }\n${indent}  }`;
    }

    if (value === 'history') {
        return `${aliasedValue}(rangeAbsolute: {start: "$from", end: "$to"})`;
    }

    // Our schema does not describe fragments so we must insert them manually
    if (value.startsWith('... on ')) {
        const [name, fragment] = aliasedValue.slice(7).split(' ');

        return `... on ${name} {\n${indent}    ${fragment}\n${indent}  }`;
    }

    return aliasedValue;
}

export function addQueryElement(query: string, element: QueryNode, add: string) {
    if (element.loc === undefined) {
        console.error('Location information of the element is missing');
        return query;
    }

    let offset = 1;

    if (query.at(0) === '{') {
        offset = 0;
    }

    const columnIndex =
        element.loc.startToken.column === 2 ? element.loc.startToken.column - 1 : element.loc.startToken.column;
    const indent = new Array(columnIndex).join(' ');

    // Adding the first element to the list, also add the surrounding {...} to the query
    if (element.selectionSet === undefined) {
        const endIndex = element.loc.end - offset;
        const value = appendAutoFill(add, indent);

        const start = query.slice(0, endIndex).trimEnd();
        const inject = ` {\n${indent}  ${value}\n${indent}}\n${indent}`;
        const end = query.slice(endIndex).trimStart();

        if (end.at(0) === '}') {
            return `${start}${inject.slice(0, inject.length - 2)}${end}`;
        }

        return `${start}${inject}${end}`;
    }

    // Adding to a pre-existing list of elements
    const aliasedAdd = getAliasedValue(element.selectionSet.selections, add);

    const startOfSelections =
        element.selectionSet.selections.reduce<number>(
            (startOfSelections, selection) => Math.min(startOfSelections, selection.loc?.start || Infinity),
            Infinity
        ) - offset;

    if (startOfSelections === Infinity) {
        console.warn(`Failed to insert ${add}, please check the query in textMode`);
        return query;
    }

    const value = appendAutoFill(add, indent, aliasedAdd);

    const start = query.slice(0, startOfSelections).trimEnd();
    const inject = `\n${indent}  ${value}\n${indent}  `;
    const end = query.slice(startOfSelections).trimStart();

    return `${start}${inject}${end}`;
}

export function changeQueryElement(query: string, element: QueryNode, parentElement: QueryNode, changeTo: string) {
    if (element.loc === undefined) {
        console.error('Location information of the element is missing');
        return query;
    }

    if (parentElement.selectionSet === undefined) {
        console.error('Attempted to change element on parent that does not exist');
        return query;
    }

    let offset = 1;

    if (query.at(0) === '{') {
        offset = 0;
    }

    const aliasedChangeTo = getAliasedValue(parentElement.selectionSet.selections, changeTo);
    const indent = new Array(element.loc.startToken.column - 2).join(' ');
    const start = query.slice(0, element.loc.start - offset);

    // Retain selectionSet if just the alias changed
    if (
        element.kind === Kind.FIELD &&
        element.name.value === changeTo.split(':').pop() &&
        element.selectionSet !== undefined
    ) {
        if (element.name.loc === undefined) {
            console.error('Location information of the element is missing');
            return query;
        }

        const end = query.slice(element.name.loc.end - offset);

        return `${start}${changeTo}${end}`;
    }

    const inject = appendAutoFill(changeTo, indent, aliasedChangeTo);
    const end = query.slice(element.loc.end);

    return `${start}${inject}${end}`;
}

export function removeQueryElement(query: string, element: QueryNode, parentElement: QueryNode) {
    let offset = 1;

    if (query.at(0) === '{') {
        offset = 0;
    }

    // This might be the last element in the set. If so, remove the surrounding {...} from the query
    if (parentElement.selectionSet?.selections.length === 1) {
        if (parentElement.selectionSet.loc === undefined) {
            console.error('Location information of the parentElement is missing');
            return query;
        }

        const start = query.slice(0, parentElement.selectionSet.loc.start - offset);
        const end = query.slice(parentElement.selectionSet.loc.end - offset);

        return `${start}${end}`;
    }

    if (element.loc === undefined || element.loc.startToken.prev === null) {
        console.error('Location information of the element is missing');
        return query;
    }

    const start = query.slice(0, element.loc.startToken.prev.end - offset);
    const end = query.slice(element.loc.end - offset);

    return `${start}${end}`;
}

const getArgumentValue = (valueNode: ValueNode): string | undefined => {
    switch (valueNode.kind) {
        case Kind.ENUM:
        // Fall through
        case Kind.INT:
        // Fall through
        case Kind.FLOAT:
        // Fall through
        case Kind.BOOLEAN:
            return `${valueNode.value}`;
        case Kind.STRING:
            return `"${valueNode.value}"`;
        case Kind.LIST:
            return `[${valueNode.values.map(getArgumentValue).join(', ')}]`;
        case Kind.OBJECT:
            return `{ ${valueNode.fields.map(
                (valueField) => `${valueField.name.value}: ${getArgumentValue(valueField.value)}`
            )} }`;
        case Kind.VARIABLE: {
            const value = valueNode.name.value;
            if (value.at(0) === '$') {
                return value;
            }

            return `$${value}`;
        }
        default:
            console.error(`Unhandled argument kind ${valueNode.kind}`);
            return undefined;
    }
};

function addQueryElementArgument(query: string, element: FieldNode, value?: string) {
    if (value === undefined) {
        return query;
    }

    if (element.name.loc === undefined) {
        console.error('Location information of the element is missing');
        return query;
    }

    let offset = 1;
    if (query.at(0) === '{') {
        offset = 0;
    }

    // First argument to be added
    if (element.arguments === undefined || element.arguments.length === 0) {
        const start = query.slice(0, element.name.loc.endToken.end - offset);
        const inject = `(${value})`;
        const end = query.slice(element.name.loc.endToken.end - offset);

        return `${start}${inject}${end}`;
    }

    // Append to end of existing arguments
    const endOfArguments = element.arguments.reduce<number>(
        (endOfArguments, argument) => Math.max(endOfArguments, argument.loc?.end || 0),
        0
    );
    return `${query.slice(0, endOfArguments - offset)}, ${value}${query.slice(endOfArguments - offset)}`;
}

function removeQueryElementArgument(query: string, element: FieldNode, argumentElement: ArgumentNode) {
    if (argumentElement.loc === undefined) {
        console.error('Location information of the element is missing');
        return query;
    }

    let offset = element.arguments?.length === 1 ? 1 : 0;
    if (query.at(0) === '{') {
        offset++;
    }

    const start = query.slice(0, argumentElement.loc.start - 3 + offset);
    const end = query.slice(argumentElement.loc.end - 1 + offset);
    return `${start}${end}`;
}

export const isEmptyValue = (valueNode: ValueNode) => {
    switch (valueNode.kind) {
        case Kind.FLOAT:
        // Fall through
        case Kind.INT:
        // Fall through
        case Kind.STRING:
            return valueNode.value === '';
        case Kind.LIST:
            return valueNode.values.length === 0;
        case Kind.OBJECT:
            return valueNode.fields.length === 0;
        case Kind.ENUM:
            return valueNode.value === UNSELECTED_VALUE;
        case Kind.VARIABLE:
            return valueNode.name.value === '';
        default:
            return false;
    }
};

export function changeQueryElementArgument(
    query: string,
    argument: GraphQLArgument,
    element: FieldNode,
    valueNode: ValueNode
) {
    const argumentElement = element?.arguments?.find((arg) => arg.name.value === argument.name);
    const inject = getArgumentValue(valueNode);
    const isEmpty = isEmptyValue(valueNode);

    if (argumentElement === undefined) {
        if (isEmpty) {
            return query;
        }

        const value = `${argument.name}: ${inject}`;
        return addQueryElementArgument(query, element, value);
    }

    if (isEmpty) {
        return removeQueryElementArgument(query, element, argumentElement);
    }

    let offset = 1;
    if (query.at(0) === '{') {
        offset = 0;
    }

    const valueLocation = argumentElement.value.loc;
    if (valueLocation === undefined) {
        console.error('Location information of the argument is missing');
        return query;
    }

    const start = query.slice(0, valueLocation.start - offset);
    const end = query.slice(valueLocation.end - offset);

    return `${start}${inject}${end}`;
}

export function shouldUpdateNodeLayers(
    formatType: (typeof FORMAT_TYPES)[number],
    newLayers: NodeGraphLayer[],
    oldConfig?: Config
) {
    if (formatType !== 'NODE_GRAPH') {
        return false;
    }

    if (oldConfig?.layers === undefined) {
        return true;
    }

    if (newLayers.length !== oldConfig.layers.length) {
        return true;
    }

    for (let i = 0; i < newLayers.length; i++) {
        if (oldConfig.layers[i] === undefined || newLayers[i].alias !== oldConfig.layers[i].alias) {
            return true;
        }
    }

    return false;
}

export function shouldUpdateColumnOptions(
    formatType: (typeof FORMAT_TYPES)[number],
    newOptions: string[],
    oldOptions?: string[]
) {
    if (formatType !== 'TABLE' && formatType !== 'DEPRECATING_TABLE') {
        return false;
    }

    if (oldOptions === undefined) {
        return true;
    }

    if (newOptions.length !== oldOptions.length) {
        return true;
    }

    for (let i = 0; i < newOptions.length; i++) {
        if (newOptions[i] !== oldOptions[i]) {
            return true;
        }
    }

    return false;
}

/* eslint-disable deprecation/deprecation */
export function updateLegacyTarget(target: WideSkyQueryJSONTarget): WideSkyQueryJSONTarget {
    const legacyTarget = target as unknown as LegacyWideSkyTarget;

    const newTarget: Omit<WideSkyQueryJSONTarget, 'formatType' | 'config'> = {
        refId: legacyTarget.refId,
        query: legacyTarget.graphq,
        datasource: legacyTarget.datasource,
        hide: legacyTarget.hide,
        setColumnOptions: target.setColumnOptions,
        setNodeLayers: target.setNodeLayers,
        setQueryInfo: target.setQueryInfo,
    };

    switch (legacyTarget.formatAs.choice) {
        case 'table2022':
            return {
                ...newTarget,
                config: updateLegacyTableConfig(legacyTarget),
                formatType: 'TABLE',
            };
        case 'table':
            return {
                ...newTarget,
                config: updateLegacyDeprecatingTableConfig(legacyTarget),
                formatType: 'DEPRECATING_TABLE',
            };
        case 'time_series':
            return {
                ...newTarget,
                config: updateLegacyTimeSeriesConfig(legacyTarget),
                formatType: 'TIME_SERIES',
            };
        case 'node_graph':
            return {
                ...newTarget,
                config: {},
                formatType: 'NODE_GRAPH',
            };
        case 'widesky':
            return {
                ...newTarget,
                config: {},
                formatType: 'REAL_TIME',
            };
        default:
            console.error(
                `Failed to update legacy format (${legacyTarget.formatAs.choice}), defaulting to time series`
            );
            return {
                ...newTarget,
                config: {},
                formatType: 'TIME_SERIES',
            };
    }
}

const updateLegacyTableConfig = (legacyTarget: LegacyWideSkyTarget): TableConfig => {
    const fillFlatten = legacyTarget.formatAs.fillFlatten || false;

    if (legacyTarget.tblColumnCfg === undefined) {
        return { fillFlatten, columns: [] };
    }

    const columns = Object.keys(legacyTarget.tblColumnCfg).map((key) => ({
        base: legacyTarget.tblColumnCfg![key].val,
        flatten: legacyTarget.tblColumnCfg![key].flattenWith,
    }));

    return { fillFlatten, columns };
};

const updateLegacyDeprecatingTableConfig = (legacyTarget: LegacyWideSkyTarget): DeprecatingTableConfig => {
    if (legacyTarget.tblColumnCfg === undefined) {
        return { columns: [] };
    }

    const columns = Object.keys(legacyTarget.tblColumnCfg).map((key) => ({
        base: legacyTarget.tblColumnCfg![key].val,
        flatten: legacyTarget.tblColumnCfg![key].flattenWith,
    }));

    return {
        columns,
    };
};

const updateLegacyTimeSeriesConfig = (legacyTarget: LegacyWideSkyTarget): TimeSeriesConfig => {
    return { alias: legacyTarget.alias?.value };
};

/* eslint-enable deprecation/deprecation */
