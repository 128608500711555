import { DataFrame, Field, FieldType } from '@grafana/data';
import { ASTNode, Kind, parse } from 'graphql';
import { Column, Config } from './config';
import { GraphQLResponse } from 'types';

type Cell = number | string | boolean | undefined;

export function createTableDeprecatingData(
    graphQLRoot: Readonly<GraphQLResponse>,
    query: string,
    config: Config,
    setColumnOptions?: (options: string[]) => void
): DataFrame[] {
    const table = new Table();

    const columns = config.columns || [];

    const baseColumns: string[] = columns.filter((column) => column.base !== undefined).map((column) => column.base!);

    table.processQuery(query);
    table.processGraph(graphQLRoot, new Set(baseColumns));

    if (setColumnOptions) {
        setColumnOptions(Array.from(table.columns));
    }

    if (baseColumns.length === 0) {
        return [
            {
                fields: [],
                meta: {
                    preferredVisualisationType: 'table',
                },
                length: baseColumns.length,
            },
        ];
    }

    const collapsedRows = table.collapse(columns, baseColumns);

    const fields: Field[] = baseColumns.map((column) => ({
        type: FieldType.string,
        values: collapsedRows[column],
        config: {},
        name: column,
    }));

    const response: DataFrame = {
        fields,
        meta: {
            preferredVisualisationType: 'table',
        },
        length: fields.at(0)?.values.length ?? 0,
    };

    return [response];
}

class Table {
    public columns: Set<string> = new Set();
    private content: Array<Record<string, Cell>> = [{}];

    public processGraph(graphQLRoot: Readonly<GraphQLResponse>, columnSet: Set<string>) {
        const node = graphQLRoot.haystack;

        if (!(node instanceof Object)) {
            throw Error('Haystack object not found in response or is not Object type. Check query');
        }

        this.walkGraph(node, 'haystack', 0, columnSet);
        const addedContent = this.content.at(0);
        if (addedContent && Object.keys(addedContent).length === 0) {
            this.content.splice(length, 1);
        }
    }

    public processQuery(query: string) {
        if (query.trimStart().at(0) !== '{') {
            query = `{${query}}`;
        }

        const queryNode = parse(query).definitions[0];

        if (queryNode.kind !== Kind.OPERATION_DEFINITION) {
            return;
        }

        const insertColumns = (node: ASTNode, name: string): void => {
            if (node.kind !== Kind.INLINE_FRAGMENT && node.kind !== Kind.FIELD) {
                return;
            }

            const nextName =
                node.kind === Kind.INLINE_FRAGMENT
                    ? name
                    : `${name}${name ? '.' : ''}${node.alias ? node.alias.value : node.name.value}`;

            if (!node.selectionSet) {
                if (!this.columns.has(nextName)) {
                    this.columns.add(nextName);
                }
                return;
            }

            node.selectionSet.selections.forEach((subSelection) => insertColumns(subSelection, nextName));
        };

        insertColumns(queryNode.selectionSet.selections[0], '');
    }

    private static getValidPositions(rows: Cell[]): number[] {
        return rows
            .map((row, index) => (row !== undefined ? index : undefined))
            .filter((index): index is number => index !== undefined);
    }

    public collapse(columns: Column[], baseColumns: string[]): Record<string, Cell[]> {
        const tableAsColumns = baseColumns.reduce<Record<string, Cell[]>>((tableAsColumns, column) => {
            tableAsColumns[column] = this.content.map((row) => row[column]);
            return tableAsColumns;
        }, {});

        columns
            .filter(
                (column): column is Required<Column> =>
                    column.base !== undefined &&
                    column.flatten !== undefined &&
                    column.flatten !== 'None' &&
                    column.base in tableAsColumns &&
                    column.flatten in tableAsColumns
            )
            .forEach((column) => {
                const parentPositions = Table.getValidPositions(tableAsColumns[column.flatten]);
                const sourcePositions = Table.getValidPositions(tableAsColumns[column.base]);
                const aligned = new Array<Cell>(this.content.length).fill(undefined);

                sourcePositions.forEach((source) => {
                    const validParents = parentPositions.filter((parent) => parent <= source);
                    let alignedPosition = validParents.length === 0 ? source : Math.max(...validParents);

                    while (aligned[alignedPosition] !== undefined) {
                        alignedPosition += 1;
                    }

                    aligned[alignedPosition] = tableAsColumns[column.base][source];
                });

                tableAsColumns[column.base] = aligned;
            });

        const validIndices = Array.from(Array(this.content.length).keys()).filter((index) =>
            baseColumns.some((column) => tableAsColumns[column][index] !== undefined)
        );

        const result: Record<string, Cell[]> = {};
        baseColumns.forEach((column) => {
            result[column] = validIndices.map((index) =>
                tableAsColumns[column][index] === undefined || tableAsColumns[column][index] === null
                    ? ''
                    : tableAsColumns[column][index]
            );
        });

        return result;
    }

    private walkGraph(currentNode: any, currentPath: string, rowIndex: number, columnSet: Set<string>) {
        if (Array.isArray(currentNode)) {
            currentNode.forEach((nextNode) => {
                const length = this.content.length;
                this.content.push({});
                this.walkGraph(nextNode, currentPath, length, columnSet);
                const addedContent = this.content.at(length);
                if (addedContent && Object.keys(addedContent).length === 0) {
                    this.content.splice(length, 1);
                }
            });
            return;
        }

        if (typeof currentNode === 'object' && currentNode !== null) {
            Object.keys(currentNode).forEach((key) =>
                this.walkGraph(currentNode[key], `${currentPath}.${key}`, rowIndex, columnSet)
            );
            return;
        }

        if (columnSet.has(currentPath)) {
            if (currentNode === '') {
                this.content[rowIndex][currentPath] = currentNode;
                return;
            }

            if (currentNode === null) {
                this.content[rowIndex][currentPath] = undefined;
                return;
            }

            const nodeAsNumber = Number(currentNode);
            this.content[rowIndex][currentPath] = isNaN(nodeAsNumber) ? currentNode : nodeAsNumber;
        }
    }
}
