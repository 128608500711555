import { Bounds, NodeDatum, Position } from 'types';

/**
 * Calculates the bounding box of the given nodes.
 *
 * @param {Readonly<NodeDatum[]>} nodes - An array of nodes to calculate the bounds for.
 * @param {boolean} includeChildren - A flag indicating whether to include the bounds of child nodes.
 * @returns {Bounds} - An object representing the bounding box of the nodes.
 */
export function nodeBounds(nodes: Readonly<NodeDatum[]>, includeChildren: boolean): Bounds {
    if (nodes.length === 0) {
        return { top: 0, right: 0, bottom: 0, left: 0 };
    }

    return nodes.reduce<Bounds>(
        (bounds, node) => {
            if (includeChildren === true) {
                const containedChildren = node.children.filter(
                    (child) => child.groupId === node.id || child.groupId === node.groupId
                );
                const childBounds = nodeBounds(containedChildren, true);

                if (childBounds.left !== childBounds.right) {
                    bounds.right = Math.max(bounds.right, childBounds.right);
                    bounds.left = Math.min(bounds.left, childBounds.left);
                    bounds.bottom = Math.max(bounds.bottom, childBounds.bottom);
                    bounds.top = Math.min(bounds.top, childBounds.top);
                }
            }
            bounds.right = Math.max(bounds.right, node.bounds.right);
            bounds.left = Math.min(bounds.left, node.bounds.left);
            bounds.bottom = Math.max(bounds.bottom, node.bounds.bottom);
            bounds.top = Math.min(bounds.top, node.bounds.top);
            return bounds;
        },
        { top: Infinity, right: -Infinity, bottom: -Infinity, left: Infinity }
    );
}

/**
 * Translates a group of nodes by the specified position.
 *
 * @param {NodeDatum} node - The node to be translated.
 * @param {Position} translateBy - The amount to translate the node by.
 * @param {boolean} rootInclusive - A flag indicating whether to include the root node in the translation.
 */
export function translateGroup(node: NodeDatum, translateBy: Position, rootInclusive: boolean) {
    if (rootInclusive === true) {
        node.bounds.left += translateBy.x;
        node.bounds.right += translateBy.x;
        node.bounds.top += translateBy.y;
        node.bounds.bottom += translateBy.y;
    }

    rootInclusive = true;

    node.children.forEach((child) => {
        if (child.groupId !== node.id && child.groupId !== node.groupId) {
            return;
        }

        translateGroup(child, translateBy, rootInclusive);
    });
}

/**
 * Expands a group of nodes to the right by the specified amount.
 *
 * @param {NodeDatum} node - The node to be expanded.
 * @param {number} expandBy - The amount to expand the node by.
 */
export function expandGroup(node: NodeDatum, expandBy: number) {
    node.bounds.right += expandBy;

    node.children.forEach((child) => {
        if (child.groupId !== node.id && child.groupId !== node.groupId) {
            return;
        }

        expandGroup(child, expandBy);
    });
}

/**
 * Checks if a node exists in a list of nodes.
 *
 * @param {NodeDatum} node - The node to check for.
 * @param {NodeDatum[]} nodesList - The list of nodes to check against.
 * @returns {boolean} - True if the node exists in the list, false otherwise.
 */
export function nodeExistsInList(node: NodeDatum, nodesList: NodeDatum[]): boolean {
    if (nodesList.findIndex((listNode) => listNode.id === node.id) !== -1) {
        return true;
    }

    for (const listNode of nodesList) {
        if (listNode.children.length === 0) {
            continue;
        }

        if (nodeExistsInList(node, listNode.children)) {
            return true;
        }
    }

    return false;
}

/**
 * Finds a node by its ID in a list of nodes.
 *
 * @param {string} id - The ID of the node to find.
 * @param {NodeDatum[]} nodes - The list of nodes to search in.
 * @returns {NodeDatum | undefined} - The found node or undefined if not found.
 */
export function findNodeById(id: string, nodes: NodeDatum[]): NodeDatum | undefined {
    const foundNode = nodes.find((node) => node.id === id);
    if (foundNode) {
        return foundNode;
    }

    for (const node of nodes) {
        const foundNode = findNodeById(id, node.children);
        if (foundNode) {
            return foundNode;
        }
    }

    return undefined;
}
