import {
    colorManipulator,
    PanelOptionsEditorBuilder,
    StandardEditorContext,
    StandardEditorProps,
    StringFieldConfigSettings,
} from '@grafana/data';
import { Input, useTheme2 } from '@grafana/ui';
import React, { ReactNode, useCallback } from 'react';
import {
    ColorMap,
    defaultEdgeColor,
    defaultEdgeHighlight,
    defaultNodeSecondaryColor,
    defaultNodeTextColor,
    EdgeRenderOptions,
    GenericLayerElement,
    LayerConfig,
    NodeRenderOptions,
} from './editor.types';

type PanelOptionsSupplier<TOptions> = (
    builder: PanelOptionsEditorBuilder<TOptions>,
    context: StandardEditorContext<TOptions>
) => void;

interface NestedValueAccess {
    getValue: (path: string) => any;
    onChange: (path: string, value: any) => void;
}
interface NestedPanelOptions<TSub = any> {
    path: string;
    category?: string[];
    build: PanelOptionsSupplier<TSub>;
    values?: (parent: NestedValueAccess) => NestedValueAccess;
}

export interface LayerEditorOptions {
    element: GenericLayerElement;
    onChange: (layer: LayerConfig) => void;
    category: string[];
}

export function LayerEditor(layerOptions: LayerEditorOptions): NestedPanelOptions<GenericLayerElement> {
    const theme = useTheme2();

    return {
        category: layerOptions.category,
        path: '',
        values: () => ({
            getValue: (path: string) => {
                return (layerOptions.element.options as any)[path];
            },
            onChange: (path: string, value: string) => {
                // Hacky work-around because 'addColorPicker->enableNamedColors' doesn't appear to be working... :(
                if (
                    path === 'color' ||
                    path === 'highlight' ||
                    path === 'primaryColor' ||
                    path === 'secondaryColor' ||
                    path === 'textColor'
                ) {
                    value = colorManipulator.asHexString(theme.visualization.getColorByName(value));
                }

                const newLayer: LayerConfig = { ...layerOptions.element.options, [path]: value };
                layerOptions.onChange(newLayer);
            },
        }),
        build: (builder) => {
            if (layerOptions.element.options.type === 'node') {
                setupNodeEditor(
                    builder as PanelOptionsEditorBuilder<GenericLayerElement<NodeRenderOptions>>,
                    layerOptions.element.options
                );
            } else {
                setupEdgeEditor(builder as PanelOptionsEditorBuilder<GenericLayerElement<EdgeRenderOptions>>);
            }
        },
    };
}

function setupNodeEditor(
    builder: PanelOptionsEditorBuilder<GenericLayerElement<NodeRenderOptions>>,
    options: NodeRenderOptions
) {
    builder.addRadio({
        name: 'Group Type',
        path: 'groupType',
        description: 'Select how the group is displayed',
        settings: {
            options: [
                { value: 'box', label: 'Box' },
                { value: 'column', label: 'Column' },
                {
                    value: 'node',
                    label: 'Node',
                    icon: 'exclamation-triangle',
                    description: 'Experimental, may not work as intended!',
                },
            ],
        },
        defaultValue: 'node',
    });

    builder.addColorPicker({
        name: 'Primary Color',
        path: 'primaryColor',
        defaultValue: ColorMap[options.groupType || 'node'],
    });

    builder.addColorPicker({
        name: 'Secondary Color',
        path: 'secondaryColor',
        defaultValue: defaultNodeSecondaryColor,
    });

    if (options.groupType === 'node') {
        builder.addColorPicker({
            name: 'Text Color',
            path: 'textColor',
            defaultValue: defaultNodeTextColor,
        });

        builder.addBooleanSwitch({
            name: 'Show Tooltip',
            path: 'showTooltip',
            defaultValue: true,
        });

        builder.addBooleanSwitch({
            name: 'Enable Data Linking',
            description: 'Allow for external links',
            path: 'dataLinkingEnabled',
            defaultValue: false,
        });

        if (options.dataLinkingEnabled) {
            builder.addCustomEditor({
                id: 'text',
                name: 'Linked URL Title',
                path: 'urlTitle',
                editor: StringValueEditor,
            });

            builder.addCustomEditor({
                id: 'text',
                name: 'Linked URL',
                path: 'url',
                editor: StringValueEditor,
            });

            builder.addBooleanSwitch({
                name: 'Open In New Tab',
                description: 'Choose if the link to be opened in a new tab',
                path: 'openInNewTab',
                defaultValue: false,
            });
        }
    }
}

function setupEdgeEditor(builder: PanelOptionsEditorBuilder<GenericLayerElement<EdgeRenderOptions>>) {
    builder.addColorPicker({
        name: 'Primary Color',
        path: 'color',
        defaultValue: defaultEdgeColor,
    });

    builder.addColorPicker({
        name: 'Highlight Color',
        path: 'highlight',
        defaultValue: defaultEdgeHighlight,
    });
}

interface StringValueProps extends StandardEditorProps<string, StringFieldConfigSettings> {
    suffix?: ReactNode;
}

const StringValueEditor = ({ value, onChange, item, suffix }: StringValueProps) => {
    const onValueChange = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
            let nextValue = value ?? '';

            if ('key' in e) {
                if (e.key === 'Enter' && !item.settings?.useTextarea) {
                    nextValue = e.currentTarget.value.trim();
                }
            } else {
                nextValue = e.currentTarget.value.trim();
            }

            if (nextValue === value) {
                return;
            }

            onChange(nextValue === '' ? undefined : nextValue);
        },
        [value, item.settings?.useTextarea, onChange]
    );

    return (
        <Input
            placeholder={item.settings?.placeholder}
            value={value || ''}
            onChange={onValueChange}
            onBlur={onValueChange}
            onKeyDown={onValueChange}
            suffix={suffix}
        />
    );
};
