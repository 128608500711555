import { DataFrame, Field, FieldType } from '@grafana/data';
import { GraphQLResponse } from 'types';
import { SortableValue, sortVariableValues } from 'utils/sort';
import { Config } from './config';
import { VariableSort } from '@grafana/schema';

interface Point {
    time: number;
    value: string | number | boolean | null;
}

interface Series {
    name: string;
    points: Point[];
    fullName?: string;
    sortValue?: string;
    measurementUnits?: string;
}

interface Data {
    alias?: string;
    sortAlias?: string;
    root: any;
    series: Series[];
    measurementUnits?: string;
    description?: string;
}

const ENTITY_NODE_NAME = 'entity';
const DIS_TAG_NAME = 'description';
const TIME_SERIES_ARRAY_NAME = 'dataPoints';
const COMBINED_UNITS_NODE_NAME = 'units';
const ALIAS_SET_PATTERN = /\{(.*?)\}/g;

export const createTimeSeriesData = (
    rootNode: Readonly<GraphQLResponse>,
    config: Readonly<Config>,
    refId: string
): DataFrame[] => {
    const data: Data = {
        root: rootNode,
        series: [],
        alias: config.alias,
        sortAlias: config.sortAlias,
    };

    walkGraphObjectTimeSeries(rootNode, 'haystack', data);

    // Sort series
    if (config.sort !== undefined && config.sort !== VariableSort.disabled) {
        const sortableSeries = data.series.map<SortableValue<Series>>((series) => {
            return { value: series, sortValue: series.sortValue || '' };
        });

        const sortedArray = sortVariableValues(sortableSeries, config.sort);
        if (sortedArray.length === data.series.length) {
            data.series = sortedArray;
        } else {
            console.error('Failed to create a sortable alias for all series (Series remain unsorted)');
        }
    }

    return data.series.map<DataFrame>((series, index) => {
        const name = series.fullName || `series-${(index + 10).toString(36)}-${series.name}`;

        const timeField: Field = {
            values: series.points.map((point) => point.time),
            name: 'Time',
            type: FieldType.time,
            config: {},
        };

        const pointField: Field = {
            values: series.points.map((point) => pointFormat(point.value)),
            name: 'Value',
            type: FieldType.number,
            config: {
                unit: series.measurementUnits ? wideskyToGrafanaUnits[series.measurementUnits] : undefined,
            },
        };

        const dataFrame: DataFrame = {
            name,
            refId: `${refId} - ${name}`,
            fields: [timeField, pointField],
            meta: {
                preferredVisualisationType: 'graph',
            },
            length: series.points.length,
        };

        return dataFrame;
    });
};

/*****************************************************
 * This algorithm parses a Widesky graph QL response
 * and look for two things.
 * 1) Entities
 * 2) Time series data.
 *
 * The goal of this algorithm is to map the
 * timeseries data to their respective entity
 * and return them.
 *
 ******************************************************/
const walkGraphArrayTimeSeries = (curNode: any, curPath: string, result: Data) => {
    const curTag = curPath.substring(curPath.lastIndexOf('.') + 1);
    if (curTag === ENTITY_NODE_NAME) {
        curNode.forEach((node: any, idx: number) => {
            const description = result.description;
            if (node.hasOwnProperty(DIS_TAG_NAME)) {
                result.description = node[DIS_TAG_NAME];
            }

            walkGraphObjectTimeSeries(node, curPath + '[' + idx + ']', result);
            result.description = description;
        });
    } else if (curTag === TIME_SERIES_ARRAY_NAME) {
        let fullName = result.description;

        if (result.alias !== undefined) {
            const path = curPath.substring(curPath.indexOf('.') + 1, curPath.lastIndexOf('.'));
            fullName = buildAliasName(result.alias, path, result.root) || fullName;
        }

        let sortValue = fullName;
        if (result.sortAlias !== undefined) {
            const path = curPath.substring(curPath.indexOf('.') + 1, curPath.lastIndexOf('.'));
            sortValue = buildAliasName(result.sortAlias, path, result.root);
        }

        result.series.push({
            name: curTag,
            points: curNode,
            measurementUnits: result.measurementUnits,
            fullName,
            sortValue,
        });
    } else {
        curNode.forEach((node: any, idx: number) => {
            walkGraphObjectTimeSeries(node, curPath + '[' + idx + ']', result);
        });
    }
};

const resolvePaths = (aliasPath: string, source: string): string[] => {
    const paths = source.split('.');
    const parts = aliasPath.split('/').filter((part) => part !== '.');

    parts.forEach((part) => {
        if (part === '..') {
            paths.pop();
            return;
        }

        part.split('.')
            .filter((subpart) => subpart !== '.' && subpart)
            .forEach((subpart) => {
                paths.push(subpart);
            });
    });

    return paths;
};

const buildAliasName = (aliasPath: string, source: string, node: any) => {
    const resolvedPath = aliasPath.replace(ALIAS_SET_PATTERN, (_matched, captured) => {
        const resolvedPaths = resolvePaths(captured, source);
        let name = node;

        resolvedPaths.forEach((path) => {
            if (name === null || name === undefined) {
                return;
            }

            if (!path.endsWith(']')) {
                name = name[path];
                return;
            }

            path.split('[').forEach((subPath) => {
                if (subPath.endsWith(']')) {
                    subPath = subPath.slice(0, subPath.length - 1);
                }

                name = name[subPath];
            });
        });

        return typeof name === 'string' ? name : '';
    });

    return resolvedPath.trim() === '' ? undefined : resolvedPath;
};

const walkGraphObjectTimeSeries = (curNode: any, curPath: string, result: Data) => {
    if (curNode.hasOwnProperty(COMBINED_UNITS_NODE_NAME)) {
        result.measurementUnits = curNode[COMBINED_UNITS_NODE_NAME];
    } else if (curNode.hasOwnProperty('tags')) {
        const tags = curNode['tags'];
        const unit = tags.filter((tag: any) => tag['name'] === 'unit')[0];
        if (unit) {
            result.measurementUnits = unit['string'];
        }
    }

    for (const tag in curNode) {
        const node = curNode[tag];
        if (node instanceof Array) {
            walkGraphArrayTimeSeries(node, `${curPath}.${tag}`, result);
        } else if (node instanceof Object) {
            const description = result.description;
            if (description) {
                result.description += `-${tag}`;
            }
            walkGraphObjectTimeSeries(node, `${curPath}.${tag}`, result);
            result.description = description;
        }
    }
};

const pointFormat = (value: string | number | boolean | null): number | null => {
    switch (typeof value) {
        case 'boolean':
            return value ? 1 : 0;
        case 'number':
            return value;
        case 'string': {
            if (value === 'true' || value === 'false') {
                return value === 'true' ? 1 : 0;
            }
            return Number(value);
        }
    }

    return value;
};

const wideskyToGrafanaUnits: Record<string, string> = {
    '%': 'percent',
    '%RH': 'humidity',
    $: 'currencyUSD',
    '£': 'currencyGBP',
    '€': 'currencyEUR',
    '¥': 'currencyJPY',
    Hz: 'hertz',
    V: 'volt',
    kV: 'Kilovolt',
    J: 'joule',
    kW: 'kwatt',
    Wh: 'watth',
    kWh: 'kwatth',
    kVARh: 'kvoltampreact',
    VARh: 'voltampreact',
    N: 'forceN',
    m: 'lengthm',
    km: 'lengthkm',
    mile: 'mile',
    '°C': 'celsius',
    '°F': 'farenheit',
    K: 'kelvin',
    cfs: 'flowcfs',
    cfm: 'cubic_feet_per_minute',
    byte: 'bytes',
    kB: 'deckbytes',
    MB: 'decmbytes',
    GB: 'decgbytes',
    ns: 'ns',
    µs: 'µs',
    ms: 'ms',
    s: 's',
    min: 'm',
    h: 'h',
    day: 'd',
    'm/s': 'velocityms',
    'km/h': 'velocitykmh',
    mph: 'velocitymph',
    knot: 'velocityknot',
    mL: 'mlitre',
    L: 'litre',
    VA: 'voltamp',
    kVA: 'kvoltamp',
    VAR: 'voltampreact',
    kVAR: 'kvoltampreact',
};
