import { css } from '@emotion/css';
import React from 'react';

import { Button, HorizontalGroup, useStyles2 } from '@grafana/ui';

const getStyles = () => ({
    wrapper: css({
        label: 'wrapper',
        pointerEvents: 'all',
    }),
});

interface Props {
    onPlus: () => void;
    onMinus: () => void;
    onZoomToFit: () => void;
    disableZoomOut?: boolean;
    disableZoomIn?: boolean;
}

export function ViewControls(props: Props) {
    const { onPlus, onMinus, onZoomToFit, disableZoomOut, disableZoomIn } = props;

    const styles = useStyles2(getStyles);

    return (
        <div className={styles.wrapper}>
            <HorizontalGroup spacing="xs">
                <Button
                    icon={'arrows-v'}
                    onClick={onZoomToFit}
                    size={'md'}
                    title={'Zoom to fit'}
                    variant="secondary"
                />
                <Button
                    icon={'plus-circle'}
                    onClick={onPlus}
                    size={'md'}
                    title={'Zoom in'}
                    variant="secondary"
                    disabled={disableZoomIn}
                />
                <Button
                    icon={'minus-circle'}
                    onClick={onMinus}
                    size={'md'}
                    title={'Zoom out'}
                    variant="secondary"
                    disabled={disableZoomOut}
                />
            </HorizontalGroup>
        </div>
    );
}
