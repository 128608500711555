import { NodeDatum, nodeHeight, Position } from 'types';
import { expandGroup, findNodeById, nodeBounds, translateGroup } from './utils';

const padding: Position = { x: nodeHeight / 2, y: nodeHeight };
const nodePadding: Position = { x: nodeHeight, y: nodeHeight * 1.5 };

/**
 * Processes the layout of nodes, optimizing their positions and ensuring they are correctly placed.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to be processed.
 */
export function processLayout(nodes: NodeDatum[]) {
    locallyOptimisePosition(nodes, nodes);
    initializePositions(nodes);
    reduceNodes(nodes);
    globallyOptimisePositon(nodes, nodes);
    expandColumns(nodes);
    centerNodes(nodes);
}

/**
 * Initializes positions of the nodes, setting their initial bounds and applying padding.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to initialize positions for.
 */
function initializePositions(nodes: NodeDatum[]) {
    let offset = 0;
    const collectionHasColumns = nodes.some((node) => node.groupType === 'column');

    nodes.forEach((node) => {
        if (node.hasBeenPlaced) {
            return;
        }

        const containedNodes = node.children.filter(
            (child) => child.groupId === node.id || child.groupId === node.groupId
        );

        if (containedNodes.length === 0) {
            node.bounds.top += offset;
            node.bounds.bottom += offset;
            offset = node.bounds.bottom + padding.y;
            node.hasBeenPlaced = true;
            return;
        }

        initializePositions(containedNodes);
        placeElement(node, containedNodes);

        if (collectionHasColumns) {
            translateGroup(node, { x: offset === 0 ? 0 : offset - node.bounds.left, y: 0 }, true);
            offset = node.bounds.right + padding.x;
        } else {
            translateGroup(node, { x: 0, y: offset }, true);
            offset = node.bounds.bottom + padding.y;
        }

        node.hasBeenPlaced = true;
    });

    if (collectionHasColumns) {
        return;
    }

    const bounds = nodeBounds(nodes, false);
    nodes.forEach((node) => {
        switch (node.groupType) {
            case 'node':
                node.bounds.right = bounds.right;
                return;
            case 'box': {
                const expansion = bounds.right - node.bounds.right;
                if (expansion === 0) {
                    return;
                }

                expandGroup(node, expansion);
                return;
            }
        }
    });
}

/**
 * Places a node within its contained nodes, adjusting its bounds and applying padding.
 *
 * @param {NodeDatum} node - The node to be placed.
 * @param {NodeDatum[]} containedNodes - The contained nodes within the node.
 */
function placeElement(node: NodeDatum, containedNodes: NodeDatum[]) {
    const isNode = node.groupType === 'node';

    const bounds = nodeBounds(containedNodes, false);

    if (bounds.left !== bounds.right) {
        node.bounds = bounds;
        if (isNode) {
            node.bounds.right += nodePadding.x;
            node.bounds.bottom += nodePadding.y;
        } else {
            node.bounds.right += padding.x * 2;
            node.bounds.bottom += padding.y * 2;
        }
    }

    const elementPadding = isNode ? nodePadding : padding;
    translateGroup(node, elementPadding, false);
}

/**
 * Optimizes the positions of nodes locally within their groups.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to be optimized.
 * @param {NodeDatum[]} allNodes - An array of all nodes.
 */
function locallyOptimisePosition(nodes: NodeDatum[], allNodes: NodeDatum[]) {
    nodes.forEach((node) => {
        if (node.children.length === 0) {
            return;
        }

        if (node.groupType !== 'node' || node.groupId === undefined) {
            locallyOptimisePosition(node.children, allNodes);
            return;
        }

        const externalNodeIds = new Set(
            node.children
                .filter(
                    (child): child is Required<NodeDatum> =>
                        child.groupId !== undefined && child.groupId !== node.groupId
                )
                .map((child) => child.groupId)
        );

        if (externalNodeIds.size === 0) {
            locallyOptimisePosition(node.children, allNodes);
            return;
        }

        const thisGroup = findNodeById(node.groupId, allNodes);

        if (thisGroup === undefined || thisGroup.groupId === undefined) {
            console.log(`no node group for: ${node.primaryText || ''}`);
            locallyOptimisePosition(node.children, allNodes);
            return;
        }

        // The group contained by both nodes
        const parentGroup = findNodeById(thisGroup.groupId, allNodes);

        if (parentGroup === undefined) {
            console.log(`no parent group for: ${thisGroup?.primaryText || ''}`);
            locallyOptimisePosition(node.children, allNodes);
            return;
        }

        moveInternalNodeGroupsToValidateThisNode(externalNodeIds, thisGroup, parentGroup, allNodes);
        locallyOptimisePosition(node.children, allNodes);
    });
}

/**
 * Moves internal node groups to validate the current node, adjusting their positions.
 *
 * @param {Set<string>} externalNodeIds - A set of external node IDs to validate.
 * @param {NodeDatum} thisGroup - The current node group.
 * @param {NodeDatum} parentGroup - The parent node group.
 * @param {NodeDatum[]} allNodes - An array of all nodes.
 */
function moveInternalNodeGroupsToValidateThisNode(
    externalNodeIds: Set<string>,
    thisGroup: NodeDatum,
    parentGroup: NodeDatum,
    allNodes: NodeDatum[]
) {
    const thisIndex = parentGroup.children.indexOf(thisGroup);

    externalNodeIds.forEach((externalNodeId) => {
        const externalNodeGroup = findNodeById(externalNodeId, allNodes);

        if (externalNodeGroup === undefined) {
            return;
        }

        if (thisGroup.groupId !== externalNodeGroup.groupId) {
            return;
        }

        const externalIndex = parentGroup.children.indexOf(externalNodeGroup);

        if (thisIndex < externalIndex) {
            return;
        }

        if (
            externalIndex < 0 ||
            externalIndex >= parentGroup.children.length ||
            thisIndex < 0 ||
            thisIndex >= parentGroup.children.length
        ) {
            console.error(
                `Node does not exist in parent: ${thisGroup.primaryText || ''} or ${
                    externalNodeGroup.primaryText || ''
                }`
            );
            return;
        }

        const element = parentGroup.children.splice(externalIndex, 1)[0];
        parentGroup.children.splice(thisIndex, 0, element);
    });
}

/**
 * Optimizes the positions of nodes globally within the entire graph.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to be optimized.
 * @param {NodeDatum[]} allNodes - An array of all nodes.
 */
function globallyOptimisePositon(nodes: NodeDatum[], allNodes: NodeDatum[]) {
    nodes.forEach((node) => {
        if (node.children.length === 0) {
            return;
        }

        if (node.groupType !== 'node' || node.groupId === undefined) {
            globallyOptimisePositon(node.children, allNodes);
            return;
        }

        const externalNodeIds = new Set(
            node.children.filter(
                (child): child is Required<NodeDatum> => child.groupId !== undefined && child.groupId !== node.groupId
            )
        );

        if (externalNodeIds.size === 0) {
            globallyOptimisePositon(node.children, allNodes);
            return;
        }

        moveExternalNodeGroupsToValidateThisNode(externalNodeIds, node, allNodes);
        globallyOptimisePositon(node.children, allNodes);
    });
}

/**
 * Finds the first non-node group for the given node.
 *
 * @param {NodeDatum} node - The node to find the group for.
 * @param {NodeDatum[]} allNodes - An array of all nodes.
 * @returns {NodeDatum | undefined} - The first non-node group or undefined if not found.
 */
function findFirstNonNodeGroup(node: NodeDatum, allNodes: NodeDatum[]): NodeDatum | undefined {
    if (!node.groupId) {
        return;
    }

    const group = findNodeById(node.groupId, allNodes);
    if (!group) {
        return;
    }

    if (group.groupType === 'node') {
        return findFirstNonNodeGroup(group, allNodes);
    }

    return group;
}

/**
 * Checks if the node conflicts with others in the group and adjusts its position if needed.
 *
 * @param {NodeDatum} node - The node to check for conflicts.
 * @param {NodeDatum[]} siblings - The sibling nodes in the group.
 */
function conflictsWithOthersInGroup(node: NodeDatum, siblings: NodeDatum[]) {
    const bottom = siblings.reduce<number>((bottom, sibling) => Math.max(sibling.bounds.bottom, bottom), 0);

    for (const sibling of siblings) {
        if (node.bounds.top <= sibling.bounds.bottom || node.bounds.bottom >= sibling.bounds.top) {
            translateGroup(node, { x: 0, y: bottom - node.bounds.top + padding.y / 2 }, true);
            return;
        }
    }
}

/**
 * Moves external node groups to validate the current node, adjusting their positions.
 *
 * @param {Set<NodeDatum>} externalNodes - A set of external nodes to validate.
 * @param {NodeDatum} thisNode - The current node.
 * @param {NodeDatum[]} allNodes - An array of all nodes.
 */
function moveExternalNodeGroupsToValidateThisNode(
    externalNodes: Set<NodeDatum>,
    thisNode: NodeDatum,
    allNodes: NodeDatum[]
) {
    externalNodes.forEach((externalNode) => {
        if (thisNode.bounds.bottom < externalNode.bounds.top) {
            return;
        }

        const thisNodeGroup = findFirstNonNodeGroup(thisNode, allNodes);
        const externalNodeGroup = findFirstNonNodeGroup(externalNode, allNodes);

        if (
            thisNodeGroup === undefined ||
            externalNodeGroup === undefined ||
            thisNodeGroup.groupId === externalNodeGroup.groupId
        ) {
            return;
        }

        translateGroup(
            externalNodeGroup,
            { x: 0, y: thisNode.bounds.bottom - externalNode.bounds.top + padding.y * 2 },
            true
        );

        const externalNodeGroupParent = findFirstNonNodeGroup(externalNodeGroup, allNodes);

        if (!externalNodeGroupParent) {
            return;
        }

        conflictsWithOthersInGroup(
            externalNodeGroup,
            externalNodeGroupParent.children.filter((node) => node.id !== externalNodeGroup.id)
        );

        if (thisNode.bounds.bottom > externalNode.bounds.top) {
            translateGroup(
                externalNodeGroup,
                { x: 0, y: thisNode.bounds.bottom - externalNode.bounds.top + padding.y * 2 },
                true
            );
            return;
        }
    });
}

/**
 * Reduces the nodes to ensure their bounds are correctly set.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to be reduced.
 */
function reduceNodes(nodes: NodeDatum[]) {
    nodes.forEach((node) => {
        reduceNodes(node.children);

        if (node.groupType === 'node') {
            node.bounds.bottom = node.bounds.top + nodeHeight;
        }
    });
}

/**
 * Expands columns within the nodes to ensure they have the correct height.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to expand columns for.
 */
function expandColumns(nodes: NodeDatum[]) {
    if (nodes.at(0)?.groupType === 'column') {
        const height = nodes.reduce<number>((height, node) => {
            return Math.max(height, nodeBounds(node.children, false).bottom);
        }, 0);
        nodes.forEach((node) => (node.bounds.bottom = height + padding.y * 2));
    }

    nodes.forEach((node) => expandColumns(node.children));
}

/**
 * Centers the nodes within their bounds.
 *
 * @param {NodeDatum[]} nodes - An array of nodes to be centered.
 */
function centerNodes(nodes: NodeDatum[]) {
    const bounds = nodeBounds(nodes, true);

    const center: Position = {
        x: -(bounds.left + (bounds.right - bounds.left) / 2),
        y: -(bounds.top + (bounds.bottom - bounds.top) / 2),
    };

    nodes.forEach((node) => translateGroup(node, center, true));
}
