import { isEmptyValue } from 'components/state/helpers';
import { WideSkyLabel } from 'components/WideSkyVisualComponents';
import {
    GraphQLInputType,
    isEnumType,
    isInputObjectType,
    isListType,
    isScalarType,
    Kind,
    ObjectFieldNode,
    ValueNode,
} from 'graphql';
import React from 'react';
import { EnumArgument } from './EnumArgument';
import { ListArgument } from './ListArgument';
import { ObjectArgument } from './ObjectArgument';
import { ScalarArgument } from './ScalarArgument';

interface Props {
    type: GraphQLInputType;
    isRequired: boolean;
    defaultValue: unknown;
    value?: ValueNode;
    name?: string;
    description?: string | null;
    onChange: (value: ValueNode) => void;
}

export function Argument(props: Props): React.JSX.Element {
    const { type, isRequired, defaultValue, value, name, description, onChange } = props;

    return (
        <>
            {name && <WideSkyLabel label={name} tooltip={description} iconName="info-circle" />}

            {isScalarType(type) && (
                <ScalarArgument value={value} defaultValue={defaultValue} type={type} onChange={onChange} />
            )}
            {isEnumType(type) && <EnumArgument value={value} isRequired={isRequired} type={type} onChange={onChange} />}
            {isListType(type) && <ListArgument value={value} type={type} onChange={onChange} />}
            {isInputObjectType(type) && (
                <ObjectArgument
                    values={value?.kind === Kind.OBJECT ? value.fields : []}
                    isRequired={false}
                    allArguments={Object.keys(type.getFields()).map((field) => type.getFields()[field])}
                    onChange={(valueNode, argument) => {
                        const isEmpty = isEmptyValue(valueNode);

                        // The user altered an argument that isnt defined in the query yet but let the value empty
                        if (value === undefined && isEmpty) {
                            return;
                        }

                        if (value !== undefined && value.kind !== Kind.OBJECT) {
                            return;
                        }

                        const fieldNode: ObjectFieldNode[] = isEmpty
                            ? []
                            : [
                                  {
                                      name: { kind: Kind.NAME, value: argument.name },
                                      kind: Kind.OBJECT_FIELD,
                                      value: valueNode,
                                  },
                              ];

                        const fields =
                            value === undefined
                                ? fieldNode
                                : [...value.fields.filter((f) => f.name.value !== argument.name), ...fieldNode];

                        onChange({ kind: Kind.OBJECT, fields });
                    }}
                />
            )}
        </>
    );
}
