import {
    DataFrame,
    DataQueryError,
    DataQueryRequest,
    DataQueryResponse,
    DataSourceApi,
    DataSourceInstanceSettings,
    LegacyMetricFindQueryOptions,
    MetricFindValue,
    ScopedVars,
    TimeRange,
} from '@grafana/data';

import { APIServerManager } from './api_server_manager';

import { WideSkyDataSourceOptions, GraphQLResponse, InternalQueryError, WideSkyQueryJSONTarget } from 'types';

import { createResponse, isValidQuery, parseTarget } from 'formats/query_base';
import { replaceVariables } from 'utils/replace_variables';
import { findTemplateVariables } from 'utils/template_variables';
import { pointWriteQuery } from 'utils/graphql_queries';
import { toDataQueryError } from '@grafana/runtime';
import { updateLegacyTarget } from 'components/state/helpers';

export class WideSkyDatasource extends DataSourceApi<WideSkyQueryJSONTarget, WideSkyDataSourceOptions> {
    public apiServerManager: APIServerManager;

    constructor(instanceSettings: DataSourceInstanceSettings<WideSkyDataSourceOptions>) {
        super(instanceSettings);
        this.apiServerManager = new APIServerManager(instanceSettings);
    }

    public async metricFindQuery(query: string, options?: LegacyMetricFindQueryOptions): Promise<MetricFindValue[]> {
        if (/templateVal/.exec(query) === null) {
            console.error(`Query has no anchoring templateVal:\n${query}`);
            return Promise.resolve([]);
        }
        let parsedQuery = replaceVariables(query, options?.range, options?.scopedVars);

        if (parsedQuery.trimStart().at(0) !== '{') {
            parsedQuery = `{${parsedQuery}}`;
        }

        let serverResponse: GraphQLResponse | undefined;

        try {
            serverResponse = await this.apiServerManager.postQuery<GraphQLResponse>(parsedQuery, query);
        } catch (error) {
            const internalError = error as InternalQueryError<GraphQLResponse>;
            console.error(
                `${internalError.message}${internalError.additionalInfo ? `\n${internalError.additionalInfo}` : ''}`
            );

            serverResponse = internalError.data;
        }

        try {
            const templates = findTemplateVariables(serverResponse);

            if (templates.templateTexts.length === 0) {
                return templates.templateVals.map((value) => ({
                    value,
                    text: value,
                }));
            } else {
                return templates.templateVals
                    .filter((_, index) => templates.templateTexts.at(index) !== undefined)
                    .map((value, index) => ({
                        value,
                        text: templates.templateTexts.at(index)!,
                    }));
            }
        } catch (error) {
            console.warn('metricFindQuery failed to find template variables in the response');
            console.error(error);
            return [];
        }
    }

    private processTarget = async (
        target: WideSkyQueryJSONTarget,
        range: TimeRange,
        scopedVars: ScopedVars
    ): Promise<{ data?: DataFrame[]; error?: DataQueryError }> => {
        if ('graphq' in target) {
            target = updateLegacyTarget(target);
        }

        if (isValidQuery(target) === false) {
            return {};
        }

        let queryError: InternalQueryError<GraphQLResponse> | undefined;
        try {
            if (target.setQueryInfo) {
                target.setQueryInfo({
                    severity: 'warning',
                    data: {
                        message: 'Query processing',
                    },
                });
            }

            const parsedQuery = parseTarget(target, range, scopedVars);

            const response = await this.apiServerManager
                .postQuery<GraphQLResponse>(parsedQuery, target.query, false)
                .catch((error: InternalQueryError<GraphQLResponse>) => {
                    queryError = error;
                    if (error.data === undefined) {
                        throw error;
                    }

                    return error.data;
                });

            const data = createResponse(target, response);

            if (target.setQueryInfo) {
                const firstDataFrame = data.at(0);
                let dataLength: string | undefined;

                if (target.formatType === 'TIME_SERIES') {
                    dataLength = `${data.length} series`;
                } else if (firstDataFrame === undefined) {
                    dataLength = '0 fields';
                } else {
                    dataLength =
                        firstDataFrame.fields.length === 1
                            ? `${firstDataFrame.fields.at(0)?.values.length} values`
                            : `${firstDataFrame.fields.length} fields`;
                }

                target.setQueryInfo({
                    severity: queryError === undefined ? 'success' : 'warning',
                    data: {
                        message: `Query successful: ${dataLength} returned. ${queryError?.message || ''}`,
                        error: queryError?.additionalInfo,
                    },
                });
            }

            return { data };
        } catch (error) {
            const internalError = queryError || (error as InternalQueryError);
            console.warn(
                `${internalError.message}${internalError.additionalInfo ? `\n${internalError.additionalInfo}` : ''}`
            );

            if (target.setQueryInfo) {
                target.setQueryInfo({
                    severity: 'error',
                    data: {
                        message: internalError.message,
                        error: internalError.additionalInfo,
                    },
                });
            }

            return { error: toDataQueryError(error) };
        }
    };

    public async query(options: DataQueryRequest<WideSkyQueryJSONTarget>): Promise<DataQueryResponse> {
        const data: DataFrame[] = [];
        const errors: DataQueryError[] = [];

        const msRange = options.range.to.diff(options.range.from);
        const sRange = Math.round(msRange / 1000);

        options.scopedVars['__range_ms'] = { text: msRange, value: msRange };
        options.scopedVars['__range_s'] = { text: sRange, value: sRange };
        options.scopedVars['__range'] = { text: sRange + 's', value: sRange + 's' };

        const targets = options.targets.map((target) => this.processTarget(target, options.range, options.scopedVars));
        const responses = await Promise.all(targets);

        responses.forEach((response) => {
            if (response.data) {
                data.push(...response.data);
            }

            if (response.error) {
                errors.push(response.error);
            }
        });

        return { data, errors, error: errors.at(0) };
    }

    public async testDatasource() {
        return this.apiServerManager.testDatasource();
    }

    public getQueryDisplayText(query: WideSkyQueryJSONTarget) {
        return query.query;
    }

    // Public interface for the real time controll panel to use
    public pointWrite(id: string, value: string | boolean, useAMQP: boolean) {
        const query = pointWriteQuery(id, value, useAMQP);
        return this.apiServerManager.pointWriteQuery(query);
    }
}
