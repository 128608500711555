import { PanelPlugin, SelectableValue } from '@grafana/data';
import { GraphState, NodeGraphOptions, VIEW_TYPE } from 'editor/editor.types';
import { LayerEditor } from 'editor/LayerEditor';
import { LayerSelector } from 'editor/LayerSelector';
import { NodeGraphPanel } from './components/NodeGraphPanel';

export const plugin = new PanelPlugin<NodeGraphOptions>(NodeGraphPanel).setPanelOptions((builder, context) => {
    builder.addSelect({
        name: 'View',
        category: ['View options'],
        description: 'This decides how the panel will be positioned when it first loads',
        path: 'viewOptions.type',
        settings: {
            options: VIEW_TYPE.map<SelectableValue<(typeof VIEW_TYPE)[number]>>((viewType) => ({
                label: viewType,
                value: viewType,
            })),
        },
    });

    builder.addCustomEditor({
        category: ['Render options'],
        id: 'layers',
        path: '',
        name: '',
        editor: LayerSelector,
    });

    const state = context.instanceState as GraphState;
    if (state !== undefined) {
        const selected = state.layers.find((layer) => layer.isSelected && layer.options.isAssignedInOptions);
        if (selected !== undefined) {
            builder.addNestedOptions(
                LayerEditor({
                    element: selected,
                    category: ['Render options'],
                    onChange: state.onChange,
                })
            );
        }
    }
});
