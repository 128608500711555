import { ASTNode, visit } from 'graphql';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ASTReducer } from 'graphql/language/visitor';

export function prettifier(ast: ASTNode): string {
    return visit(ast, prettifyASTReducer);
}

const prettifyASTReducer: ASTReducer<string> = {
    Name: { leave: (node) => node.value },
    Variable: { leave: (node) => '$' + node.name },

    // Document

    Document: {
        leave: (node) => join(node.definitions, '\n\n'),
    },

    OperationDefinition: {
        leave(node) {
            const varDefs = wrap('(', join(node.variableDefinitions, ', '), ')');
            const prefix = join([node.operation, join([node.name, varDefs]), join(node.directives, ' ')], ' ');
            return (prefix === 'query' ? '' : prefix + ' ') + node.selectionSet;
        },
    },

    VariableDefinition: {
        leave: ({ variable, type, defaultValue, directives }) =>
            variable + ': ' + type + wrap(' = ', defaultValue) + wrap(' ', join(directives, ' ')),
    },
    SelectionSet: { leave: ({ selections }) => block(selections) },

    Field: {
        leave({ alias, name, arguments: args, directives, selectionSet }) {
            const prefix = wrap('', alias, ': ') + name;
            const argsLine = prefix + wrap('(', join(args, ', '), ')');
            return join([argsLine, join(directives, ' '), selectionSet], ' ');
        },
    },

    Argument: { leave: ({ name, value }) => name + ': ' + value },

    // Fragments

    FragmentSpread: {
        leave: ({ name, directives }) => '...' + name + wrap(' ', join(directives, ' ')),
    },

    InlineFragment: {
        leave: ({ typeCondition, directives, selectionSet }) =>
            join(['...', wrap('on ', typeCondition), join(directives, ' '), selectionSet], ' '),
    },

    FragmentDefinition: {
        leave: ({ name, typeCondition, directives, selectionSet }) =>
            `fragment ${name} on ${typeCondition} ${wrap('', join(directives, ' '), ' ')}${selectionSet}`,
    },

    // Value

    IntValue: { leave: ({ value }) => value },
    FloatValue: { leave: ({ value }) => value },
    StringValue: {
        leave: ({ value, block: isBlockString }) => (isBlockString ? printBlockString(value) : printString(value)),
    },
    BooleanValue: { leave: ({ value }) => (value ? 'true' : 'false') },
    NullValue: { leave: () => 'null' },
    EnumValue: { leave: ({ value }) => value },
    ListValue: { leave: ({ values }) => '[' + join(values, ', ') + ']' },
    ObjectValue: { leave: ({ fields }) => '{' + join(fields, ', ') + '}' },
    ObjectField: { leave: ({ name, value }) => name + ': ' + value },

    // Directive

    Directive: {
        leave: ({ name, arguments: args }) => '@' + name + wrap('(', join(args, ', '), ')'),
    },

    // Type

    NamedType: { leave: ({ name }) => name },
    ListType: { leave: ({ type }) => '[' + type + ']' },
    NonNullType: { leave: ({ type }) => type + '!' },

    // Type System Definitions

    SchemaDefinition: {
        leave: ({ description, directives, operationTypes }) =>
            wrap('', description, '\n') + join(['schema', join(directives, ' '), block(operationTypes)], ' '),
    },

    OperationTypeDefinition: {
        leave: ({ operation, type }) => operation + ': ' + type,
    },

    ScalarTypeDefinition: {
        leave: ({ description, name, directives }) =>
            wrap('', description, '\n') + join(['scalar', name, join(directives, ' ')], ' '),
    },

    ObjectTypeDefinition: {
        leave: ({ description, name, interfaces, directives, fields }) =>
            wrap('', description, '\n') +
            join(
                ['type', name, wrap('implements ', join(interfaces, ' & ')), join(directives, ' '), block(fields)],
                ' '
            ),
    },

    FieldDefinition: {
        leave: ({ description, name, arguments: args, type, directives }) =>
            wrap('', description, '\n') +
            name +
            (hasMultilineItems(args)
                ? wrap('(\n', indent(join(args, '\n')), '\n)')
                : wrap('(', join(args, ', '), ')')) +
            ': ' +
            type +
            wrap(' ', join(directives, ' ')),
    },

    InputValueDefinition: {
        leave: ({ description, name, type, defaultValue, directives }) =>
            wrap('', description, '\n') +
            join([name + ': ' + type, wrap('= ', defaultValue), join(directives, ' ')], ' '),
    },

    InterfaceTypeDefinition: {
        leave: ({ description, name, interfaces, directives, fields }) =>
            wrap('', description, '\n') +
            join(
                ['interface', name, wrap('implements ', join(interfaces, ' & ')), join(directives, ' '), block(fields)],
                ' '
            ),
    },

    UnionTypeDefinition: {
        leave: ({ description, name, directives, types }) =>
            wrap('', description, '\n') +
            join(['union', name, join(directives, ' '), wrap('= ', join(types, ' | '))], ' '),
    },

    EnumTypeDefinition: {
        leave: ({ description, name, directives, values }) =>
            wrap('', description, '\n') + join(['enum', name, join(directives, ' '), block(values)], ' '),
    },

    EnumValueDefinition: {
        leave: ({ description, name, directives }) =>
            wrap('', description, '\n') + join([name, join(directives, ' ')], ' '),
    },

    InputObjectTypeDefinition: {
        leave: ({ description, name, directives, fields }) =>
            wrap('', description, '\n') + join(['input', name, join(directives, ' '), block(fields)], ' '),
    },

    DirectiveDefinition: {
        leave: ({ description, name, arguments: args, repeatable, locations }) =>
            wrap('', description, '\n') +
            'directive @' +
            name +
            (hasMultilineItems(args)
                ? wrap('(\n', indent(join(args, '\n')), '\n)')
                : wrap('(', join(args, ', '), ')')) +
            (repeatable ? ' repeatable' : '') +
            ' on ' +
            join(locations, ' | '),
    },

    SchemaExtension: {
        leave: ({ directives, operationTypes }) =>
            join(['extend schema', join(directives, ' '), block(operationTypes)], ' '),
    },

    ScalarTypeExtension: {
        leave: ({ name, directives }) => join(['extend scalar', name, join(directives, ' ')], ' '),
    },

    ObjectTypeExtension: {
        leave: ({ name, interfaces, directives, fields }) =>
            join(
                [
                    'extend type',
                    name,
                    wrap('implements ', join(interfaces, ' & ')),
                    join(directives, ' '),
                    block(fields),
                ],
                ' '
            ),
    },

    InterfaceTypeExtension: {
        leave: ({ name, interfaces, directives, fields }) =>
            join(
                [
                    'extend interface',
                    name,
                    wrap('implements ', join(interfaces, ' & ')),
                    join(directives, ' '),
                    block(fields),
                ],
                ' '
            ),
    },

    UnionTypeExtension: {
        leave: ({ name, directives, types }) =>
            join(['extend union', name, join(directives, ' '), wrap('= ', join(types, ' | '))], ' '),
    },

    EnumTypeExtension: {
        leave: ({ name, directives, values }) => join(['extend enum', name, join(directives, ' '), block(values)], ' '),
    },

    InputObjectTypeExtension: {
        leave: ({ name, directives, fields }) =>
            join(['extend input', name, join(directives, ' '), block(fields)], ' '),
    },
};

/**
 * Given maybeArray, print an empty string if it is null or empty, otherwise
 * print all items together separated by separator if provided
 */
function join(maybeArray: Maybe<ReadonlyArray<string | undefined>>, separator = ''): string {
    return maybeArray?.filter((x) => x).join(separator) ?? '';
}

/**
 * Given array, print each item on its own line, wrapped in an indented `{ }` block.
 */
function block(array: Maybe<ReadonlyArray<string | undefined>>): string {
    return wrap('{\n', indent(join(array, '\n')), '\n}');
}

/**
 * If maybeString is not null or empty, then wrap with start and end, otherwise print an empty string.
 */
function wrap(start: string, maybeString: Maybe<string>, end = ''): string {
    return maybeString != null && maybeString !== '' ? start + maybeString + end : '';
}

function indent(str: string): string {
    return wrap('  ', str.replace(/\n/g, '\n  '));
}

function hasMultilineItems(maybeArray: Maybe<readonly string[]>): boolean {
    return maybeArray?.some((str) => str.includes('\n')) ?? false;
}

function printBlockString(value: string, options?: { minimize?: boolean }): string {
    const escapedValue = value.replace(/"""/g, '\\"""');

    // Expand a block string's raw value into independent lines.
    const lines = escapedValue.split(/\r\n|[\n\r]/g);
    const isSingleLine = lines.length === 1;

    // If common indentation is found we can fix some of those cases by adding leading new line
    const forceLeadingNewLine =
        lines.length > 1 && lines.slice(1).every((line) => line.length === 0 || isWhiteSpace(line.charCodeAt(0)));

    // Trailing triple quotes just looks confusing but doesn't force trailing new line
    const hasTrailingTripleQuotes = escapedValue.endsWith('\\"""');

    // Trailing quote (single or double) or slash forces trailing new line
    const hasTrailingQuote = value.endsWith('"') && !hasTrailingTripleQuotes;
    const hasTrailingSlash = value.endsWith('\\');
    const forceTrailingNewline = hasTrailingQuote || hasTrailingSlash;

    const printAsMultipleLines =
        !options?.minimize &&
        // add leading and trailing new lines only if it improves readability
        (!isSingleLine || value.length > 70 || forceTrailingNewline || forceLeadingNewLine || hasTrailingTripleQuotes);

    let result = '';

    // Format a multi-line block quote to account for leading space.
    const skipLeadingNewLine = isSingleLine && isWhiteSpace(value.charCodeAt(0));
    if ((printAsMultipleLines && !skipLeadingNewLine) || forceLeadingNewLine) {
        result += '\n';
    }

    result += escapedValue;
    if (printAsMultipleLines || forceTrailingNewline) {
        result += '\n';
    }

    return '"""' + result + '"""';
}

/**
 * ```
 * WhiteSpace ::
 *   - "Horizontal Tab (U+0009)"
 *   - "Space (U+0020)"
 * ```
 * @internal
 */
function isWhiteSpace(code: number): boolean {
    return code === 0x0009 || code === 0x0020;
}

/**
 * Prints a string as a GraphQL StringValue literal. Replaces control characters
 * and excluded characters (" U+0022 and \\ U+005C) with escape sequences.
 */
function printString(str: string): string {
    return `"${str.replace(escapedRegExp, escapedReplacer)}"`;
}

const escapedRegExp = /[\x00-\x1f\x22\x5c\x7f-\x9f]/g;

function escapedReplacer(str: string): string {
    return escapeSequences[str.charCodeAt(0)];
}

// prettier-ignore
const escapeSequences = [
    '\\u0000', '\\u0001', '\\u0002', '\\u0003', '\\u0004', '\\u0005', '\\u0006', '\\u0007',
    '\\b',     '\\t',     '\\n',     '\\u000B', '\\f',     '\\r',     '\\u000E', '\\u000F',
    '\\u0010', '\\u0011', '\\u0012', '\\u0013', '\\u0014', '\\u0015', '\\u0016', '\\u0017',
    '\\u0018', '\\u0019', '\\u001A', '\\u001B', '\\u001C', '\\u001D', '\\u001E', '\\u001F',
    '',        '',        '\\"',     '',        '',        '',        '',        '',
    '',        '',        '',        '',        '',        '',        '',        '', // 2F
    '',        '',        '',        '',        '',        '',        '',        '',
    '',        '',        '',        '',        '',        '',        '',        '', // 3F
    '',        '',        '',        '',        '',        '',        '',        '',
    '',        '',        '',        '',        '',        '',        '',        '', // 4F
    '',        '',        '',        '',        '',        '',        '',        '',
    '',        '',        '',        '',        '\\\\',    '',        '',        '', // 5F
    '',        '',        '',        '',        '',        '',        '',        '',
    '',        '',        '',        '',        '',        '',        '',        '', // 6F
    '',        '',        '',        '',        '',        '',        '',        '',
    '',        '',        '',        '',        '',        '',        '',        '\\u007F',
    '\\u0080', '\\u0081', '\\u0082', '\\u0083', '\\u0084', '\\u0085', '\\u0086', '\\u0087',
    '\\u0088', '\\u0089', '\\u008A', '\\u008B', '\\u008C', '\\u008D', '\\u008E', '\\u008F',
    '\\u0090', '\\u0091', '\\u0092', '\\u0093', '\\u0094', '\\u0095', '\\u0096', '\\u0097',
    '\\u0098', '\\u0099', '\\u009A', '\\u009B', '\\u009C', '\\u009D', '\\u009E', '\\u009F',
  ];
