import { GraphQLResponse, QueryInfo, WideSkyQueryJSONTarget, QueryType } from 'types';
import { createTimeSeriesData } from './parse';

export const TimeSeriesQuery: QueryType = {
    isValidQuery(target: WideSkyQueryJSONTarget): boolean {
        if (target.query === undefined || target.query.indexOf('history') === -1) {
            if (target.setQueryInfo) {
                const info: QueryInfo = {
                    severity: 'error',
                    data: {
                        message: `Query is missing the required "history" node`,
                    },
                };

                target.setQueryInfo(info);
            }

            return false;
        }

        return true;
    },

    createResponse(target: WideSkyQueryJSONTarget, graphQLRoot: Readonly<GraphQLResponse>) {
        return createTimeSeriesData(graphQLRoot, target.config, target.refId);
    },
};
