import React from 'react';
import { WideSkyInput, WideSkySelect } from 'components/WideSkyVisualComponents';
import {
    BooleanValueNode,
    FloatValueNode,
    GraphQLScalarType,
    IntValueNode,
    Kind,
    StringValueNode,
    ValueNode,
    VariableNode,
} from 'graphql';
import { UNSELECTED_VALUE } from '../types';
import { HaystackFilter } from './HaystackFilter';

interface GenericScalarProps {
    value?: string | boolean;
    defaultValue: unknown;
    onChange: (value: VariableNode | StringValueNode | IntValueNode | FloatValueNode) => void;
}

function GenericScalar(props: GenericScalarProps) {
    const { value, defaultValue, onChange } = props;

    return (
        <WideSkyInput
            type={'text'}
            value={value?.toString()}
            defaultValue={defaultValue}
            onBlur={(event) => {
                const newValue = event.target.value;
                if (newValue === undefined) {
                    return;
                }

                if (newValue.at(0) === '$') {
                    onChange({ kind: Kind.VARIABLE, name: { kind: Kind.NAME, value: newValue } });
                    return;
                }

                if (isNaN(parseInt(newValue, 10))) {
                    onChange({ kind: Kind.STRING, value: newValue });
                    return;
                }

                onChange({ kind: Kind.INT, value: newValue });
            }}
        />
    );
}

type ScalarValueNode = IntValueNode | FloatValueNode | BooleanValueNode | StringValueNode | VariableNode;
const isScalarValueNode = (value: ValueNode): value is ScalarValueNode =>
    value.kind === Kind.INT ||
    value.kind === Kind.FLOAT ||
    value.kind === Kind.BOOLEAN ||
    value.kind === Kind.STRING ||
    value.kind === Kind.VARIABLE;

interface Props {
    type: GraphQLScalarType;
    value?: ValueNode;
    defaultValue: unknown;
    onChange: (value: ValueNode) => void;
}

export function ScalarArgument(props: Props) {
    const { value, type, defaultValue, onChange } = props;

    if (value !== undefined && !isScalarValueNode(value)) {
        console.error(`Argument type ${type.name} does not match value type (${value?.kind})`);
        return null;
    }

    if (value?.kind === Kind.VARIABLE) {
        return <GenericScalar value={`$${value.name.value}`} defaultValue={defaultValue} onChange={onChange} />;
    }

    let returnKind: Kind.STRING | Kind.INT | Kind.FLOAT;

    switch (type.name) {
        case 'HaystackFilter':
            return (
                <HaystackFilter
                    value={value?.value !== undefined ? String(value.value) : undefined}
                    onChange={onChange}
                />
            );
        case 'Int':
            returnKind = Kind.INT;
            break;
        case 'Float':
            returnKind = Kind.FLOAT;
            break;
        case 'String':
        // Fall through
        case 'iso8601':
            returnKind = Kind.STRING;
            break;
        case 'Boolean':
            return (
                <WideSkySelect
                    value={value?.value === undefined ? UNSELECTED_VALUE : String(value.value)}
                    options={[UNSELECTED_VALUE, 'true', 'false'].map((value) => ({
                        label: String(value),
                        value: value,
                    }))}
                    onChange={(option) => {
                        if (option.value === undefined) {
                            return;
                        }

                        if (option.value === UNSELECTED_VALUE) {
                            onChange({ kind: Kind.STRING, value: '' });
                        } else {
                            onChange({ kind: Kind.BOOLEAN, value: option.value === 'true' });
                        }
                    }}
                />
            );
        default:
            console.error(`Unhandled type ${type.name}`);
            return null;
    }

    return (
        <GenericScalar
            value={value?.value}
            defaultValue={defaultValue}
            onChange={(newValue) => {
                const newValueString = newValue.kind === Kind.VARIABLE ? newValue.name.value : newValue.value;
                onChange({ value: newValueString, kind: returnKind });
            }}
        />
    );
}
