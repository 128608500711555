import React from 'react';
import { SortSelect, WideSkyField, WideSkyInput } from 'components/WideSkyVisualComponents';
import { useDispatch, useWideSkyState } from 'components/state/context';
import { VariableSort } from '@grafana/data';
import { Config } from './config';
import { actions } from 'components/state/actions';

export function TimeSeriesEditor() {
    const state = useWideSkyState();
    const dispatch = useDispatch();

    const config = state.target.config as Config | undefined;

    return (
        <>
            <WideSkyField label="Alias">
                <WideSkyInput
                    type={'text'}
                    placeholder={'{../description}_elecEnergy'}
                    value={config?.alias}
                    onBlur={(e) => {
                        if (config !== undefined) {
                            config.alias = e.target.value;
                        } else {
                            state.target.config = {
                                alias: e.target.value,
                            };
                        }

                        dispatch(actions.editorChanged());
                    }}
                />
            </WideSkyField>
            <WideSkyField label="Sort Method">
                <SortSelect
                    sort={config?.sort || VariableSort.disabled}
                    onChange={(e) => {
                        if (config !== undefined) {
                            config.sort = e.value;
                        } else {
                            state.target.config = {
                                sort: e.value,
                            };
                        }

                        dispatch(actions.editorChanged());
                    }}
                />
            </WideSkyField>
            {config?.sort !== VariableSort.disabled && config?.sort !== undefined && (
                <WideSkyField label="Sort Alias">
                    <WideSkyInput
                        type={'text'}
                        placeholder={config?.sortAlias || 'name'}
                        value={config?.sortAlias}
                        onBlur={(e) => {
                            if (config !== undefined) {
                                config.sortAlias = e.target.value;
                            } else {
                                state.target.config = {
                                    sortAlias: e.target.value,
                                };
                            }

                            dispatch(actions.editorChanged());
                        }}
                    />
                </WideSkyField>
            )}
        </>
    );
}
