import { Field } from '@grafana/data';

export type NodeFields = {
    id: Field<string>;
    primaryText?: Field<string | undefined>;
    secondaryText?: Field<string | undefined>;
    alias?: Field<string | undefined>;
    detail: Field[];
};

export type EdgeFields = {
    from: Field;
    to: Field;
};

export enum NodeFieldNames {
    id = 'id',
    primaryText = 'primary_text',
    secondaryText = 'secondary_text',
    alias = 'detail__alias',
    detail = 'detail__',
}

export enum EdgeFieldNames {
    from = 'from',
    to = 'to',
}
