import React from 'react';
import { InlineFieldRow, InlineSwitch } from '@grafana/ui';
import { WideSkyField, WideSkyIcon, WideSkySelect } from 'components/WideSkyVisualComponents';
import { actions } from 'components/state/actions';
import { useDispatch, useWideSkyState } from 'components/state/context';
import { Column, Config, FLATTEN_NONE_VALUE } from './config';
import { SelectableValue } from '@grafana/data';

const FLATTEN_NONE_OPTION = {
    value: FLATTEN_NONE_VALUE,
    label: FLATTEN_NONE_VALUE,
};

export function TableEditor() {
    const state = useWideSkyState();
    const dispatch = useDispatch();

    const config = state.target.config as Config | undefined;

    const onAddColumn = (index: number) => () => {
        if (config?.columns === undefined) {
            state.target.config = {
                columns: [{}],
            };
        } else {
            config.columns = [...config.columns.slice(0, index + 1), {}, ...config.columns.slice(index + 1)];
        }

        dispatch(actions.editorChanged());
    };

    const onRemoveColumn = (index: number) => () => {
        if (config?.columns === undefined) {
            return;
        }

        config.columns.splice(index, 1);
        dispatch(actions.editorChanged());
    };

    const onChange = (key: keyof Column, value?: string, column?: Column) => {
        if (column !== undefined) {
            column[key] = value;
        } else {
            state.target.config = {
                columns: [
                    {
                        [key]: value,
                    },
                ],
            };
        }

        dispatch(actions.editorChanged());
    };

    const getBaseColumnOptions = (index: number) => {
        const columns = config?.columns;

        if (state.columnOptions === undefined) {
            return [];
        }

        const optionsAsSelectable = state.columnOptions.map((option) => ({ value: option, label: option }));

        if (columns === undefined) {
            return optionsAsSelectable;
        }

        return optionsAsSelectable.filter(
            (option) =>
                columns.some(
                    (columnOption, columnOptionIndex) =>
                        columnOption.base === option.value && columnOptionIndex !== index
                ) === false
        );
    };

    const getFlattenColumnOptions = (index: number) => {
        const columns = config?.columns;

        if (columns === undefined) {
            return [FLATTEN_NONE_OPTION];
        }

        return [
            FLATTEN_NONE_OPTION,
            ...columns
                .filter(
                    (option, optionIndex) => option.base !== undefined && optionIndex !== index && optionIndex < index
                )
                .map((option) => ({ label: option.base, value: option.base })),
        ];
    };

    return (
        <>
            {config?.columns === undefined && (
                <ColumnEditor
                    column={undefined}
                    index={0}
                    onAdd={onAddColumn(0)}
                    onRemove={onRemoveColumn(0)}
                    onChange={onChange}
                    baseOptions={getBaseColumnOptions(0)}
                    flattenOptions={getFlattenColumnOptions(0)}
                />
            )}

            {config?.columns !== undefined &&
                config.columns.map((column, index) => (
                    <ColumnEditor
                        key={`${index}-${column.base || index}-${column.flatten || index}`}
                        column={column}
                        index={index}
                        onAdd={onAddColumn(index)}
                        onRemove={onRemoveColumn(index)}
                        onChange={onChange}
                        baseOptions={getBaseColumnOptions(index)}
                        flattenOptions={getFlattenColumnOptions(index)}
                    />
                ))}

            <WideSkyField label="Fill Flatten">
                <InlineSwitch
                    value={config?.fillFlatten}
                    onChange={() => {
                        if (config !== undefined) {
                            config.fillFlatten = !config.fillFlatten;
                        } else {
                            state.target.config = {
                                fillFlatten: true,
                            };
                        }
                        dispatch(actions.editorChanged());
                    }}
                />
            </WideSkyField>
        </>
    );
}

interface Props {
    column?: Column;
    index: number;
    baseOptions: Array<SelectableValue<string>>;
    flattenOptions: Array<SelectableValue<string>>;
    onAdd: () => void;
    onRemove: () => void;
    onChange: (key: keyof Column, value?: string, column?: Column) => void;
}

function ColumnEditor(props: Props) {
    const { column, index, baseOptions, flattenOptions, onChange, onAdd, onRemove } = props;

    return (
        <InlineFieldRow>
            <WideSkyField label={`Column ${index + 1}`}>
                <WideSkySelect
                    value={column?.base ?? undefined}
                    options={baseOptions}
                    onChange={(e) => onChange('base', e.value, column)}
                />
            </WideSkyField>

            {index !== 0 && (
                <WideSkyField label="Flatten With">
                    <WideSkySelect
                        value={column?.flatten ?? undefined}
                        options={flattenOptions}
                        onChange={(e) => onChange('flatten', e.value, column)}
                    />
                </WideSkyField>
            )}

            <WideSkyIcon name="plus" onClick={onAdd} />
            {index !== 0 && <WideSkyIcon name="minus" onClick={onRemove} />}
        </InlineFieldRow>
    );
}
