import _ from 'lodash';
import { DataFrame, ScopedVars, TimeRange } from '@grafana/data';
import { FORMAT_TYPES, GraphQLResponse, QueryType, WideSkyQueryJSONTarget } from 'types';
import { replaceVariables } from 'utils/replace_variables';
import { TimeSeriesQuery } from './time_series/query';
import { TableQuery } from './table/query';
import { TableDeprecatingQuery } from './table_deprecating/query';
import { NodeGraphQuery } from './node_graph/query';
import { RealTimeQuery } from './real_time/query';

const queryMap: { [key in (typeof FORMAT_TYPES)[number]]: QueryType } = {
    DEPRECATING_TABLE: TableDeprecatingQuery,
    TABLE: TableQuery,
    TIME_SERIES: TimeSeriesQuery,
    NODE_GRAPH: NodeGraphQuery,
    REAL_TIME: RealTimeQuery,
};

export function isValidQuery(target: WideSkyQueryJSONTarget) {
    const formattedQuery = queryMap[target.formatType];

    if (target.hide === true || target.query === '' || formattedQuery === undefined) {
        return false;
    }

    return formattedQuery.isValidQuery ? formattedQuery.isValidQuery(target) : true;
}

export function parseTarget(target: WideSkyQueryJSONTarget, range: TimeRange, scopedVars: ScopedVars) {
    const formattedQuery = queryMap[target.formatType];
    let query = formattedQuery.parseTarget ? formattedQuery.parseTarget(target) : target.query;

    query = replaceVariables(query, range, scopedVars);

    if (query.trimStart().at(0) !== '{') {
        query = `{${query}}`;
    }

    return query
        .replace(/[\t ]+\n/g, '\n')
        .replace(/\n[\t ]+/g, ' ')
        .replace(/\n/g, '');
}

export function createResponse(target: WideSkyQueryJSONTarget, graphQLRoot: Readonly<GraphQLResponse>): DataFrame[] {
    const responses = queryMap[target.formatType].createResponse(target, graphQLRoot);

    responses.forEach((response) => {
        response.meta = {
            ...response.meta,
            executedQueryString: target.query,
        };

        response.refId = response.refId || target.refId;
    });

    return responses;
}
