import { ScopedVars, TimeRange } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';

// Regex flags used are - global - ignoreCase - multiline
const INTERVAL_DURATION_VARIABLE_PATTERN = /\$__intervalDuration/gim;
const INTERVAL_NUMBER_VARIABLE_PATTERN = /\$__intervalNum/gim;

const FROM_TIME_VARIABLE_PATTERN = /\$from/gim;
const TO_TIME_VARIABLE_PATTERN = /\$to/gim;

/**
 * Processes a query string to replace various types of variables including time-based variables, template variables,
 * and interval variables. This function acts as a central processor that sequentially calls specific functions to handle
 * each type of replacement in the query string.
 * @param query The initial query string that may contain placeholders for various variable types.
 * @param range A `TimeRange` object providing the start and end times used to replace time-based placeholders in the query.
 * @param scopedVars An object containing variables scoped to the current context, used to replace template and interval variables.
 * @returns The fully processed query string with all placeholders replaced with their respective actual values.
 */
export const replaceVariables = (
    query: string,
    range?: TimeRange,
    scopedVars?: ScopedVars
): string => {
    query = replaceTimeVariables(query, range);
    query = replaceTemplatevar(query, scopedVars);
    query = replaceIntervals(query, scopedVars);
    return query;
};

/**
 * Replaces instances of `replaceKey` with `replaceValue` within specified parameter patterns in the provided string.
 * @param fullString The string containing the parameters to replace.
 * @param replaceKey A regular expression pattern identifying the part of the parameter to be replaced.
 * @param replaceValue The replacement string.
 * @returns The modified string with the replacements.
 */
const searchAndReplace = (fullString: string, replaceKey: RegExp, replaceValue: string) => {
    if (!replaceKey.test(fullString)) {
        return fullString;
    }

    return fullString.replace(replaceKey, (match) => {
        return match.replace(replaceKey, replaceValue);
    });
};

/**
 * Replaces time-related variables in the query string with their actual values from the provided `TimeRange`.
 * This function specifically targets `history()` fields and replaces `$from` and `$to` placeholders with ISO string
 * representations of start and end times.
 * @param query The query string containing time variables to be replaced.
 * @param range A `TimeRange` object containing the `from` and `to` date values.
 * @returns The query string with the time variables replaced.
 */
const replaceTimeVariables = (query: string, range?: TimeRange): string => {
    if (range === undefined) {
        return query;
    }

    query = searchAndReplace(query, FROM_TIME_VARIABLE_PATTERN, range.from.toISOString());
    query = searchAndReplace(query, TO_TIME_VARIABLE_PATTERN, range.to.toISOString());
    return query;
};

/**
 * Replaces Grafana template variables in the query string with actual values provided through `scopedVars`.
 * It handles single values and arrays, formatting arrays into a string joined with '|'.
 * @param query The query string containing template variables.
 * @param scopedVars An object containing scoped variables and their values.
 * @returns The query string with template variables replaced.
 */
const replaceTemplatevar = (query: string, scopedvars?: ScopedVars) => {
    // Template variable delimiteter => '|'
    const formatFunc = (value: any) => (Array.isArray(value) ? `(${value.join('|')})` : value);
    return getTemplateSrv().replace(query, scopedvars, formatFunc);
};

/**
 * Replaces interval placeholders within `groupBy` blocks in the query with values from `scopedVars`.
 * It modifies both the interval duration placeholder and the interval number placeholder.
 * @param query The query string containing interval placeholders.
 * @param scopedVars An object containing scoped variables, specifically `__interval_ms`.
 * @returns The query string with interval placeholders replaced.
 */
const replaceIntervals = (query: string, scopedVars?: ScopedVars): string => {
    query = searchAndReplace(query, INTERVAL_DURATION_VARIABLE_PATTERN, 'MILLISECOND');
    query = searchAndReplace(
        query,
        INTERVAL_NUMBER_VARIABLE_PATTERN,
        scopedVars?.__interval_ms?.value || 30000
    );
    return query;
};
