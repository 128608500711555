import { css } from '@emotion/css';
import React from 'react';
import { fontSize, GROUP_TYPE, NodeDatum, nodeHeight } from 'types';
import { Node } from './Node';

const getStyles = (
    type: (typeof GROUP_TYPE)[number],
    primaryColor: string,
    secondaryColor: string,
    width: number,
    height: number
) => {
    if (type === 'box') {
        return {
            group: css({
                border: `solid ${primaryColor}`,
                borderWidth: nodeHeight / 25,
                borderRadius: nodeHeight * (7 / 30),
                width,
                height,
                backgroundColor: secondaryColor,
            }),

            label: css({
                position: 'absolute',
                top: 0,
                right: 0,
                color: primaryColor,
                padding: '5px 10px',
                fontSize: fontSize,
                userSelect: 'none',
            }),
        };
    }

    return {
        group: css({
            border: `solid ${primaryColor}`,
            borderWidth: 0,
            borderTopWidth: nodeHeight / 25,
            borderRightWidth: nodeHeight / 25,
            borderTopRightRadius: nodeHeight * (3 / 10),
            width,
            height,
            backgroundColor: secondaryColor,
        }),

        label: css({
            position: 'absolute',
            top: 0,
            right: 0,
            color: primaryColor,
            padding: '10px 20px',
            fontSize: fontSize,
            userSelect: 'none',
        }),
    };
};

interface Props {
    node: Readonly<NodeDatum>;
    nodeHoveringId?: string;
    onClick: (id: string, context: 'up' | 'down') => void;
    onMouseEnter: (id: string) => void;
    onMouseLeave: (id: string) => void;
}

export function NodeGroup(props: Props) {
    const { node, nodeHoveringId, onClick, onMouseEnter, onMouseLeave } = props;

    if (node.groupType === 'node') {
        return (
            <>
                {node.children
                    .filter((child) => child.groupId === node.id || child.groupId === node.groupId)
                    .map((child, index) => {
                        return (
                            <NodeGroup
                                key={index}
                                node={child}
                                nodeHoveringId={nodeHoveringId}
                                onClick={onClick}
                                onMouseEnter={onMouseEnter}
                                onMouseLeave={onMouseLeave}
                            />
                        );
                    })}
                <Node node={node} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
            </>
        );
    }

    const styles = getStyles(
        node.groupType,
        node.primaryColor,
        node.secondaryColor,
        node.bounds.right - node.bounds.left,
        node.bounds.bottom - node.bounds.top
    );

    return (
        <>
            <foreignObject
                data-testid={'node-group-container'}
                x={node.bounds.left}
                y={node.bounds.top}
                width={node.bounds.right - node.bounds.left}
                height={node.bounds.bottom - node.bounds.top}
            >
                <div className={styles.group}>
                    <div className={styles.label}>{node.primaryText}</div>
                </div>
            </foreignObject>
            {node.children.map((child, index) => {
                return (
                    <NodeGroup
                        key={index}
                        node={child}
                        nodeHoveringId={nodeHoveringId}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    />
                );
            })}
        </>
    );
}
