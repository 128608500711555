import { css, cx } from '@emotion/css';
import { GrafanaTheme2, IconName, SelectableValue, VariableSort } from '@grafana/data';
import { Icon, Select, SelectValue, Tooltip, useTheme2 } from '@grafana/ui';
import React, { HTMLInputTypeAttribute, MouseEventHandler, useEffect, useMemo, useState } from 'react';

const MENU_WIDTH = 150;

export const getStyles = (theme: GrafanaTheme2) => ({
    text: (color?: string) =>
        css({
            color: color || theme.colors.text.primary,
            padding: `0 ${theme.spacing(1)}`,
            fontWeight: theme.typography.fontWeightMedium,
            //! TODO: Change to 'theme.typography.fontSize' when we update to G10
            // eslint-disable-next-line deprecation/deprecation
            fontSize: theme.typography.size.sm,
            userSelect: 'none',
            whiteSpace: 'nowrap',
            maxWidth: 'none',
        }),

    container: css({
        alignItems: 'center',
        border: 'none',
        display: 'flex',
        height: theme.spacing(theme.components.height.md),
        backgroundColor: theme.colors.background.secondary,
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        //! TODO: Change to 'theme.shape.radius.default' when we update to G10
        // eslint-disable-next-line deprecation/deprecation
        borderRadius: theme.shape.borderRadius(2),
    }),

    select: (invalid?: boolean, color?: string) =>
        css({
            //! TODO: Change to 'theme.typography.fontSize' when we update to G10
            // eslint-disable-next-line deprecation/deprecation
            fontSize: theme.typography.size.sm,
            color,
            background: invalid ? theme.colors.error.transparent : undefined,
        }),

    element: css({
        display: 'flex',
    }),

    label: css({
        width: 'auto',
        cursor: 'pointer',
        userSelect: 'none',
    }),

    field: (width?: number, grow?: boolean) =>
        css({
            width: grow ? undefined : width || 150,
            flexGrow: grow ? 1 : undefined,
            cursor: 'pointer',
            userSelect: 'none',
            minWidth: 150,
        }),

    icon: (color?: string) =>
        css({
            color,
            width: 25,
            padding: theme.spacing(0.5),
            cursor: 'pointer',
        }),

    input: (width: string) =>
        css({
            width,
            backgroundColor: theme.colors.background.primary,
            borderColor: theme.colors.border.medium,
            borderWidth: 2,
            borderStyle: 'solid',
        }),

    menuContainer: (position: 'bottom' | 'right') =>
        css({
            position: 'absolute',
            background: theme.colors.background.primary,
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: theme.colors.background.secondary,
            width: MENU_WIDTH,
            top: position === 'bottom' ? theme.spacing(theme.components.height.md / 2) : 0,
            left: position === 'right' ? MENU_WIDTH - 2 : 0,
        }),

    menuItemChild: css({
        position: 'absolute',
        top: 0,
        left: 0,
    }),

    menuLabel: css({
        position: 'relative',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: 'flex',
        ':hover': {
            background: theme.colors.action.hover,
        },
    }),

    tooltip: css({
        marginLeft: 'auto',
        paddingLeft: theme.spacing(1),
    }),

    selectContainer: css({
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(theme.components.height.md),
    }),
});

interface FieldProps {
    label: string;
    width?: number;
    grow?: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
}

export function WideSkyField(props: React.PropsWithChildren<FieldProps>) {
    const theme = useTheme2();
    const styles = getStyles(theme);

    return (
        <div className={styles.element}>
            <div
                onClick={props.onClick}
                onDoubleClick={props.onDoubleClick}
                className={cx(
                    styles.text(theme.colors.primary.text),
                    styles.container,
                    styles.field(props.width, props.grow)
                )}
            >
                {props.label}
            </div>
            {props.children}
        </div>
    );
}

interface IconProps {
    name: IconName;
    color?: string;
    onClick: () => void;
}

export function WideSkyIcon(props: IconProps) {
    const { color, ...restProps } = props;
    const theme = useTheme2();
    const styles = getStyles(theme);

    return <Icon {...restProps} className={cx(styles.container, styles.icon(color))} />;
}

interface InputProps {
    placeholder?: string;
    value?: string | readonly string[] | number;
    defaultValue?: unknown;
    type?: HTMLInputTypeAttribute;
    width?: string;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export function WideSkyInput(props: InputProps) {
    const { placeholder, value, defaultValue, ...restProps } = props;
    const [inputValue, setInputValue] = useState<string | number | readonly string[] | undefined>(value);

    const theme = useTheme2();
    const styles = getStyles(theme);

    useEffect(() => {
        if (value !== undefined) {
            setInputValue(value);
        }
    }, [value]);

    const handleChange = (event: React.FocusEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    return (
        <input
            {...restProps}
            value={inputValue === defaultValue?.toString() ? undefined : inputValue}
            onChange={handleChange}
            width={'auto'}
            placeholder={placeholder || (defaultValue === undefined ? undefined : defaultValue?.toString())}
            className={cx(styles.container, styles.text(), styles.input(props.width || 'auto'))}
        />
    );
}

interface LabelProps {
    label: string;
    suffix?: string | React.JSX.Element | null;
    tooltip?: string | null;
    iconName: IconName;
    onClick?: () => void;
}

export function WideSkyLabel(props: LabelProps) {
    const theme = useTheme2();
    const styles = getStyles(theme);

    return (
        <div className={cx(styles.text(), styles.container, styles.label)} onClick={props.onClick}>
            {props.label}
            {props.tooltip && (
                <div className={styles.tooltip}>
                    <Tooltip placement="auto-end" content={props.tooltip}>
                        <Icon size={'xs'} name={props.iconName} />
                    </Tooltip>
                </div>
            )}
        </div>
    );
}

interface SelectProps<T> {
    value?: T;
    defaultValue?: SelectValue<T>;
    color?: string;
    options?: Array<SelectableValue<T>>;
    isLoading?: boolean;
    allowCustomValue?: boolean;
    backspaceRemovesValue?: boolean;
    components?: any;
    onChange: (value: SelectableValue<T>) => {} | void;
}

export function WideSkySelect<T>(props: SelectProps<T>) {
    const { options: propOptions, color, ...restProps } = props;
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [options, setOptions] = useState<Array<SelectableValue<T>>>([]);

    const theme = useTheme2();
    const styles = getStyles(theme);

    useEffect(() => {
        if (
            props.value !== undefined &&
            props.value !== null &&
            (propOptions === undefined || propOptions.some((option) => option.value === props.value) === false)
        ) {
            setIsInvalid(true);
            return;
        }

        setIsInvalid(false);
    }, [props.value, propOptions]);

    useEffect(() => {
        if (props.value === null || props.value === undefined || !isInvalid) {
            setOptions(propOptions || []);
            return;
        }

        if (isInvalid) {
            const invalidOption: SelectableValue<T> = {
                label: String(props.value),
                value: props.value,
                isDisabled: true,
            };

            if (propOptions) {
                setOptions([invalidOption, ...propOptions]);
            } else {
                setOptions([invalidOption]);
            }
        }
    }, [isInvalid, propOptions, props.value]);

    return (
        <Select<T>
            {...restProps}
            options={options}
            formatCreateLabel={defaultFormatCreateLabel}
            width="auto"
            className={cx(styles.container, styles.select(isInvalid, color))}
        />
    );
}

// Pulled from the grafana element
function defaultFormatCreateLabel(input: string) {
    return (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <div>{input}</div>
            <div style={{ flexGrow: 1 }} />
            <div className="muted small" style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                Select to add
            </div>
        </div>
    );
}

interface MenuItemProps {
    label: string;
    tooltip?: string;
    onMouseEnter?: MouseEventHandler;
    onClick?: MouseEventHandler;
}

export function WideSkyMenuItem(props: React.PropsWithChildren<MenuItemProps>) {
    const theme = useTheme2();
    const styles = getStyles(theme);

    return (
        <div className={cx(styles.text(), styles.menuLabel)} onMouseEnter={props.onMouseEnter} onClick={props.onClick}>
            {props.label}
            {props.tooltip && (
                <div className={styles.tooltip}>
                    <Tooltip placement="auto-end" content={props.tooltip}>
                        <Icon size={'xs'} name="info-circle" />
                    </Tooltip>
                </div>
            )}
            <div className={styles.menuItemChild}>{props.children}</div>
        </div>
    );
}

interface MenuProps {
    position: 'bottom' | 'right';
}

export function WideSkyMenu(props: React.PropsWithChildren<MenuProps>) {
    const theme = useTheme2();
    const styles = getStyles(theme);

    return <div className={styles.menuContainer(props.position)}>{props.children}</div>;
}

interface Props {
    onChange: (option: SelectableValue<VariableSort>) => void;
    sort: VariableSort;
}

const SORT_OPTIONS = [
    { label: 'Disabled', value: VariableSort.disabled },
    { label: 'Alphabetical (asc)', value: VariableSort.alphabeticalAsc },
    { label: 'Alphabetical (desc)', value: VariableSort.alphabeticalDesc },
    { label: 'Numerical (asc)', value: VariableSort.numericalAsc },
    { label: 'Numerical (desc)', value: VariableSort.numericalDesc },
    { label: 'Alphabetical (case-insensitive, asc)', value: VariableSort.alphabeticalCaseInsensitiveAsc },
    { label: 'Alphabetical (case-insensitive, desc)', value: VariableSort.alphabeticalCaseInsensitiveDesc },
    // Using VariableSort.naturalAsc and VariableSort.naturalDesc here returns undefined, might be a Grafana 9.3 thing
    // TODO: Try update this when we reach G11
    { label: 'Natural (asc)', value: 7 },
    { label: 'Natural (desc)', value: 8 },
];

export function SortSelect(props: Props) {
    const { onChange, sort } = props;
    const value = useMemo(() => SORT_OPTIONS.find((o) => o.value === sort) ?? SORT_OPTIONS[0], [sort]);
    const theme = useTheme2();
    const styles = getStyles(theme);

    return (
        <Select
            onChange={onChange}
            value={value}
            width={26.5}
            options={SORT_OPTIONS}
            className={styles.selectContainer}
        />
    );
}
