import React, { useEffect, useState } from 'react';
import { css, cx } from '@emotion/css';
import { autoUpdate, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Portal, useTheme2 } from '@grafana/ui';
import { actions } from 'components/state/actions';
import { useDispatch, useWideSkyState } from 'components/state/context';
import { WideSkyIcon, WideSkyInput } from 'components/WideSkyVisualComponents';
import {
    FieldNode,
    getNamedType,
    GraphQLNamedOutputType,
    GraphQLObjectType,
    isListType,
    isObjectType,
    isUnionType,
    Kind,
    OperationDefinitionNode,
    parse,
    SelectionNode,
} from 'graphql';
import { QueryElementMenu } from './QueryElementMenu';
import { getStyles as getWSStyles } from '../WideSkyVisualComponents';
import { GrafanaTheme2 } from '@grafana/data';
import { resolveNodeName } from './utils';
import { QueryNode } from './types';
import { QueryElementArgument } from './QueryElementArgument';

const borderStyling = '1px solid rgba(155, 155, 155, 0.2)';

const getStyles = (theme: GrafanaTheme2) => ({
    verticalLine: css({
        position: 'absolute',
        top: theme.spacing(theme.components.height.md),
        left: theme.spacing(theme.components.height.md / 2),
        borderLeft: borderStyling,
        height: `calc((100% - ${theme.spacing(theme.components.height.md)}) - ${theme.spacing(0.5)})`,
    }),

    verticalLineMask: css({
        position: 'absolute',
        top: `calc(${theme.spacing(theme.components.height.md / 2)} + 1px)`, // 1px is added so the mask is not painted in the vertex
        left: theme.spacing(-theme.components.height.md / 2),
        borderLeft: `1px solid ${theme.colors.background.canvas}`,
        height: `calc((100% - ${theme.spacing(theme.components.height.md / 2)}) - ${theme.spacing(0.5)})`,
    }),

    horizontalLine: css({
        position: 'absolute',
        width: theme.spacing(theme.components.height.md / 2),
        left: theme.spacing(-theme.components.height.md / 2),
        top: theme.spacing(theme.components.height.md / 2),
        borderTop: borderStyling,
    }),

    container: css({
        position: 'relative',
        marginLeft: theme.spacing(theme.components.height.md),
    }),

    rootElementContainer: css({
        position: 'relative',
    }),
});

export function QueryBuilder() {
    const state = useWideSkyState();
    const [rootElement, setRootElement] = useState<FieldNode>();
    const [rootElementSchema, setRootElementSchema] = useState<GraphQLObjectType>();

    useEffect(() => {
        if (!state.schema) {
            if (state.target.setQueryInfo) {
                state.target.setQueryInfo({
                    severity: 'error',
                    data: {
                        error: `Unable to retrieve schema from ${state.appName} server, check datasource settings`,
                        message: 'The server cannot be reached',
                    },
                });
            }
            return;
        }

        let query = state.target.query;
        if (query.trimStart().at(0) !== '{') {
            query = `{${query}}`;
        }

        const document = parse(query, { allowLegacyFragmentVariables: true });
        const definition = document.definitions[0] as OperationDefinitionNode;
        const element = (definition.selectionSet.selections[0] as FieldNode)!;

        const name = element.name.value.charAt(0).toUpperCase() + element.name.value.slice(1);
        const schema = state.schema.getType(name);

        if (!isObjectType(schema)) {
            throw Error('Expected schema to be an object');
        }

        setRootElement(element);
        setRootElementSchema(schema);
    }, [state]);

    if (rootElement === undefined || rootElementSchema === undefined) {
        return null;
    }

    return <QueryElement element={rootElement} schema={rootElementSchema} isRootElement={true} index={0} />;
}

interface QueryElementProps {
    element: QueryNode;
    index: number;
    schema?: GraphQLNamedOutputType;
    parentElement?: QueryNode;
    parentSchema?: GraphQLNamedOutputType;
    isRootElement?: boolean;
}

function QueryElement(props: QueryElementProps) {
    const { element, schema, parentElement, parentSchema, isRootElement, index } = props;
    const [inputVisible, setInputVisible] = useState(false);
    const [show, setShow] = useState(false);

    const dispatch = useDispatch();

    const theme = useTheme2();
    const wsStyles = getWSStyles(theme);
    const styles = getStyles(theme);

    const createNextQueryElement = (nextNode: SelectionNode, index: number) => {
        if (nextNode.kind === Kind.FRAGMENT_SPREAD) {
            return null;
        }

        const name = resolveNodeName(nextNode, false);

        if (name === 'dataPoints' || name === undefined) {
            return null;
        }

        let nextSchema: GraphQLNamedOutputType;

        if (isObjectType(schema)) {
            nextSchema = getNamedType(schema.getFields()[name].type);
        } else if (isListType(schema)) {
            nextSchema = getNamedType(schema.ofType as GraphQLNamedOutputType);
        } else if (isUnionType(schema)) {
            const topLevelType = schema.getTypes().find((type) => type.name === name);
            nextSchema = getNamedType(topLevelType as GraphQLNamedOutputType);
        } else {
            return null;
        }

        return (
            <QueryElement
                key={index}
                index={index}
                element={nextNode}
                schema={nextSchema}
                parentElement={element}
                parentSchema={schema}
            />
        );
    };

    const name = resolveNodeName(element, true);

    const { context, refs, floatingStyles } = useFloating({
        open: show,
        placement: 'left',
        onOpenChange: setShow,
        whileElementsMounted: autoUpdate,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

    return (
        <div className={isRootElement ? styles.rootElementContainer : styles.container}>
            <div className={wsStyles.element}>
                <div
                    onDoubleClick={() => setInputVisible(!inputVisible)}
                    className={cx(wsStyles.text(theme.colors.primary.text), wsStyles.container, wsStyles.field())}
                    ref={refs.setReference}
                    {...getReferenceProps()}
                >
                    {name}
                </div>
                {!isRootElement && inputVisible && element.kind === Kind.FIELD && (
                    <>
                        <WideSkyInput
                            value={element.alias?.value}
                            placeholder="Add alias"
                            onBlur={(event) => {
                                if (parentElement === undefined || parentElement.kind === Kind.INLINE_FRAGMENT) {
                                    console.error(
                                        `Attempted to edit alias on element that has no parent (${element.name.value})`
                                    );
                                    return;
                                }

                                const value = event.target.value.trim();

                                if (value.length === 0) {
                                    dispatch(
                                        actions.queryElementChanged({
                                            element,
                                            parentElement,
                                            item: element.name.value,
                                        })
                                    );
                                    return;
                                }

                                dispatch(
                                    actions.queryElementChanged({
                                        element,
                                        parentElement,
                                        item: `${value}:${element.name.value}`,
                                    })
                                );
                            }}
                        />
                        <WideSkyIcon name="check" onClick={() => setInputVisible(false)} />
                    </>
                )}
                <QueryElementArgument element={element} parentSchema={parentSchema} />
                {element.selectionSet && element.selectionSet.selections.length !== 0 && (
                    <div className={styles.verticalLine} />
                )}
            </div>
            {show && (
                <Portal>
                    <div
                        onMouseLeave={() => setShow(false)}
                        onClick={() => setShow(false)}
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        <QueryElementMenu
                            element={element}
                            schema={schema}
                            parentElement={parentElement}
                            parentSchema={parentSchema}
                        />
                    </div>
                </Portal>
            )}
            {parentElement !== undefined && (
                <>
                    <div className={styles.horizontalLine} />
                    {(parentElement.kind === Kind.INLINE_FRAGMENT || parentElement.kind === Kind.FIELD) &&
                        parentElement.selectionSet &&
                        parentElement.selectionSet.selections.length - 1 === index && (
                            <div className={styles.verticalLineMask} />
                        )}
                </>
            )}
            {element.selectionSet && element.selectionSet.selections.map(createNextQueryElement)}
        </div>
    );
}
