import { DataFrame, Field, FieldType, VariableSort } from '@grafana/data';
import { GraphQLResponse, WideSkyQueryJSONTarget } from 'types';
import { buildAliasName, SortableValue, sortVariableValues } from 'utils/sort';
import { Config } from './config';

enum NodeFieldNames {
    id = 'id',
    primaryText = 'primary_text',
    secondaryText = 'secondary_text',
    alias = 'detail__alias',
    detail = 'detail__',
}

enum EdgeFieldNames {
    to = 'to',
    from = 'from',
}

interface Edges {
    to: Field;
    from: Field;
}

export function createNodeGraphData(
    graphQLRoot: Readonly<GraphQLResponse>,
    target: Omit<WideSkyQueryJSONTarget, 'refId' | 'formatType' | 'query'>
): DataFrame[] {
    const config = target.config as Config | undefined;

    const fieldMap: Map<string, string> = new Map<string, string>([
        ['node_graph_id', NodeFieldNames.id],
        [NodeFieldNames.secondaryText, NodeFieldNames.secondaryText],
        [NodeFieldNames.primaryText, NodeFieldNames.primaryText],
        [NodeFieldNames.alias, NodeFieldNames.alias],
    ]);

    const nodeFields: Map<string, Field> = new Map<string, Field>();
    nodeFields.set(NodeFieldNames.id, createField(NodeFieldNames.id));

    const edges: Edges = { from: createField(EdgeFieldNames.from), to: createField(EdgeFieldNames.to) };

    const nodeGroups: any[][] = [];

    const aliases: string[] = [];
    const primaryText: string[] = [];
    const secondaryText: string[] = [];
    const sortAlias: string[] = [];
    const sort: Array<VariableSort | undefined> = [];

    for (const searchName in graphQLRoot.haystack) {
        const searchValue = graphQLRoot.haystack[searchName];
        if (
            searchValue.entity === undefined ||
            searchValue.entity.length === 0 ||
            searchValue.entity.at(0).node_graph_id === undefined
        ) {
            continue;
        }

        nodeGroups.push(searchValue.entity);
        aliases.push(searchName);

        const layer = config?.layers?.find((layer) => layer.alias === searchName);

        primaryText.push(layer?.primaryText || '{name}');
        secondaryText.push(layer?.secondaryText || '{description}');
        sortAlias.push(layer?.sortAlias || '');
        sort.push(layer?.sort);
    }

    nodeGroups.forEach((nodeGroup, index) => {
        const alias = aliases.at(index);
        const primaryTextValue = primaryText.at(index);
        const secondaryTextValue = secondaryText.at(index);
        const sortAliasValue = sortAlias.at(index);
        const sortValue = sort.at(index);

        if (
            alias === undefined ||
            primaryTextValue === undefined ||
            secondaryTextValue === undefined ||
            sortAliasValue === undefined
        ) {
            return;
        }

        processNodeGroup(
            nodeGroup,
            nodeGroups,
            edges,
            fieldMap,
            nodeFields,
            alias,
            primaryTextValue,
            secondaryTextValue,
            sortAliasValue,
            sortValue
        );
    });

    const nodeData: DataFrame = {
        fields: [],
        name: 'nodes',
        meta: {
            preferredVisualisationType: 'nodeGraph',
        },
        length: 1,
    };

    const edgeData: DataFrame = {
        fields: [edges.from, edges.to],
        name: 'edges',
        meta: {
            preferredVisualisationType: 'nodeGraph',
        },
        length: 1,
    };

    for (const field of nodeFields.values()) {
        nodeData.fields.push(field);
    }

    // Update the config
    if (target.setNodeLayers) {
        if (config?.layers !== undefined) {
            aliases.forEach((group, index) => {
                if (config.layers!.findIndex((layer) => layer.alias === group) !== -1) {
                    return;
                }

                config.layers!.splice(index, 0, { alias: group });
            });

            config.layers.forEach((layer, index) => {
                if (aliases.findIndex((group) => group === layer.alias) !== -1) {
                    return;
                }

                config.layers!.splice(index, 1);
            });

            target.setNodeLayers(config.layers.map((layer) => layer));
        } else {
            target.setNodeLayers(
                aliases.map((alias) => ({
                    alias,
                }))
            );
        }
    }

    return [nodeData, edgeData];
}

function processNodeGroup(
    nodeGroup: any[],
    allNodes: any[][],
    edges: Edges,
    fieldMap: Map<string, string>,
    nodeFields: Map<string, Field>,
    alias: string,
    primaryText: string,
    secondaryText: string,
    sortAlias: string,
    sort?: VariableSort
) {
    // Sort nodeGroup
    if (sortAlias !== '') {
        const hasValidAlias = buildAliasName(sortAlias, nodeGroup[0]) !== undefined;

        if (hasValidAlias) {
            const sortableGroups = nodeGroup.map<SortableValue<any>>((node) => {
                const sortValue = buildAliasName(sortAlias, node) || '';
                return { value: node, sortValue };
            });

            const sortedArray = sortVariableValues(sortableGroups, sort);
            if (sortedArray.length === nodeGroup.length) {
                nodeGroup = sortedArray;
            } else {
                console.error(
                    `Failed to create a sortable alias for all nodes in group: ${alias} (Nodes remain unsorted)`
                );
            }
        }
    }

    nodeGroup.forEach((node) => {
        for (const key in node) {
            const value = node[key];
            processField(key, value, fieldMap, nodeFields);

            if (Array.isArray(value) && value.length !== 0 && 'value' in value[0]) {
                const referenceValue = value[0].value;

                const edgeReference = allNodes
                    .flat()
                    .find((otherNode: any) => otherNode.node_graph_id === referenceValue);

                if (edgeReference) {
                    edges.from.values.push(edgeReference.node_graph_id);
                    edges.to.values.push(node.node_graph_id);
                    continue;
                }
            }
        }

        const resolvedPrimaryText = resolveText(primaryText, node);
        const resolvedSecondaryText = resolveText(secondaryText, node);

        processField(NodeFieldNames.primaryText, resolvedPrimaryText, fieldMap, nodeFields);
        processField(NodeFieldNames.secondaryText, resolvedSecondaryText, fieldMap, nodeFields);
        processField(NodeFieldNames.alias, alias, fieldMap, nodeFields);
    });
}

function processField(key: string, value: string, fieldMap: Map<string, string>, nodeFields: Map<string, Field>) {
    const fieldKey = fieldMap.get(key);
    if (!fieldKey) {
        return;
    }

    if (!nodeFields.has(fieldKey)) {
        nodeFields.set(fieldKey, createField(fieldKey));
        const otherFieldLength = nodeFields.get(NodeFieldNames.id)!.values.length - 1;

        if (otherFieldLength !== 0) {
            nodeFields.get(fieldKey)!.values = Array.from(Array(otherFieldLength).keys()).map(() => undefined);
        }
    }

    nodeFields.get(fieldKey)!.values.push(value);
}

function resolveText(aliasPath: string, node: any): string {
    const ALIAS_SET_PATTERN = /\{(.*?)\}/g;

    return aliasPath.replace(ALIAS_SET_PATTERN, (_matched, captured: string) => {
        const resolvedPaths = captured.split('.');
        let name = node;

        resolvedPaths.forEach((path) => {
            if (name === undefined) {
                return;
            }

            if (path.endsWith(']')) {
                path.split('[').forEach((part) => {
                    if (part.endsWith(']')) {
                        part = part.slice(0, part.length - 1);
                    }

                    name = name[part];
                });
            } else {
                name = name[path];
            }
        });

        return name || '';
    });
}

function createField(name: string): Field {
    return {
        values: [],
        name,
        type: FieldType.string,
        config: {},
    };
}
