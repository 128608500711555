import { GraphQLResponse, QueryType, WideSkyQueryJSONTarget } from 'types';
import { createTableDeprecatingData } from './parse';

export const TableDeprecatingQuery: QueryType = {
    createResponse(target: WideSkyQueryJSONTarget, graphQLRoot: Readonly<GraphQLResponse>) {
        return createTableDeprecatingData(
            graphQLRoot,
            target.query,
            target.config,
            target.setColumnOptions
        );
    },
};
