import React from 'react';

import { GrafanaTheme2, StandardEditorProps } from '@grafana/data';
import { Container, IconButton, useStyles2, ValuePicker } from '@grafana/ui';
import { GenericLayerElement, GraphState, NodeGraphOptions } from './editor.types';
import { css, cx } from '@emotion/css';

type Props = StandardEditorProps<unknown, unknown, NodeGraphOptions, GraphState>;

export const LayerSelector = (props: Props) => {
    if (props.context.instanceState === undefined) {
        return <div>No layers found</div>;
    }

    const { layers, actions, aliases } = props.context.instanceState as GraphState;

    const onSelect = (element: GenericLayerElement) => {
        actions.selectLayer(element.options.name);
    };

    const onDelete = (element: GenericLayerElement) => {
        actions.deleteLayer(element.options.name);
    };

    const availableLayers = layers.filter((layer) => layer.options.isAssignedInOptions);
    const availableAliases = aliases.filter((alias) => !availableLayers.some((layer) => layer.options.name === alias));

    return (
        <>
            {availableAliases.length !== 0 && (
                <>
                    <Container>
                        <ValuePicker
                            icon="plus"
                            label={'Add layer'}
                            variant="secondary"
                            options={availableAliases.map((option) => ({ value: option, label: option }))}
                            onChange={(option) => actions.addLayer(option.value!)}
                            isFullWidth={true}
                        />
                    </Container>
                    <br />
                </>
            )}

            <LayerList layers={availableLayers} aliases={aliases} onSelect={onSelect} onDelete={onDelete} />
        </>
    );
};

type ListProps = {
    layers: GenericLayerElement[];
    aliases: string[];
    onSelect: (element: GenericLayerElement) => void;
    onDelete: (element: GenericLayerElement) => void;
};

const LayerList = (props: ListProps) => {
    const { layers, aliases, onSelect, onDelete } = props;

    const style = useStyles2(getStyles);

    const getRowStyle = (isSelected: boolean) => {
        return isSelected ? `${style.row} ${style.sel}` : style.row;
    };

    return (
        <div>
            {layers.reverse().map((layer, index) => (
                <div
                    key={index}
                    className={getRowStyle(layer.isSelected)}
                    onMouseDown={() => onSelect(layer)}
                    role="button"
                    tabIndex={0}
                >
                    <div className={style.textWrapper}>
                        &nbsp;{' '}
                        {`${layer.options.name}${
                            layer.options.type === 'edge' || aliases.some((alias) => layer.options.name === alias)
                                ? ''
                                : ' (Not found)'
                        }`}
                    </div>
                    {layer.options.type !== 'edge' && (
                        <IconButton
                            name="trash-alt"
                            tooltip="Remove"
                            className={cx(style.actionIcon, style.dragIcon)}
                            onClick={() => onDelete(layer)}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

const getStyles = (theme: GrafanaTheme2) => ({
    wrapper: css({
        marginBottom: theme.spacing(2),
    }),
    row: css({
        padding: theme.spacing(0.5, 1),
        borderRadius: 2,
        background: theme.colors.background.secondary,
        minHeight: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '3px',
        cursor: 'pointer',

        border: `1px solid ${theme.components.input.borderColor}`,
        '&:hover': {
            border: `1px solid ${theme.components.input.borderHover}`,
        },
    }),
    sel: css({
        border: `1px solid ${theme.colors.primary.border}`,
        '&:hover': {
            border: `1px solid ${theme.colors.primary.border}`,
        },
    }),
    dragIcon: css({
        cursor: 'drag',
    }),
    actionIcon: css({
        color: theme.colors.text.secondary,
        '&:hover': {
            color: theme.colors.text.primary,
        },
    }),
    typeWrapper: css({
        color: theme.colors.primary.text,
        marginRight: '5px',
    }),
    textWrapper: css({
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        overflow: 'hidden',
        marginRight: theme.spacing(1),
    }),
});
