import React from 'react';
import { Alert, useTheme2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { useWideSkyState } from 'components/state/context';

const getStyles = (theme: GrafanaTheme2) => ({
    alertBody: css({
        marginTop: theme.spacing(2),
        whiteSpace: 'pre-wrap',
        fontFamily: 'monospace',
    }),
});

export function QueryInfoBar() {
    const state = useWideSkyState();

    const theme = useTheme2();
    const styles = getStyles(theme);

    const queryInfo = state.queryInfo;

    if (!queryInfo || !queryInfo.data || !queryInfo.data.message) {
        return null;
    }

    return (
        <Alert className={styles.alertBody} title={queryInfo.data.message} severity={queryInfo.severity}>
            {queryInfo.data.error}
        </Alert>
    );
}
