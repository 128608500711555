export const GROUP_TYPE = ['box', 'column', 'node'] as const;

export type NodeDatum = {
    children: NodeDatum[];
    id: string;
    primaryColor: string;
    secondaryColor: string;
    textColor: string;
    primaryText?: string;
    secondaryText?: string;
    detail: Array<{ name: string; value: any }>;

    url?: string;
    urlTitle?: string;
    openInNewTab?: boolean;

    hasBeenPlaced: boolean;
    showTooltip?: boolean;
    groupId?: string;
    groupType: (typeof GROUP_TYPE)[number];
    bounds: Bounds;
};

export type EdgeDatum = {
    from: string;
    to: string;
};

export type Position = {
    x: number;
    y: number;
};

export interface Bounds {
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export const nodeHeight = 50; // x
export const halfNodeHeight = nodeHeight / 2;
export const nodeWidth = nodeHeight * 5;
export const fontSize = nodeHeight * 0.288;
export const borderRadius = nodeHeight / 6;
