import { GraphQLArgument, GraphQLNamedOutputType, isObjectType, Kind, ValueNode } from 'graphql';
import React from 'react';
import { QueryNode } from './types';
import { useDispatch } from 'components/state/context';
import { actions } from 'components/state/actions';
import { ObjectArgument } from './arguments/ObjectArgument';

interface Props {
    element: QueryNode;
    parentSchema?: GraphQLNamedOutputType;
}

export function QueryElementArgument(props: Props) {
    const { element, parentSchema } = props;

    const dispatch = useDispatch();

    if (!isObjectType(parentSchema) || element.kind !== Kind.FIELD) {
        return <ObjectArgument allArguments={[]} isRequired={true} onChange={() => {}} />;
    }

    return (
        <ObjectArgument
            values={element.arguments}
            isRequired={true}
            allArguments={parentSchema.getFields()[element.name.value].args}
            onChange={(newValue: ValueNode, argument: GraphQLArgument) =>
                dispatch(actions.queryElementArgumentChanged({ argument, element, value: newValue }))
            }
        />
    );
}
