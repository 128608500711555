import { sortBy, toLower } from 'lodash';
import { VariableSort } from '@grafana/data';

export const buildAliasName = (aliasPath: string, node: any): string | undefined => {
    let failed = false;

    const resolvedPaths = aliasPath.split('/');
    let name = node;

    resolvedPaths.forEach((path) => {
        if (name === null || name === undefined) {
            failed = true;
            return;
        }

        if (!path.endsWith(']')) {
            name = name[path];
            return;
        }

        path.split('[').forEach((subPath) => {
            if (name === null || name === undefined) {
                failed = true;
                return;
            }

            if (subPath.endsWith(']')) {
                subPath = subPath.slice(0, subPath.length - 1);
            }

            name = name[subPath];
        });
    });

    if (failed) {
        return undefined;
    }

    return String(name);
};

export type SortableValue<T> = {
    sortValue: string;
    value: T;
};

export const sortVariableValues = <T>(values: Array<SortableValue<T>>, sortOrder?: VariableSort) => {
    if (sortOrder === undefined || sortOrder === VariableSort.disabled) {
        return values.map((value) => value.value);
    }

    const sortType = Math.ceil(sortOrder / 2);
    const reverseSort = sortOrder % 2 === 0;

    if (sortType === 1) {
        values = sortBy(values, 'sortValue');
    } else if (sortType === 2) {
        values = sortBy(values, (opt) => {
            if (!opt.sortValue) {
                return -1;
            }

            const matches = opt.sortValue.match(/.*?(\d+).*/);
            if (!matches || matches.length < 2) {
                return -1;
            } else {
                return parseInt(matches[1], 10);
            }
        });
    } else if (sortType === 3) {
        values = sortBy(values, (opt) => {
            return toLower(opt.sortValue);
        });
    } else if (sortType === 4) {
        values.sort((a, b) => {
            if (!a.sortValue) {
                return -1;
            }

            if (!b.sortValue) {
                return 1;
            }

            return a.sortValue.localeCompare(b.sortValue, undefined, { numeric: true });
        });
    }

    if (reverseSort) {
        values = values.reverse();
    }

    return values.map((value) => value.value);
};
