import { SelectableValue } from '@grafana/data';
import { WideSkySelect } from 'components/WideSkyVisualComponents';
import { GraphQLEnumType, Kind, ValueNode } from 'graphql';
import React, { useEffect, useState } from 'react';
import { UNSELECTED_VALUE } from '../types';
import { getTemplateSrv } from '@grafana/runtime';

interface Props {
    isRequired: boolean;
    value?: ValueNode;
    type: GraphQLEnumType;
    onChange: (value: ValueNode) => void;
}

export function EnumArgument(props: Props) {
    const { isRequired, value, type, onChange } = props;
    const [displayedValue, setDisplayedValue] = useState<string>(UNSELECTED_VALUE);

    useEffect(() => {
        if (value === undefined) {
            setDisplayedValue(UNSELECTED_VALUE);
        } else if (value.kind === Kind.VARIABLE) {
            setDisplayedValue(`$${value.name.value}`);
        } else if (value.kind === Kind.ENUM) {
            setDisplayedValue(value.value);
        }
    }, [value]);

    if (value !== undefined && value.kind !== Kind.VARIABLE && value.kind !== Kind.ENUM) {
        console.error(`Argument type ${type.name} does not match value type (${value.kind})`);
        return null;
    }

    const options: Array<SelectableValue<string>> = type
        .getValues()
        .map((listValue) => ({ label: listValue.name, value: listValue.value }));

    if (type.name === 'duration') {
        options.push({ label: '$__intervalDuration', value: '$__intervalDuration' });
    }

    const templateVariables = getTemplateSrv().getVariables();

    options.push(
        ...templateVariables.map((variable) => ({
            label: `$${variable.name}`,
            value: `$${variable.name}`,
        }))
    );

    if (!isRequired) {
        options.push({ label: UNSELECTED_VALUE, value: UNSELECTED_VALUE });
    }

    // If we allow the defaultValue to be displayed here. There creates a case where its object parent does not exist in
    // the 'onChangeObject' function, so the logic around how that should work will need to be discussed with
    // a project owners. Causing 'value === undefined => true' and the code attempt to add the UNSELECTED_VALUE string
    // to the query under its parent object. Basically, we would need a way of telling if the user set a value to
    // undefined/UNSELECTED_VALUE/(or other empty value) or if the query builder initialised a value as undefined.
    return (
        <WideSkySelect
            value={displayedValue}
            options={options.reverse()}
            onChange={(option) => {
                const newValue = option.value;

                if (newValue === undefined) {
                    return;
                }

                if (newValue.at(0) === '$') {
                    onChange({ kind: Kind.VARIABLE, name: { kind: Kind.NAME, value: newValue } });
                    return;
                }

                onChange({ kind: Kind.ENUM, value: newValue });
            }}
        />
    );
}
