import { DataFrame, Field, FieldCache } from '@grafana/data';
import {
    ColorMap,
    defaultNodeSecondaryColor,
    defaultNodeTextColor,
    NodeGraphOptions,
    NodeRenderOptions,
} from 'editor/editor.types';
import { EdgeDatum, NodeDatum, nodeHeight, nodeWidth } from 'types';
import { EdgeFieldNames, EdgeFields, NodeFieldNames, NodeFields } from './preprocessing.types';
import { nodeExistsInList } from './utils';

/**
 * Processes the given data frames to extract node and edge fields.
 *
 * @param {DataFrame[]} dataFrames - An array of data frames containing nodes and edges data.
 * @returns {{ nodeFields: NodeFields; edgeFields: EdgeFields; aliases?: Set<string | undefined> } | undefined} - An object containing node fields, edge fields, and optionally a set of aliases, or undefined if the data frames are invalid.
 */
export function processFields(
    dataFrames: DataFrame[]
): { nodeFields: NodeFields; edgeFields: EdgeFields; aliases?: Set<string | undefined> } | undefined {
    if (dataFrames.length < 2) {
        return;
    }

    const nodesDataFrame = dataFrames.find((dataFrame) => dataFrame.name === 'nodes');
    const edgesDataFrame = dataFrames.find((dataFrame) => dataFrame.name === 'edges');

    if (!nodesDataFrame || !edgesDataFrame) {
        return;
    }

    const nodeFields = getNodeFields(nodesDataFrame);
    const edgeFields = getEdgeFields(edgesDataFrame);

    if (!nodeFields || !edgeFields) {
        return;
    }

    if (nodeFields.alias !== undefined) {
        const aliases = new Set(nodeFields.alias.values);
        return { nodeFields, edgeFields, aliases };
    }
    return { nodeFields, edgeFields };
}

/**
 * Processes node and edge fields to generate node and edge data.
 *
 * @param {NodeFields} nodeFields - The fields of the nodes.
 * @param {EdgeFields} edgeFields - The fields of the edges.
 * @param {NodeGraphOptions} options - Options for rendering the node graph.
 * @returns {{ nodes: NodeDatum[]; edges: EdgeDatum[] }} - An object containing arrays of nodes and edges.
 */
export function processData(nodeFields: NodeFields, edgeFields: EdgeFields, options: NodeGraphOptions) {
    const edges = processEdges(edgeFields);
    const flatNodes = processNodes(nodeFields, options, edges);
    const nodes = buildTraversalTree(flatNodes);

    return { nodes, edges };
}

/**
 * Extracts node fields from the given data frame.
 *
 * @param {DataFrame} nodes - The data frame containing nodes data.
 * @returns {NodeFields | undefined} - An object containing node fields or undefined if the fields are invalid.
 */
export function getNodeFields(nodes: DataFrame): NodeFields | undefined {
    const normalizedFrames = {
        ...nodes,
        fields: nodes.fields.map((field) => ({ ...field, name: field.name })),
    };

    const fieldsCache = new FieldCache(normalizedFrames);

    const id = fieldsCache.getFieldByName(NodeFieldNames.id);
    const primaryText = fieldsCache.getFieldByName(NodeFieldNames.primaryText);
    const secondaryText = fieldsCache.getFieldByName(NodeFieldNames.secondaryText);
    const alias = fieldsCache.getFieldByName(NodeFieldNames.alias);
    const detail = findFieldsByPrefix(nodes, NodeFieldNames.detail);

    if (!id) {
        return;
    }

    return { id, primaryText, secondaryText, alias, detail };
}

/**
 * Extracts edge fields from the given data frame.
 *
 * @param {DataFrame} edges - The data frame containing edges data.
 * @returns {EdgeFields | undefined} - An object containing edge fields or undefined if the fields are invalid.
 */
export function getEdgeFields(edges: DataFrame): EdgeFields | undefined {
    const normalizedFrames = {
        ...edges,
        fields: edges.fields.map((field) => ({ ...field, name: field.name })),
    };

    const fieldsCache = new FieldCache(normalizedFrames);

    const from = fieldsCache.getFieldByName(EdgeFieldNames.from);
    const to = fieldsCache.getFieldByName(EdgeFieldNames.to);

    if (!from || !to) {
        return;
    }

    return { from, to };
}

/**
 * Processes node fields and options to generate an array of node data.
 *
 * @param {Readonly<NodeFields>} nodeFields - The fields of the nodes.
 * @param {NodeGraphOptions} options - Options for rendering the node graph.
 * @param {EdgeDatum[]} edges - An array of edge data.
 * @returns {NodeDatum[]} - An array of node data.
 */
export function processNodes(
    nodeFields: Readonly<NodeFields>,
    options: NodeGraphOptions,
    edges: EdgeDatum[]
): NodeDatum[] {
    // Initial mapping of IDs to NodeDatum objects
    let nodes = nodeFields.id.values
        .map<NodeDatum | undefined>((id, index) => {
            const renderOptions = options.renderOptions?.find(
                (option): option is NodeRenderOptions => option.name === nodeFields.alias?.values.get(index)
            );

            if (renderOptions === undefined || renderOptions.isAssignedInOptions === false) {
                return;
            }

            const detail = nodeFields.detail
                .map<{ name: string; value: any }>((field) => ({
                    name: field.name.slice(8),
                    value: field.values.get(index),
                }))
                .filter((field) => field.value !== undefined);

            const groupType = renderOptions.groupType || 'box';
            const primaryColor = renderOptions.primaryColor || ColorMap[groupType];
            const secondaryColor = renderOptions.secondaryColor || defaultNodeSecondaryColor;
            const textColor = renderOptions.textColor || defaultNodeTextColor;

            const url = renderOptions.dataLinkingEnabled ? renderOptions.url : undefined;
            const urlTitle = renderOptions.dataLinkingEnabled ? renderOptions.urlTitle : undefined;
            const openInNewTab = renderOptions.dataLinkingEnabled ? renderOptions.openInNewTab : undefined;

            const showTooltip = renderOptions.showTooltip;

            const node: NodeDatum = {
                id,
                primaryText: nodeFields.primaryText?.values.get(index),
                secondaryText: nodeFields.secondaryText?.values.get(index),
                detail,
                url,
                urlTitle,
                openInNewTab,
                showTooltip,
                groupType,
                primaryColor,
                secondaryColor,
                textColor,
                hasBeenPlaced: false,
                children: [],
                bounds: { left: -nodeWidth / 2, right: nodeWidth / 2, top: -nodeHeight / 2, bottom: nodeHeight / 2 },
            };

            return node;
        })
        .filter((node): node is NodeDatum => node !== undefined);

    // Establish parent-child relationships
    edges.forEach((edge) => {
        const parent = nodes.find((node) => node.id === edge.from);
        const child = nodes.find((node) => node.id === edge.to);
        if (parent === undefined || child === undefined) {
            return;
        }

        parent.children.push(child);
    });

    let prevLength = 0;

    // Continue culling until no more nodes can be removed
    while (nodes.length !== prevLength) {
        prevLength = nodes.length;

        nodes = nodes.filter((node) => node.groupType === 'node' || node.children.length !== 0);

        // Update children to ensure they are also in the nodesToProcess list
        nodes.forEach((node) => {
            if (!node.children) {
                return;
            }

            node.children = node.children.filter(
                (child) => nodes.findIndex((otherNode) => otherNode.id === child.id) !== -1
            );
        });
    }

    return nodes;
}

/**
 * Builds a traversal tree from an array of node data.
 *
 * @param {NodeDatum[]} nodes - An array of node data.
 * @returns {NodeDatum[]} - An array of root nodes forming the traversal tree.
 */
export function buildTraversalTree(nodes: NodeDatum[]): NodeDatum[] {
    const nodeMap = new Map<string, NodeDatum>();

    nodes.forEach((node) => {
        nodeMap.set(node.id, { ...node, children: node.children ? [...node.children] : [] });
    });

    const buildTree = (node: NodeDatum): NodeDatum => {
        if (!node.children) {
            return node;
        }

        node.children = node.children.map((childNode) => {
            const child = nodeMap.get(childNode.id);
            if (child) {
                if (node.groupType !== 'node') {
                    child.groupId = node.id;
                }
                return buildTree(child);
            }

            return childNode;
        });

        return node;
    };

    const rootNodes = Array.from(nodeMap.values()).filter(
        (node) =>
            !Array.from(nodeMap.values()).some((otherNode) => otherNode.children.some((child) => child.id === node.id))
    );

    const tree = rootNodes.map(buildTree);

    const cullExcessChildren = (node: NodeDatum) => {
        if (!node.children) {
            return;
        }

        const newChildren: NodeDatum[] = [];

        node.children.forEach((childNode) => {
            const children = node.children.filter((child) => child.id !== childNode.id);
            const childIsAlreadyPresent = nodeExistsInList(childNode, children);

            if (childIsAlreadyPresent === false && (node.groupType !== 'node' || childNode.groupType === 'node')) {
                newChildren.push(childNode);
            }

            cullExcessChildren(childNode);
        });

        node.children = newChildren;
    };

    tree.forEach(cullExcessChildren);
    return tree;
}

/**
 * Processes edge fields to generate an array of edge data.
 *
 * @param {EdgeFields} edgeFields - The fields of the edges.
 * @returns {EdgeDatum[]} - An array of edge data.
 */
export function processEdges(edgeFields: EdgeFields): EdgeDatum[] {
    return edgeFields.from.values.map((from: string, index) => ({
        from,
        to: edgeFields.to?.values.get(index),
    }));
}

/**
 * Finds fields in the given data frame that have names matching the specified prefix.
 *
 * @param {DataFrame} frame - The data frame to search for fields.
 * @param {string} prefix - The prefix to match field names against.
 * @returns {Field[]} - An array of fields with names matching the prefix.
 */
function findFieldsByPrefix(frame: DataFrame, prefix: string): Field[] {
    return frame.fields.filter((f) => f.name !== 'detail__alias').filter((f) => f.name.match(new RegExp('^' + prefix)));
}
