import React from 'react';
import { css } from '@emotion/css';
import { borderRadius, fontSize, halfNodeHeight, NodeDatum, nodeHeight } from 'types';

const getStyles = () => ({
    node: (width: number, height: number, primaryColor: string, secondaryColor: string) =>
        css({
            width,
            height,
            backgroundColor: primaryColor,
            borderRadius: borderRadius,
            border: `solid ${secondaryColor}`,
            borderTopWidth: 0,
            borderRightWidth: nodeHeight * 0.08,
            borderBottomWidth: 0,
            borderLeftWidth: 0,
        }),

    text: (color: string) =>
        css({
            color,
            position: 'absolute',
            top: nodeHeight / 3,
            transform: 'translateY(-50%)',
            right: nodeHeight / 3,
            left: nodeHeight,
            fontWeight: 400,
            fontSize: fontSize,
            textAlign: 'right',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            direction: 'rtl',
            textOverflow: 'ellipsis',
            transition: 'opacity 0.5s ease',
        }),

    subtext: (color: string) =>
        css({
            color,
            position: 'absolute',
            top: nodeHeight / 1.5,
            transform: 'translateY(-50%)',
            right: nodeHeight / 3,
            left: nodeHeight,
            fontWeight: 300,
            fontSize: fontSize / 2,
            textAlign: 'right',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            direction: 'rtl',
            textOverflow: 'ellipsis',
            transition: 'opacity 0.5s ease',
        }),

    status: (secondaryColor: string) =>
        css({
            position: 'absolute',
            transform: 'translateY(-50%) translateX(-50%)',
            top: halfNodeHeight,
            left: halfNodeHeight,
            width: borderRadius * 2,
            height: borderRadius * 2,
            borderRadius: '50%',
            backgroundColor: secondaryColor,
            transition: 'opacity 0.5s ease',
            border: `solid ${secondaryColor}`,
        }),
});

interface Props {
    node: NodeDatum;
    onClick: (id: string, context: 'up' | 'down') => void;
    onMouseEnter: (id: string) => void;
    onMouseLeave: (id: string) => void;
}

export function Node(props: Props) {
    const { node, onClick, onMouseEnter, onMouseLeave } = props;

    const styles = getStyles();

    return (
        <foreignObject
            data-testid={'node-container'}
            onMouseDown={() => onClick(node.id, 'down')}
            onMouseUp={() => onClick(node.id, 'up')}
            onMouseEnter={() => onMouseEnter(node.id)}
            onMouseLeave={() => onMouseLeave(node.id)}
            x={node.bounds.left}
            y={node.bounds.top}
            width={node.bounds.right - node.bounds.left}
            height={node.bounds.bottom - node.bounds.top}
        >
            <div
                className={styles.node(
                    node.bounds.right - node.bounds.left,
                    node.bounds.bottom - node.bounds.top,
                    node.primaryColor,
                    node.secondaryColor
                )}
            >
                <div className={styles.status(node.secondaryColor)}></div>
                <div className={styles.text(node.textColor)}>{node.primaryText}</div>
                <div className={styles.subtext(node.textColor)}>{node.secondaryText}</div>
            </div>
        </foreignObject>
    );
}
