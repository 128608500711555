import { NodeGraphPanel } from 'components/NodeGraphPanel';
import {
    ColorMap,
    defaultNodeSecondaryColor,
    defaultNodeTextColor,
    GenericLayerElement,
    GraphLayerActions,
    NodeRenderOptions,
} from 'editor/editor.types';

export const getActions = (panel: NodeGraphPanel) => {
    const actions: GraphLayerActions = {
        selectLayer: (name: string) => {
            const layerIndex = panel.layers.findIndex((layer) => layer.options.name === name);
            if (layerIndex === -1) {
                return;
            }

            panel.toggleSelected(layerIndex);
        },
        addLayer: (name: string) => {
            const layerIndex = panel.layers.findIndex((layer) => layer.options.name === name);
            if (layerIndex === -1) {
                const newOption: GenericLayerElement<NodeRenderOptions> = {
                    options: {
                        type: 'node',
                        isAssignedInOptions: true,
                        name,
                        groupType: 'node',
                        primaryColor: ColorMap['node'],
                        secondaryColor: defaultNodeSecondaryColor,
                        textColor: defaultNodeTextColor,
                        showTooltip: true,
                        dataLinkingEnabled: false,
                    },
                    isSelected: false,
                };

                panel.addNewLayerOption(newOption);
                return;
            }

            panel.toggleOptionVisibility(layerIndex, true);
        },
        deleteLayer: (name: string) => {
            const layerIndex = panel.layers.findIndex((layer) => layer.options.name === name);
            if (layerIndex === -1) {
                return;
            }

            panel.toggleOptionVisibility(layerIndex, false);
        },
    };

    return actions;
};
