import { Dispatch } from '@reduxjs/toolkit';
import { WideSkyQueryEditorState } from 'types';
import { actions, ActionType } from './actions';
import {
    addQueryElement,
    changeQueryElement,
    changeQueryElementArgument,
    initState,
    removeQueryElement,
} from './helpers';

async function reducer(state: WideSkyQueryEditorState, action: ActionType): Promise<WideSkyQueryEditorState> {
    state = { ...state };

    switch (action.type) {
        case actions.init.type: {
            const dependencies = (action as ReturnType<typeof actions.init>).payload;
            state = await initState(dependencies);
            break;
        }

        case actions.formatTypeChanged.type: {
            const type = (action as ReturnType<typeof actions.formatTypeChanged>).payload;
            state.target = { ...state.target, formatType: type, config: {} };
            break;
        }

        case actions.queryElementAdded.type: {
            const { element, item } = (action as ReturnType<typeof actions.queryElementAdded>).payload;
            state.target.query = addQueryElement(state.target.query, element, item);
            break;
        }
        case actions.queryElementChanged.type: {
            const { element, parentElement, item } = (action as ReturnType<typeof actions.queryElementChanged>).payload;
            state.target.query = changeQueryElement(state.target.query, element, parentElement, item);
            break;
        }
        case actions.queryElementRemoved.type: {
            const { element, parentElement } = (action as ReturnType<typeof actions.queryElementRemoved>).payload;
            state.target.query = removeQueryElement(state.target.query, element, parentElement);
            break;
        }
        case actions.queryElementArgumentChanged.type: {
            const { argument, element, value } = (action as ReturnType<typeof actions.queryElementArgumentChanged>)
                .payload;
            state.target.query = changeQueryElementArgument(state.target.query, argument, element, value);
            break;
        }

        case actions.editorChanged.type: {
            break;
        }
        case actions.runQuery.type: {
            const query = (action as ReturnType<typeof actions.runQuery>).payload;
            state.target.query = query;
            break;
        }
    }

    return { ...state };
}

export const createStore = (onChange: (state: WideSkyQueryEditorState) => void): Dispatch<ActionType> => {
    let state = {} as WideSkyQueryEditorState;

    const dispatch = async (action: ActionType) => {
        state = await reducer(state, action);
        onChange(state);
    };

    return dispatch as Dispatch<ActionType>;
};
