import React, { ChangeEvent } from 'react';
import { InlineField, InlineSwitch, Input } from '@grafana/ui';
import { DataSourcePluginOptionsEditorProps } from '@grafana/data';
import { WideSkyDataSourceOptions } from '../types';

interface Props extends DataSourcePluginOptionsEditorProps<WideSkyDataSourceOptions> {}

export const ConfigEditor: React.FC<Props> = ({ onOptionsChange, options }) => {
    const onInputChange = (key: keyof WideSkyDataSourceOptions) => (event: ChangeEvent<HTMLInputElement>) => {
        if (key === 'asTeamUser') {
            const jsonData = {
                ...options.jsonData,
                [key]: event.target.checked,
            };

            onOptionsChange({ ...options, jsonData });
            return;
        }

        const jsonData = {
            ...options.jsonData,
            [key]: event.target.value,
        };

        onOptionsChange({ ...options, jsonData });
    };

    const onUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
        onOptionsChange({ ...options, url: event.target.value });
    };

    return (
        <>
            <h3 className="page-heading">Server</h3>
            <InlineField label="URL" labelWidth={14}>
                <Input
                    type="url"
                    placeholder="http://xyz.cloud/api"
                    value={options.url}
                    onChange={onUrlChange}
                />
            </InlineField>

            <InlineField tooltip="Enable impersonation of different users based on the email of the team." label="Impersonate" labelWidth={14}>
                <InlineSwitch value={options.jsonData.asTeamUser} onChange={onInputChange('asTeamUser')} />
            </InlineField>

            <br />

            <h3 className="page-heading">Credentials</h3>
            <InlineField label="Client Id" labelWidth={14}>
                <Input value={options.jsonData.clientId} onChange={onInputChange('clientId')} />
            </InlineField>
            <InlineField label="Client Secret" labelWidth={14}>
                <Input value={options.jsonData.clientSecret} onChange={onInputChange('clientSecret')} />
            </InlineField>
            <InlineField label="Username" labelWidth={14}>
                <Input value={options.jsonData.username} onChange={onInputChange('username')} />
            </InlineField>
            <InlineField label="Password" labelWidth={14}>
                <Input type="password" value={options.jsonData.password} onChange={onInputChange('password')} />
            </InlineField>
        </>
    );
};
