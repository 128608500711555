import { createAction } from '@reduxjs/toolkit';
import { QueryNode } from 'components/query/types';
import { FieldNode, GraphQLArgument, ValueNode } from 'graphql';
import { FORMAT_TYPES, WideSkyEditorDependencies } from 'types';

const init = createAction<WideSkyEditorDependencies>('init');

const formatTypeChanged = createAction<(typeof FORMAT_TYPES)[number]>('format-type-changed');

const queryElementAdded = createAction<{ element: QueryNode; item: string }>('query-element-added');
const queryElementChanged = createAction<{ element: QueryNode; parentElement: QueryNode; item: string }>(
    'query-element-changed'
);
const queryElementRemoved = createAction<{ element: QueryNode; parentElement: QueryNode }>('query-element-removed');
const queryElementArgumentChanged = createAction<{
    argument: GraphQLArgument;
    element: FieldNode;
    value: ValueNode;
}>('query-element-argument-changed');

const editorChanged = createAction('editor-changed');
const runQuery = createAction<string>('run-current-query');

export const actions = {
    init,

    formatTypeChanged,

    queryElementAdded,
    queryElementChanged,
    queryElementRemoved,
    queryElementArgumentChanged,

    editorChanged,
    runQuery,
};

export type ActionType =
    | ReturnType<typeof init>
    | ReturnType<typeof formatTypeChanged>
    | ReturnType<typeof queryElementAdded>
    | ReturnType<typeof queryElementChanged>
    | ReturnType<typeof queryElementRemoved>
    | ReturnType<typeof queryElementArgumentChanged>
    | ReturnType<typeof editorChanged>
    | ReturnType<typeof runQuery>;
