import { DataSourceInstanceSettings, TestDataSourceResponse } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { GraphQLError, SourceLocation } from 'graphql';
import { WideSkyDataSourceOptions, InternalQueryError } from 'types';
import { ImpersonationQueue } from 'utils/impersonation_queue';

interface DataSourceTokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
}

interface SecretRequestData {
    grant_type: 'password' | 'refresh_token';
}

interface PasswordRequest extends SecretRequestData {
    username: string;
    password: string;
}

interface RefreshTokenRequest extends SecretRequestData {
    refresh_token: string;
}

interface RequestHeader {
    Authorization: string;
    'Content-Type': 'application/json';
    Accept: 'application/json';
    'X-IMPERSONATE'?: string;
}

interface DataSourceResponse<T = any> {
    data: T;
    errors?: GraphQLError[];
}

interface DataSourceRejectResponse {
    status: number;
    statusText: string;
    data: {
        error?: string;
        errors?: GraphQLError[];
        message?: string;
    };
}

export enum HttpCode {
    INTERNAL_FAILED_IMPERSONATE = 0,
    OK = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    BAD_GATEWAY = 502,
}

export enum ApiSeverEndpoint {
    GRAPHQL = 'graphql',
    READ_ENTITY = 'api/read',
    POINT_WRITE = 'api/pointWrite',
    OAUTH_2 = 'oauth2/token',
}

/**
 * Manages WideSky API server interactions, handling token management and data queries.
 * This class provides methods to acquire an access token, refresh the token, and perform queries to the WideSky API server.
 */
export class APIServerManager {
    private static readonly HTTP_ERROR_MESSAGES: { [key: (typeof HttpCode)[number]]: string } = {
        [HttpCode.INTERNAL_FAILED_IMPERSONATE]: 'Failed to impersonate',
        [HttpCode.BAD_REQUEST]: 'Something is wrong with your query',
        [HttpCode.UNAUTHORIZED]: 'Unauthorised',
        [HttpCode.NOT_FOUND]: 'Content not found',
        [HttpCode.BAD_GATEWAY]: 'The server cannot be reached',
    };

    private readonly instanceSettings: DataSourceInstanceSettings<WideSkyDataSourceOptions>;

    /** Token used for authenticated requests. */
    private token?: {
        accessToken: string;
        expiresIn: number;
        refreshToken: string;
    };

    private static impersonationQueue: ImpersonationQueue;

    /**
     * APIServerManager instance with given jsonData, URL, and backend service.
     * @param jsonData - The jsonData for WideSky authentication.
     * @param url - The base URL for the WideSky API server.
     * @param backendService - The Grafana service used to make backend requests.
     */
    constructor(instanceSettings: DataSourceInstanceSettings<WideSkyDataSourceOptions>) {
        if (!instanceSettings.url) {
            console.error('No Url provided, instance settings not set');
            this.instanceSettings = {} as DataSourceInstanceSettings<WideSkyDataSourceOptions>;
            return;
        }

        instanceSettings.url = instanceSettings.url.endsWith('/') ? instanceSettings.url : instanceSettings.url + '/';
        this.instanceSettings = instanceSettings;

        APIServerManager.impersonationQueue = new ImpersonationQueue();
    }

    /**
     * Generates a base request header with the provided authorization.
     * @param authorization - The authorization string.
     * @returns The request header with authorization.
     */
    private baseHeader = (authorization: string, impersonationId?: string): RequestHeader => {
        if (!impersonationId) {
            return { Authorization: authorization, 'Content-Type': 'application/json', Accept: 'application/json' };
        }

        return {
            Authorization: authorization,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-IMPERSONATE': impersonationId,
        };
    };

    /**
     * Acquires access by either using the existing token if it's still valid or by refreshing it.
     */
    private async acquireAccess(): Promise<TestDataSourceResponse> {
        const onError = async (errorResponse: DataSourceRejectResponse) => {
            let message: string;

            this.token = undefined;

            // Get the backend app name for white labelling
            const appName =
                ((await getBackendSrv().get('/api/frontend/settings'))?.wideSkyWhitelabeling
                    ?.applicationName as string) ?? 'WideSky';

            switch (errorResponse.status) {
                case HttpCode.BAD_REQUEST:
                    message = errorResponse.data.message || APIServerManager.HTTP_ERROR_MESSAGES[errorResponse.status];
                    break;
                case HttpCode.BAD_GATEWAY:
                // Fall through
                case HttpCode.NOT_FOUND:
                    message = `Not a ${appName} instance`;
                    break;
                default:
                    message = 'Unable to connect the configured URL';
                    break;
            }

            return { status: 'error', message };
        };

        const updateToken = (response: DataSourceTokenResponse) => {
            this.token = {
                accessToken: response.access_token,
                expiresIn: response.expires_in,
                refreshToken: response.refresh_token,
            };

            return { status: 'success', message: 'Data source is working' };
        };

        // No token - Attempt token request
        if (this.token === undefined) {
            const requestTokenData: PasswordRequest = {
                grant_type: 'password',
                username: this.instanceSettings.jsonData.username,
                password: this.instanceSettings.jsonData.password,
            };

            return this.requestWithSecret(requestTokenData).then(updateToken).catch(onError);
        }

        // Token is valid
        if (Date.now() < this.token.expiresIn) {
            return Promise.resolve({ status: 'success', message: 'Data source is working' });
        }

        // Attempt token refresh
        const refreshTokenData: RefreshTokenRequest = {
            grant_type: 'refresh_token',
            refresh_token: this.token.refreshToken,
        };

        return this.requestWithSecret(refreshTokenData).then(updateToken).catch(onError);
    }

    public async testDatasource() {
        return this.acquireAccess();
    }

    public async pointWriteQuery(query: string) {
        const resp = await this.acquireAccess();

        if (resp.status !== 'success') {
            const error: InternalQueryError = {
                message: APIServerManager.HTTP_ERROR_MESSAGES[HttpCode.UNAUTHORIZED],
                additionalInfo: resp.message,
            };

            throw error;
        }

        return this.requestWithToken<DataSourceResponse>(query, ApiSeverEndpoint.POINT_WRITE, true, true);
    }

    private parseError(compressedQuery: string, originalQuery: string, errorLocation?: Readonly<SourceLocation>) {
        if (errorLocation === undefined) {
            return;
        }

        const lastIndexOfNewLine = compressedQuery.matchAll(
            /\) \{|[A-z] \{|[A-z] \}(?!\))|(?<=\}) [A-z]|(?<=\}) \}|"[^"]*"|[A-z] [A-z]/gim
        );

        const arrayOfLines = Array.from(lastIndexOfNewLine)
            .filter(
                (line) => line.at(0)?.at(0)?.startsWith('"') === false && line.at(0)?.at(0)?.endsWith('"') === false
            )
            .map((line) => {
                if (line.at(0)?.at(0) === ' ') {
                    line[0] = `}${line[0]}`;
                }

                return line;
            });

        const errorStart = arrayOfLines.reduce((errorStart: number, line: RegExpMatchArray, index) => {
            const columnLocation = errorLocation.column;
            if (line.index === undefined || columnLocation === undefined) {
                return errorStart;
            }

            if (line.index > columnLocation && errorStart === 0) {
                return index;
            }

            return errorStart;
        }, 0);

        let offset = 0;
        const originalArrayLines = originalQuery.split('\n');
        if (originalArrayLines.at(0) === '{') {
            offset = 1;
        }

        const prevLine = originalArrayLines.at(errorStart - 1 + offset)!;
        const errorLine = originalArrayLines.at(errorStart + offset)!;
        const nextLine = originalArrayLines.at(errorStart + 1 + offset)!;

        const error: string[] = [`  ${prevLine}`, `>>${errorLine}`, `  ${nextLine}`];

        return error.join('\n');
    }

    /**
     * Performs a query to the WideSky API server.
     * @param query - The query string.
     * @returns A promise that resolves with the query response.
     */
    public async postQuery<T>(query: string, originalQuery?: string, hideFromInspector = true): Promise<T> {
        const resp = await this.acquireAccess();

        if (resp.status !== 'success') {
            const error: InternalQueryError<undefined> = {
                message: APIServerManager.HTTP_ERROR_MESSAGES[HttpCode.UNAUTHORIZED],
                additionalInfo: resp.message,
            };

            throw error;
        }

        return this.requestWithToken<DataSourceResponse<T>>(
            { query },
            ApiSeverEndpoint.GRAPHQL,
            hideFromInspector,
            true
        )
            .then((response) => {
                if (response.errors === undefined) {
                    return response.data;
                }

                const error: DataSourceRejectResponse & { graphqlResponse?: T } = {
                    status: HttpCode.BAD_REQUEST,
                    statusText: 'Invalid GraphQL syntax',
                    data: {
                        errors: response.errors,
                    },
                    graphqlResponse: response.data,
                };

                throw error;
            })
            .catch((error: DataSourceRejectResponse & { graphqlResponse?: T }) => {
                let message = `Oops, something went wrong, status code: ${error.status}`;
                let additionalInfo = error.statusText;

                const graphQLErrors = error.data?.errors;
                const firstGraphQLError = graphQLErrors?.at(0);

                if (graphQLErrors !== undefined && firstGraphQLError !== undefined) {
                    // Parse uuids
                    const uuidRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gim;
                    const uuids = Array.from(firstGraphQLError.message.matchAll(uuidRegExp));

                    if (uuids.length === 0) {
                        const argumentRegExp = / \{([^]*)\}\.\n/gim;
                        const graphqlArguments = Array.from(firstGraphQLError.message.matchAll(argumentRegExp));
                        const argumentString = graphqlArguments.at(0)?.at(0);

                        if (argumentString !== undefined) {
                            message = firstGraphQLError.message.replace(argumentString, '. ');
                        } else {
                            message = firstGraphQLError.message.split('\n')[0];
                        }
                    } else if (uuids.length > 3) {
                        message = `[${uuids.length} UUID's] ${firstGraphQLError.message.slice(
                            uuids.at(uuids.length - 1)!.index! + uuids.at(uuids.length - 1)!.at(0)!.length + 1
                        )}`;
                    } else {
                        message = firstGraphQLError.message;
                    }

                    additionalInfo =
                        this.parseError(query, originalQuery || query, firstGraphQLError.locations?.at(0)) ||
                        additionalInfo;

                    if (graphQLErrors.length !== 1) {
                        const errorLocation = firstGraphQLError.locations?.at(0);
                        const similarErrors = graphQLErrors.filter((graphQLError) => {
                            const location = graphQLError.locations?.at(0);
                            return location?.column === errorLocation?.column && location?.line === errorLocation?.line;
                        });

                        if (uuids.length !== 0) {
                            message = `[${similarErrors.length} UUID's] ${firstGraphQLError.message.slice(
                                uuids.at(uuids.length - 1)!.index! + uuids.at(uuids.length - 1)!.at(0)!.length + 1
                            )}`;
                        }
                    }
                } else {
                    message = APIServerManager.HTTP_ERROR_MESSAGES[error.status] || message;
                }

                const formattedError: InternalQueryError<T> = {
                    data: error.graphqlResponse,
                    message,
                    additionalInfo,
                };

                throw formattedError;
            });
    }

    /**
     * Executes a request using the access token to a specific WideSky API server endpoint.
     * @param data - Data to be sent in the request body.
     * @param endpoint - The server endpoint.
     * @param hideFromInspector - Should the query be shown in the query inspector.
     * @param impersonate - Should the query use impersonation.
     * @returns A promise that resolves to the API server response.
     */
    public async requestWithToken<T>(
        data: any,
        endpoint: ApiSeverEndpoint,
        hideFromInspector: boolean,
        impersonate: boolean
    ): Promise<T> {
        let impersonationId: string | undefined;

        if (impersonate && this.instanceSettings.jsonData.asTeamUser) {
            try {
                impersonationId = await APIServerManager.impersonationQueue.add(this);
            } catch (error) {
                // No impersonation found
                const rejectResponse: DataSourceRejectResponse = {
                    status: 403,
                    statusText:
                        typeof error === 'string'
                            ? `Impersonation failed. ${error}`
                            : 'Misconfigured impersonation data. Please contact server administrator.',
                    data: {},
                };
                return Promise.reject(rejectResponse);
            }
        }

        return getBackendSrv().post<T>(`${this.instanceSettings.url}${endpoint}`, data, {
            headers: this.baseHeader(`Bearer ${this.token!.accessToken}`, impersonationId),
            hideFromInspector,
        });
    }

    /**
     * Executes a request with client credentials to the 'oauth2/token' WideSky API server endpoint.
     * @param data - Data to be sent in the request body.
     * @returns A promise that resolves to the API server response.
     */
    private async requestWithSecret(data: RefreshTokenRequest | PasswordRequest): Promise<DataSourceTokenResponse> {
        return getBackendSrv().post<DataSourceTokenResponse>(
            `${this.instanceSettings.url}${ApiSeverEndpoint.OAUTH_2}`,
            data,
            {
                headers: this.baseHeader(
                    `Basic ${btoa(
                        `${this.instanceSettings.jsonData.clientId}:${this.instanceSettings.jsonData.clientSecret}`
                    )}`
                ),
            }
        );
    }
}
