import { css } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Button, CodeEditor, ErrorBoundary, useTheme2 } from '@grafana/ui';
import React, { useState } from 'react';
import { actions } from './state/actions';

import { WideSkyQueryEditorContext, useDispatch, useWideSkyState, WideSkyQueryEditorProps } from './state/context';
import { QueryBuilder } from './query/QueryBuilder';
import { QueryInfoBar } from './query/QueryInfoBar';
import { WideSkyField, WideSkySelect } from './WideSkyVisualComponents';
import { FORMAT_TYPES } from 'types';
import { TableEditor } from 'formats/table/Editor';
import { TableDeprecatingEditor } from 'formats/table_deprecating/Editor';
import { NodeGraphEditor } from 'formats/node_graph/Editor';
import { TimeSeriesEditor } from 'formats/time_series/Editor';
import { parse } from 'graphql';
import { prettifier } from 'utils/graphql_prettifier';

const getStyles = (theme: GrafanaTheme2) => ({
    container: css({
        position: 'relative',
        display: 'block',
    }),

    visualEditor: (editorExpanded: boolean) =>
        css({
            flexGrow: 1,
            height: editorExpanded ? 'auto' : 260,
            overflow: 'scroll',
            '::-webkit-scrollbar-corner': {
                backgroundImage: 'none',
                backgroundColor: 'transparent',
            },
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
            '::-webkit-scrollbar': {
                display: 'none',
            },
        }),

    codeEditor: css({
        flexGrow: 1,
        marginBottom: theme.spacing(1),
    }),

    editorButtons: (bottomSpacing: number) =>
        css({
            position: 'absolute',
            display: 'block',
            right: theme.spacing(1),
            bottom: theme.spacing(bottomSpacing),
            zIndex: 5,
        }),

    button: css({
        marginRight: theme.spacing(0.5),
    }),
});

const queryTypeOptions: Array<SelectableValue<(typeof FORMAT_TYPES)[number]>> = [
    { label: 'Table (Deprecating)', value: 'DEPRECATING_TABLE' },
    { label: 'Table', value: 'TABLE' },
    { label: 'Time Series', value: 'TIME_SERIES' },
    { label: 'Node Graph', value: 'NODE_GRAPH' },
    { label: 'WideSky (Realtime Control)', value: 'REAL_TIME' },
];

export function QueryEditor({ datasource, onRunQuery, onChange, query }: WideSkyQueryEditorProps) {
    return (
        <WideSkyQueryEditorContext datasource={datasource} onRunQuery={onRunQuery} onChange={onChange} query={query}>
            <WideSkyQueryEditorContent />
        </WideSkyQueryEditorContext>
    );
}

function WideSkyQueryEditorContent() {
    const state = useWideSkyState();
    const dispatch = useDispatch();

    const [editorExpanded, setEditorExpanded] = useState<boolean>(true);
    const [textEditMode, setTextEditMode] = useState<boolean>(false);

    const theme = useTheme2();
    const styles = getStyles(theme);

    const renderEditor = () => {
        switch (state.target.formatType) {
            case 'TABLE':
                return TableEditor();
            case 'DEPRECATING_TABLE':
                return TableDeprecatingEditor();
            case 'NODE_GRAPH':
                return NodeGraphEditor();
            case 'TIME_SERIES':
                return TimeSeriesEditor();
            case 'REAL_TIME':
            // Fall through
            default:
                return null;
        }
    };

    return (
        <>
            <div className={styles.container}>
                <div className={textEditMode ? styles.codeEditor : styles.visualEditor(editorExpanded)}>
                    {textEditMode && (
                        <CodeEditor
                            value={state.target.query || ''}
                            showMiniMap={true}
                            showLineNumbers={true}
                            language="graphql"
                            monacoOptions={{
                                scrollBeyondLastLine: false,
                                scrollbar: {
                                    alwaysConsumeMouseWheel: false,
                                    vertical: editorExpanded ? 'hidden' : 'auto',
                                },
                            }}
                            height={
                                editorExpanded ? Math.max(state.target.query.split('\n').length * (250 / 13), 250) : 250
                            }
                            onBlur={(query: string) => dispatch(actions.runQuery(query))}
                        />
                    )}
                    {!textEditMode && (
                        <ErrorBoundary>
                            {({ error }) => {
                                if (error === null) {
                                    return <QueryBuilder />;
                                }

                                setTextEditMode(true);
                                return null;
                            }}
                        </ErrorBoundary>
                    )}
                    <div className={styles.editorButtons(textEditMode ? 1 : 2)}>
                        <Button
                            className={styles.button}
                            icon={editorExpanded ? 'angle-up' : 'angle-down'}
                            variant="secondary"
                            onClick={() => setEditorExpanded(!editorExpanded)}
                        />
                        <Button
                            className={styles.button}
                            icon="pen"
                            variant="secondary"
                            onClick={() => {
                                if (!textEditMode) {
                                    let query = state.target.query;

                                    // For backwards compatibility - old datasource does not
                                    // wrap query in curly braces
                                    if (query.trimStart().at(0) !== '{') {
                                        query = `{${query}}`;
                                    }

                                    const document = parse(query);
                                    state.target.query = prettifier(document);
                                }

                                setTextEditMode(!textEditMode);
                            }}
                        />
                    </div>
                </div>
            </div>
            <WideSkyField label="Format As">
                <WideSkySelect
                    value={state.target.formatType}
                    options={queryTypeOptions}
                    onChange={(e) => dispatch(actions.formatTypeChanged(e.value!))}
                />
            </WideSkyField>
            {renderEditor()}
            <QueryInfoBar />
        </>
    );
}
