import { SelectableValue } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { APIServerManager } from 'api_server_manager';
import {
    assertInputObjectType,
    assertListType,
    GraphQLInputType,
    isInputObjectType,
    isInputType,
    Kind,
    NameNode,
    ValueNode,
} from 'graphql';
import _ from 'lodash';
import { InternalQueryError, TagKind, WideSkyQueryEditorState } from 'types';
import {
    allTagsQuery,
    GraphQLReferencesResponse,
    GraphQLReferenceValuesResponse,
    GraphQLTagSummaryResponse,
    GraphQLValuesResponse,
    referencesQuery,
    referenceValuesQuery,
    tagSummaryQuery,
    valuesQuery,
} from 'utils/graphql_queries';
import { replaceVariables } from 'utils/replace_variables';
import { QueryNode, UNDEFINED_FILTER_VALUE } from './types';

export const isArgumentDefault = (
    value: ValueNode,
    type: GraphQLInputType,
    defaultValue: unknown
): boolean => {
    switch (value.kind) {
        case Kind.ENUM:
        // Fall through
        case Kind.STRING:
        // Fall through
        case Kind.FLOAT:
        // Fall through
        case Kind.BOOLEAN:
            return value.value === defaultValue;

        case Kind.INT:
            return parseInt(value.value, 10) === defaultValue;

        case Kind.OBJECT: {
            const fields = assertInputObjectType(type).getFields();
            for (const field in fields) {
                const queryArgument = value.fields?.find(
                    (valueField) => valueField.name.value === field
                );

                if (!queryArgument) {
                    return false;
                }

                if (
                    !isArgumentDefault(
                        queryArgument.value,
                        fields[field].type,
                        fields[field].defaultValue
                    )
                ) {
                    return false;
                }
            }

            return true;
        }
        case Kind.VARIABLE:
            return false;

        case Kind.LIST: {
            const listType = assertListType(type).ofType;
            return !value.values.every(
                (listValue) =>
                    (isInputObjectType(listType) || isInputType(listType)) &&
                    !isArgumentDefault(listValue, listType, defaultValue)
            );
        }

        default:
            console.error(`Unhandled queryValue.Kind in 'isArgumentDefault': ${value.kind}`);
            return false;
    }
};

export const argumentAsString = (value: ValueNode, name?: NameNode): string => {
    const namePrefix = name ? `${name.value}:` : '';

    switch (value.kind) {
        case Kind.BOOLEAN:
        // Fall through
        case Kind.ENUM:
        // Fall through
        case Kind.STRING:
        // Fall through
        case Kind.FLOAT:
        // Fall through
        case Kind.INT:
            return `${namePrefix} ${value.value}`;

        case Kind.OBJECT:
            return `${namePrefix} { ${value.fields
                .map((field) => argumentAsString(field.value, field.name))
                .join(', ')} }`;

        case Kind.VARIABLE:
            return `${namePrefix} $${value.name.value}`;

        case Kind.LIST: {
            return `${namePrefix} [ ${value.values.map((listValue) => argumentAsString(listValue)).join(', ')} ]`;
        }
        default:
            console.error(`Unhandled queryValue.Kind in 'argumentAsString': ${value.kind}`);
            return '';
    }
};

export const splitHaystackFilter = (filter?: string): string[] => {
    if (filter === undefined) {
        return [];
    }

    const result: string[] = [];
    let currentIndex = 0;

    while (currentIndex < filter.length) {
        // Check for brackets
        if (filter.at(currentIndex) === '(') {
            let bracketCount = 1;

            // Extract the next segment until the next quote
            let nextIndex = currentIndex + 1;
            while (nextIndex < filter.length && bracketCount !== 0) {
                if (filter.at(nextIndex) === '(') {
                    bracketCount++;
                }

                if (filter.at(nextIndex) === ')') {
                    bracketCount--;
                }

                nextIndex++;
            }

            if (filter.at(nextIndex) === ')') {
                nextIndex++;
            }

            const part = filter.slice(currentIndex, nextIndex).trim();
            result.push(part);
            currentIndex = nextIndex;
            continue;
        }

        // Check for strings
        if (filter.at(currentIndex) === '"') {
            // Extract the next segment until the next quote
            let nextIndex = currentIndex + 1;
            while (nextIndex < filter.length && filter.at(nextIndex) !== '"') {
                nextIndex++;
            }

            if (filter.at(nextIndex) === '"') {
                nextIndex++;
            }

            const part = filter.slice(currentIndex, nextIndex).trim();
            result.push(part.slice(1, part.length - 1));

            currentIndex = nextIndex;
            continue;
        }

        let matched = false;

        // Check for conjunctions
        for (const conjunction of CONJUNCTIONS) {
            if (filter.startsWith(conjunction, currentIndex)) {
                result.push(conjunction);
                currentIndex += conjunction.length - 1;
                matched = true;
                break;
            }
        }

        if (matched) {
            continue;
        }

        const operatorsMap = OPERATORS.flatMap((operator) => operator).reverse();

        // Check for operators
        for (const operator of operatorsMap) {
            if (filter.startsWith(operator, currentIndex)) {
                result.push(operator);
                currentIndex += operator.length;
                matched = true;
                break;
            }
        }

        if (matched) {
            continue;
        }

        // Extract the next segment until the next separator
        let nextIndex = currentIndex;

        while (
            nextIndex < filter.length &&
            !([...CONJUNCTIONS, ...operatorsMap] as const).some((separator) =>
                filter.startsWith(separator, nextIndex)
            )
        ) {
            nextIndex++;
        }

        let part = filter.slice(currentIndex, nextIndex).trim();
        if (part.charAt(0) === '/' && part.charAt(part.length - 1) === '/') {
            part = part.slice(1, part.length - 1);
        }

        if (part.at(0) === '(') {
            nextIndex -= part.length;
            currentIndex = nextIndex;
            continue;
        }

        result.push(part);
        currentIndex = nextIndex;
    }

    return result.filter((part) => part !== '');
};
export const isOperator = (filter?: string): filter is OperatorType =>
    OPERATORS.some((operatorType) => operatorType.some((operator) => operator === filter));

export const isConjunction = (filter?: string): filter is ConjunctionType =>
    CONJUNCTIONS.some((conjunction) => conjunction === filter);

export const rebuildFilterString = (
    filters: string[],
    tagKindMap: Map<string, TagKind>,
    replaceTemplates: boolean
) => {
    let combiningOperator: OperatorType | ConjunctionType | undefined;
    let filterKind: TagKind | undefined;
    const filterString = filters.reduce((filterString, filter, index) => {
        if (replaceTemplates) {
            filter = replaceVariables(filter);
        }

        if (isOperator(filter) || isConjunction(filter)) {
            combiningOperator = filter;
            return `${filterString}${filter}`;
        }

        if (isOperator(combiningOperator) && filterKind !== 'Number' && filterKind !== 'Boolean') {
            if (combiningOperator === '=~') {
                filter = `/${filter}/`;
            } else if (
                (combiningOperator === '==' || combiningOperator === '!=') &&
                filter.at(0) !== '@'
            ) {
                filter = `\\"${filter}\\"`;
            }
        }

        const seperator = combiningOperator !== undefined || index === 0 ? '' : ' ';

        combiningOperator = undefined;
        filterKind = getFilterKind(tagKindMap, filter);
        return `${filterString}${seperator}${filter}`;
    }, '');

    return filterString;
};

export const changeValueAtFilterIndex = (
    filters: string[],
    index: number,
    insertValue: boolean,
    value?: string
) => {
    const filtersBeforeValue = filters.slice(0, index);
    const currentValue = filters.at(index);
    const filtersAfterValue = filters.slice(index + 1);

    if (value === REMOVE_VALUE || value === undefined) {
        if (!isOperator(currentValue) && !isConjunction(currentValue)) {
            return filtersBeforeValue;
        }

        const nextConjuctionIndex = filtersAfterValue.findIndex(isConjunction);
        if (nextConjuctionIndex === -1) {
            return filtersBeforeValue;
        }

        return [...filtersBeforeValue, ...filtersAfterValue.slice(nextConjuctionIndex)];
    }

    if (insertValue) {
        if (currentValue === undefined) {
            return [...filtersBeforeValue, value];
        }

        return [...filtersBeforeValue, value, currentValue, ...filtersAfterValue];
    }

    const wasOperator = isOperator(currentValue);
    const wasConjunction = isConjunction(currentValue);

    if (wasOperator || wasConjunction) {
        const nowOperator = isOperator(value);
        const nowConjunction = isConjunction(value);

        if (wasConjunction && nowOperator) {
            return [
                ...filtersBeforeValue,
                value,
                UNDEFINED_FILTER_VALUE,
                currentValue,
                ...filtersAfterValue,
            ];
        }

        if (wasOperator && nowConjunction) {
            const nextConjuctionIndex = filtersAfterValue.findIndex(isConjunction);
            if (nextConjuctionIndex === -1) {
                return filtersBeforeValue;
            }

            return [
                ...filtersBeforeValue,
                value,
                '',
                ...filtersAfterValue.slice(nextConjuctionIndex),
            ];
        }
    }

    return [...filtersBeforeValue, value, ...filtersAfterValue];
};

const CONJUNCTIONS = [' or not ', ' and not ', ' or ', ' and '] as const;
export type ConjunctionType = (typeof CONJUNCTIONS)[number];

const BOOLEAN_OPERATORS = ['==', '!='] as const;
const NUMBER_OPERATORS = ['==', '!=', '>', '>=', '<', '<=', '=~'] as const;
const REFERENCE_OPERATORS = ['==', '!=', '=~', '->'] as const;
const STRING_OPERATORS = ['==', '!=', '=~'] as const;

const OPERATORS = [
    NUMBER_OPERATORS,
    BOOLEAN_OPERATORS,
    REFERENCE_OPERATORS,
    STRING_OPERATORS,
] as const;
export type OperatorType = (typeof OPERATORS)[number][number];

export const conjunctionOptions = CONJUNCTIONS.map<SelectableValue<ConjunctionType>>(
    (conjunction) => ({
        label: conjunction,
        value: conjunction,
    })
).reverse();

export const REMOVE_VALUE = '-- Remove --';

export const REMOVE_OPTION: SelectableValue<typeof REMOVE_VALUE> = {
    label: REMOVE_VALUE,
    value: REMOVE_VALUE,
};

export const operatorOptions = (kind?: TagKind) => {
    let operators: (typeof OPERATORS)[number];

    switch (kind) {
        case 'Boolean':
            operators = BOOLEAN_OPERATORS;
            break;
        case 'NA':
        // Fall through
        case 'Number':
            operators = NUMBER_OPERATORS;
            break;
        case 'Reference':
            operators = REFERENCE_OPERATORS;
            break;
        case 'String':
        // Fall through
        default:
            operators = STRING_OPERATORS;
            break;
    }

    return operators.map<SelectableValue<(typeof operators)[number]>>((value) => ({
        label: value,
        value: value,
    }));
};

export const setNextSuggestions = async (
    state: Pick<WideSkyQueryEditorState, 'datasource' | 'tags' | 'tagKindMap'>,
    filters: string[],
    index: number,
    setSuggestions: React.Dispatch<React.SetStateAction<string[]>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const previousFilter = filters.at(index - 1);

    const getSuggestions = async () => {
        if (index === 0) {
            return state.tags;
        }

        const filter = rebuildFilterString(filters.slice(0, index - 1), state.tagKindMap, true);

        if (!isOperator(previousFilter)) {
            const tags = await getFilterTags(state.datasource.apiServerManager, filter);
            return tags.map((tag) => tag.name);
        }

        const tag = filters.at(index - 2);
        const possibleRefOperator = filters.at(index - 3);

        if (!tag) {
            console.warn('Attempted to create a query for an empty tag');
            return [];
        }

        if (previousFilter === '->') {
            return await getFilterReferences(
                state.datasource.apiServerManager,
                state.tagKindMap,
                filters,
                index,
                tag
            );
        }

        // previousFilter will be anything but ->, e.g., ->tag==___
        if (possibleRefOperator === '->') {
            const referencePath = filters.at(index - 4);
            if (referencePath !== undefined) {
                return await getFilterReferenceValues(
                    state.datasource.apiServerManager,
                    filter,
                    referencePath,
                    tag
                );
            }
        }

        return await getFilterValues(state.datasource.apiServerManager, filter, tag);
    };

    const querySuggestions = await getSuggestions();
    const templateVariables = getTemplateSrv().getVariables();
    const firstValue = querySuggestions.at(0);

    let suggestions = [
        ...templateVariables.map((variable) => `$${variable.name}`),
        ...querySuggestions.map((suggestion) => `${suggestion}`),
    ];

    const uuidRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gim;
    if (
        previousFilter !== '=~' &&
        firstValue !== undefined &&
        firstValue.match(uuidRegExp) !== null
    ) {
        suggestions = suggestions.map((suggestion) => `@${suggestion}`);
    }

    setSuggestions(suggestions);
    setIsLoading(false);
};

type HaystackTag = {
    name: string;
    kind: TagKind;
};

export const getFilterTags = (
    apiServerManager: APIServerManager,
    filter: string,
    onError?: (error: InternalQueryError) => void
): Promise<HaystackTag[]> => {
    return apiServerManager
        .postQuery<GraphQLTagSummaryResponse>(
            filter === 'id' ? allTagsQuery : tagSummaryQuery(filter)
        )
        .then((response) => response.haystack.search.tagSummary)
        .catch((error) => {
            if (onError) {
                onError(error);
            }

            return [];
        });
};

const getFilterValues = async (apiServerManager: APIServerManager, filter: string, tag: string) => {
    const query = valuesQuery(filter, tag);
    const values = await apiServerManager
        .postQuery<GraphQLValuesResponse>(query)
        .then(
            (response) =>
                response.haystack.search?.tagSummary
                    .at(0)
                    ?.values.flatMap((value) => String(value)) || []
        )
        .catch((error) => {
            console.error(error);
            return [] as string[];
        });

    return values;
};

const getFilterReferences = async (
    apiServerManager: APIServerManager,
    tagKindMap: any,
    filters: string[],
    index: number,
    tag: string
) => {
    let filter: string | undefined = undefined;
    let path: string | undefined = undefined;

    for (let i = index - 1; i > 0; i--) {
        if (isConjunction(filters.at(i)) && i > 2) {
            path = filters.slice(i + 1, index - 1).join('');
            filter = rebuildFilterString(filters.slice(0, i - 2), tagKindMap, true);
            break;
        }
    }

    if (path === undefined || filter === undefined) {
        path = tag;
        filter = rebuildFilterString(filters.slice(0, index - 1), tagKindMap, true);
    }

    const query = referencesQuery(filter, path);
    const references = await apiServerManager
        .postQuery<GraphQLReferencesResponse>(query)
        .then(
            (response) =>
                response.haystack.search?.ref?.tagSummary.flatMap((value) => value.name) || []
        )
        .catch((error) => {
            console.error(error);
            return [] as string[];
        });

    return references;
};

const getFilterReferenceValues = async (
    apiServerManager: APIServerManager,
    filter: string,
    path: string,
    tag: string
) => {
    const query = referenceValuesQuery(filter, path, tag);
    const references = await apiServerManager
        .postQuery<GraphQLReferenceValuesResponse>(query)
        .then(
            (response) =>
                response.haystack.search?.ref?.tagSummary
                    .at(0)
                    ?.values.flatMap((value) => String(value)) || []
        )
        .catch((error) => {
            console.error(error);
            return [];
        });

    return references;
};

export const getOperator = (filters: string[], index: number): OperatorType | undefined => {
    const possibleOperator = filters.at(index);

    if (isOperator(possibleOperator)) {
        return possibleOperator;
    }

    return;
};

export const getConjunction = (filters: string[], index: number): ConjunctionType | undefined => {
    const possibleConjunction = filters.at(index);

    if (isConjunction(possibleConjunction)) {
        return possibleConjunction;
    }

    return;
};

export const getFilterKind = (tagKindMap: Map<string, TagKind>, filter?: string) => {
    if (filter === undefined) {
        return undefined;
    }

    return tagKindMap.get(filter);
};

export const resolveNodeName = (node: QueryNode, complete: boolean) => {
    if (node.kind === Kind.INLINE_FRAGMENT) {
        const name = node.typeCondition === undefined ? '' : node.typeCondition.name.value;
        return complete ? `... on ${name}` : name;
    }

    if (node.alias === undefined) {
        return node.name.value;
    }

    return complete ? `${node.alias.value}:${node.name.value}` : node.name.value;
};
