import { css, cx } from '@emotion/css';
import React from 'react';
import SVG from 'react-inlinesvg';

import { colorManipulator, GrafanaTheme2, ScopedVars } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

import { Bounds, NodeDatum } from 'types';
import { getTemplateSrv } from '@grafana/runtime';

const getStyles = (theme: GrafanaTheme2, scale: number) => ({
    container: css({
        overflow: 'visible',
    }),

    wrapper: (bounds: Bounds) =>
        css({
            boxShadow: `rgba(24, 26, 27, 0.2) 0px ${4 / scale}px ${8 / scale}px`,
            padding: 10 / scale,
            background: theme.components.tooltip.background,
            borderRadius: theme.shape.borderRadius(2),
            display: 'flex-row',
            transform: `translateX(${bounds.right - bounds.left}px) translateY(-100%)`,
        }),

    header: css({
        background: theme.colors.background.secondary,
        alignItems: 'center',
        alignContent: 'center',
        display: 'flex',
        paddingBottom: theme.spacing(1 / scale),
        flexGrow: 1,
        justifyContent: 'end',
    }),

    title: css({
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: 12 / scale,
        overflow: 'hidden',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flexGrow: 1,
    }),

    icon: css({
        height: 1 / scale,
        width: 1 / scale,
    }),

    button: css({
        zIndex: 0,
        position: 'relative',
        margin: `0 ${theme.spacing(0.5 / scale)} 0 0`,
        boxShadow: 'none',
        border: 'none',
        display: 'inline-flex',
        background: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        color: theme.colors.text.primary,

        '&:before': {
            zIndex: -1,
            position: 'absolute',
            opacity: 0,
            width: `${(15 + theme.spacing.gridSize) / scale}px`,
            height: `${(15 + theme.spacing.gridSize) / scale}px`,
            borderRadius: 10 / scale,
            content: '""',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionProperty: 'opacity',
        },

        '&:hover': {
            '&:before': {
                backgroundColor: colorManipulator.alpha(theme.colors.text.primary, 0.12),
                opacity: 1,
            },
        },
    }),

    infoWrap: css({
        padding: theme.spacing(1 / scale),
        background: 'transparent',
        border: 'solid rgb(236, 236, 236)',
        borderWidth: 1 / scale,
        flexGrow: 1,

        tr: {
            background: theme.colors.background.primary,
            borderBottom: `solid ${theme.colors.border.weak}`,
            borderWidth: 1 / scale,
            '&:last-child': {
                borderBottom: 'none',
            },
        },

        'tr:nth-child(2n)': {
            background: 'transparent',
        },
    }),

    text: css({
        fontSize: 10 / scale,
    }),

    fieldName: css({
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: 16 / scale,
        paddingRight: 16 / scale,
        paddingBottom: 2 / scale,
        paddingTop: 2 / scale,
    }),

    link: css({
        color: theme.colors.text.link,
        cursor: 'pointer',
    }),
});

export interface Props {
    node?: NodeDatum;
    isClicked: boolean;
    onClose: () => void;
    scale: number;
    header?: string;
}

export interface DisplayValue {
    name: string;
    value: unknown;
    valueString: string;
}

let iconRoot: string | undefined;

export function getIconRoot(): string {
    if (iconRoot) {
        return iconRoot;
    }

    // @ts-ignore
    const grafanaPublicPath = typeof window !== 'undefined' && window.__grafana_public_path__;
    if (grafanaPublicPath) {
        iconRoot = grafanaPublicPath + 'img/icons/';
    } else {
        iconRoot = 'public/img/icons/';
    }

    return iconRoot;
}

export const NodeHoverView = (props: Props) => {
    const { node, isClicked, scale, header, onClose } = props;
    const theme = useTheme2();
    const styles = getStyles(theme, scale);

    if (node === undefined || node.showTooltip === false) {
        return null;
    }

    return (
        <foreignObject
            x={node.bounds.left + 5}
            y={node.bounds.top}
            width={(node.bounds.right - node.bounds.left) / scale}
            className={styles.container}
        >
            <div className={styles.wrapper(node.bounds)}>
                {(header || isClicked) && (
                    <div className={styles.header}>
                        {header && <span className={styles.title}>{header}</span>}
                        {isClicked && (
                            <button type="button" onClick={onClose} className={styles.button}>
                                <SVG
                                    src={`${getIconRoot()}unicons/times.svg`}
                                    width={15 / scale}
                                    height={15 / scale}
                                    className={css({
                                        display: 'inline-block',
                                        fill: 'currentColor',
                                        flexShrink: 0,
                                        label: 'Icon',
                                        lineHeight: 0,
                                        verticalAlign: 'baseline',
                                    })}
                                />
                            </button>
                        )}
                    </div>
                )}
                <table className={styles.infoWrap}>
                    <tbody>
                        <tr>
                            <th className={cx(styles.text, styles.fieldName)}>id:</th>
                            <td className={styles.text}>{node.id}</td>
                        </tr>
                        <tr>
                            <th className={cx(styles.text, styles.fieldName)}>Primary Text:</th>
                            <td className={styles.text}>{node.primaryText}</td>
                        </tr>
                        <tr>
                            <th className={cx(styles.text, styles.fieldName)}>Secondary Text:</th>
                            <td className={styles.text}>{node.secondaryText}</td>
                        </tr>

                        {node.url && (
                            <tr>
                                <th className={cx(styles.text, styles.fieldName)}>Link</th>
                                <td className={styles.text}>
                                    <UrlLabel node={node} scale={scale} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </foreignObject>
    );
};

function UrlLabel({ node, scale }: { node: NodeDatum; scale: number }) {
    const theme = useTheme2();
    const styles = getStyles(theme, scale);

    const detailVars = node.detail.reduce((detailVars, detail) => {
        detailVars[`__node_detail_${detail.name}`] = { text: 'Field', value: detail.value };
        return detailVars;
    }, {} as ScopedVars);

    const scopedVars: ScopedVars = {
        __node_id: {
            text: 'Field',
            value: node.id,
        },
        __node_group_id: {
            text: 'Field',
            value: node.groupId,
        },
        __node_primary_text: {
            text: 'Field',
            value: node.primaryText,
        },
        __node_secondary_text: {
            text: 'Field',
            value: node.secondaryText,
        },
        ...detailVars,
    };

    const url = getTemplateSrv().replace(node.url, scopedVars);

    const onClick = () => {
        if (node.openInNewTab) {
            window.open(url);
        } else {
            window.location.href = url;
        }
    };

    return (
        <span onClick={onClick} className={cx(styles.text, styles.link)}>
            {node.urlTitle || url}
        </span>
    );
}
