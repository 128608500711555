import { GraphQLResponse, TagKind } from 'types';

export const DEFAULT_QUERY = `\
{
  haystack {
    search(filter: "", limit: 30) {
      entity {
        description
        history(rangeAbsolute: {start: "\${__from:date:iso}", end: "\${__to:date:iso}"}) {
          aggregate(function: MEAN, allEntities: false, groupBy: {groupByUnits: \$__intervalDuration, groupBySize: \$__intervalNum, fill: NONE}) {
            timeSeries {
              dataPoints {
                time
                value
              }
            }
          }
        }
      }
    }
  }      
}`;

const queryLimit = 0;

export const allTagsQuery = `{ haystack { search(filter: "id", limit: 0) { tagSummary { name kind } } } }`;

export const tagSummaryQuery = (filter: string) =>
    `{ haystack { search(filter: \"${filter}\", limit: ${queryLimit}) { tagSummary { name kind } } } }`;

export const valuesQuery = (filter: string, tag: string): string =>
    `{ haystack { search(filter: \"${filter}\", limit: ${queryLimit}) { tagSummary(tagFilter:[\"${tag}\"]) { values(distinct:true) } } } }`;

export const referencesQuery = (filter: string, path: string): string =>
    `{ haystack { search(filter: \"${filter}\", limit: ${queryLimit}) { ref(path: \"${path}\") { tagSummary { name } } } } }`;

export const referenceValuesQuery = (filter: string, path: string, tag: string): string =>
    `{ haystack { search(filter: \"${filter}\", limit: ${queryLimit}) { ref(path: \"${path}\") { tagSummary(tagFilter:[\"${tag}\"]) { values(distinct:true) } } } } }`;

export interface GraphQLTagSummaryResponse extends GraphQLResponse {
    haystack: {
        search: {
            tagSummary: [{ kind: TagKind; name: string }];
        };
    };
}

export interface GraphQLValuesResponse extends GraphQLResponse {
    haystack: {
        search?: {
            tagSummary: [
                {
                    values: Array<number | string>;
                }
            ];
        };
    };
}

export interface GraphQLReferencesResponse extends GraphQLResponse {
    haystack: {
        search?: {
            ref?: {
                tagSummary: [
                    {
                        name: string[];
                    }
                ];
            };
        };
    };
}

export interface GraphQLReferenceValuesResponse extends GraphQLResponse {
    haystack: {
        search?: {
            ref?: {
                tagSummary: [
                    {
                        values: Array<number | string>;
                    }
                ];
            };
        };
    };
}

export const readByFilterQuery = (filter: string, limit: number): string => `
{
    "meta": {
        "ver": "2.0"
    },
    "cols": [
        { "name": "filter" },
        { "name": "limit" }
    ],
    "rows": [
        { 
            "filter": "s:${filter}",
            "limit": "n:${limit}" 
        }
    ]
}`;

export const pointWriteQuery = (id: string, value: string | boolean, useAMQP: boolean): string => `
{
    "meta": {
        "ver": "2.0",
        "useAMQP": ${String(useAMQP)}
    },
    "cols": [
        { "name": "id" },
        { "name": "level" },
        { "name": "who" },
        { "name": "val" }
    ],
    "rows": [
        {
            "id": "r:${id}",
            "level": "n:17",
            "who": "s:Widesky-Grafana-datasource",
            "val": ${value}
        }
    ]
}`;
