import React from 'react';

import { css } from '@emotion/css';
import { VariableSort } from '@grafana/data';

import { actions } from 'components/state/actions';
import { useDispatch, useWideSkyState } from 'components/state/context';
import { SortSelect, WideSkyField, WideSkyInput } from 'components/WideSkyVisualComponents';
import { Config } from './config';

const getStyles = () => ({
    container: css({
        display: 'flex',
    }),
});

export function NodeGraphEditor() {
    const state = useWideSkyState();
    const dispatch = useDispatch();

    const config = state.target.config as Config | undefined;
    const styles = getStyles();

    if (config?.layers === undefined) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div>
                <WideSkyField label={'Group Name'} />
                {config.layers.map((layer, index) => (
                    <WideSkyField key={index} label={layer.alias} />
                ))}
            </div>

            <div>
                <WideSkyField label={'Primary Text'} grow={true} />
                {config.layers.map((layer, index) => (
                    <WideSkyInput
                        key={index}
                        type={'text'}
                        placeholder={layer.primaryText || '{name}'}
                        value={layer.primaryText}
                        onBlur={(e) => {
                            layer.primaryText = e.target.value;
                            dispatch(actions.editorChanged());
                        }}
                    />
                ))}
            </div>

            <div>
                <WideSkyField label={'Secondary Text'} grow={true} />
                {config.layers.map((layer, index) => (
                    <WideSkyInput
                        key={index}
                        type={'text'}
                        placeholder={layer.secondaryText || '{description}'}
                        value={layer.secondaryText}
                        onBlur={(e) => {
                            layer.secondaryText = e.target.value;
                            dispatch(actions.editorChanged());
                        }}
                    />
                ))}
            </div>

            <div>
                <WideSkyField label={'Sort Method'} grow={true} />
                {config.layers.map((layer, index) => (
                    <SortSelect
                        key={index}
                        sort={layer.sort || VariableSort.disabled}
                        onChange={(e) => {
                            if (e.value === undefined) {
                                return;
                            }

                            layer.sort = e.value;
                            dispatch(actions.editorChanged());
                        }}
                    />
                ))}
            </div>

            {config.layers.some((layer) => layer.sort !== VariableSort.disabled && layer.sort !== undefined) && (
                <div>
                    <WideSkyField label={'Sort Alias'} grow={true} />
                    {config.layers.map((layer) => (
                        <>
                            {layer.sort !== VariableSort.disabled && layer.sort !== undefined && (
                                <WideSkyInput
                                    type={'text'}
                                    placeholder={layer.sortAlias || '{name}'}
                                    value={layer.sortAlias}
                                    onBlur={(e) => {
                                        layer.sortAlias = e.target.value;
                                        dispatch(actions.editorChanged());
                                    }}
                                />
                            )}
                        </>
                    ))}
                </div>
            )}
        </div>
    );
}
