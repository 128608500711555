import { useEffect, useRef, useState, useCallback } from 'react';
import { Position, Bounds } from 'types';

/**
 * A custom hook for implementing panning functionality within a bounded area.
 *
 * This hook manages the position state for panning and provides a ref to be attached
 * to the SVG element intended for panning. It handles mouse and touch events to start,
 * update, and stop panning. The hook ensures that the panning is restricted within the
 * specified view bounds and updates the position state accordingly.
 *
 * @param {number} scale - The current scale of the zoom, used to adjust panning sensitivity.
 * @param {Bounds} viewBounds - The boundaries within which panning is allowed, defined by top,
 *                              right, bottom, and left bounds.
 * @returns An object containing the current position state, a boolean indicating if panning is
 *          active, and a ref to attach to the SVG element for panning.
 */
export function usePanning(scale: number, viewBounds: Bounds, initialPositon?: Position) {
    const isPanning = useRef(false);
    const frame = useRef(0);
    const panRef = useRef<SVGSVGElement>(null);
    const [positionState, setPositon] = useState<Position>(initialPositon || { x: 0, y: 0 });

    const startMousePosition = useRef(initialPositon || { x: 0, y: 0 });
    const prevPosition = useRef(initialPositon || { x: 0, y: 0 });
    const currentPosition = useRef(initialPositon || { x: 0, y: 0 });

    const [panning, setPanning] = useState<boolean>(false);

    useEffect(() => {
        const startPanning = (event: Event) => {
            if (isPanning.current) {
                return;
            }

            isPanning.current = true;
            startMousePosition.current = getEventXY(event);
            prevPosition.current = { ...currentPosition.current };
            setPanning(true);
            bindEvents();
        };

        const stopPanning = () => {
            if (!isPanning.current) {
                return;
            }

            isPanning.current = false;
            setPanning(false);
            unbindEvents();
        };

        const onPanStart = (event: Event) => {
            startPanning(event);
            onPan(event);
        };

        const onPan = (event: Event) => {
            cancelAnimationFrame(frame.current);

            frame.current = requestAnimationFrame(() => {
                if (!panRef.current) {
                    return;
                }

                const pos = getEventXY(event);
                const dxScaled = (pos.x - startMousePosition.current.x) / scale;
                const dyScaled = (pos.y - startMousePosition.current.y) / scale;

                currentPosition.current = {
                    x: inBounds(prevPosition.current.x + dxScaled, viewBounds.left, viewBounds.right),
                    y: inBounds(prevPosition.current.y + dyScaled, viewBounds.top, viewBounds.bottom),
                };
                setPositon(() => currentPosition.current);
            });
        };

        const bindEvents = () => {
            document.addEventListener('mousemove', onPan);
            document.addEventListener('mouseup', stopPanning);
            document.addEventListener('touchmove', onPan);
            document.addEventListener('touchend', stopPanning);
        };

        const unbindEvents = () => {
            document.removeEventListener('mousemove', onPan);
            document.removeEventListener('mouseup', stopPanning);
            document.removeEventListener('touchmove', onPan);
            document.removeEventListener('touchend', stopPanning);
        };

        const ref = panRef.current;

        if (!ref) {
            return;
        }

        ref.addEventListener('mousedown', onPanStart);
        ref.addEventListener('touchstart', onPanStart, { passive: true });

        return () => {
            if (!ref) {
                return;
            }

            ref.removeEventListener('mousedown', onPanStart);
            ref.removeEventListener('touchstart', onPanStart);
        };
    }, [scale, viewBounds]);

    const onZoomToFit = useCallback((position?: Position) => {
        currentPosition.current = position || { x: 0, y: 0 };
        setPositon(position || { x: 0, y: 0 });
    }, []);

    return {
        position: {
            x: inBounds(positionState.x, viewBounds.left, viewBounds.right),
            y: inBounds(positionState.y, viewBounds.top, viewBounds.bottom),
        },
        isPanning: panning,
        onZoomToFitPosition: onZoomToFit,
        ref: panRef,
    };
}

function inBounds(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}

function getEventXY(event: Event): { x: number; y: number } {
    if ((event as any).changedTouches) {
        const e = event as TouchEvent;
        return { x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY };
    }

    const e = event as MouseEvent;
    return { x: e.clientX, y: e.clientY };
}

/**
 * A custom hook for implementing zoom functionality on a component.
 *
 * This hook manages the zoom scale state and provides handlers for zooming in, zooming out,
 * and handling wheel events for dynamic zoom adjustments. It enforces a minimum and maximum
 * zoom scale to prevent excessive zooming in or out. The hook is designed to be used with
 * a ref attached to the element you wish to apply zoom functionality to.
 *
 * @returns An object containing zoom handlers, zoom scale state, flags indicating if the
 *          current scale is at its minimum or maximum, and a ref to attach to the element.
 */

const MAX_SCALE = 100;
const MIN_SCALE = 0.001;
const SCALE_FACTOR = 1.25;

export function useZoom(intialScale?: number) {
    const ref = useRef<HTMLElement | null>(null);
    const [scale, setScale] = useState(intialScale || 1);

    const onStepUp = useCallback(() => {
        if (scale < (MAX_SCALE ?? Infinity)) {
            setScale(scale * SCALE_FACTOR);
        }
    }, [scale]);

    const onStepDown = useCallback(() => {
        if (scale > (MIN_SCALE ?? -Infinity)) {
            setScale(scale / SCALE_FACTOR);
        }
    }, [scale]);

    const onZoomToFit = useCallback((newScale: number) => {
        setScale(newScale);
    }, []);

    const onWheel = useCallback(
        (wheelEvent: WheelEvent) => handleWheelEvent(wheelEvent, scale, setScale),
        [scale, setScale]
    );

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const zoomRef = ref.current;

        zoomRef.addEventListener('wheel', onWheel, { passive: false });
        return () => {
            if (!zoomRef) {
                return;
            }

            zoomRef.removeEventListener('wheel', onWheel);
        };
    }, [onWheel]);

    return {
        onStepUp,
        onStepDown,
        onZoomToFitScale: onZoomToFit,
        scale: Math.max(Math.min(scale, MAX_SCALE), MIN_SCALE),
        isMax: scale >= MAX_SCALE,
        isMin: scale <= MIN_SCALE,
        ref,
    };
}

function handleWheelEvent(
    wheelEvent: WheelEvent,
    scale: number,
    setScale: React.Dispatch<React.SetStateAction<number>>
) {
    wheelEvent.preventDefault();

    if (wheelEvent.deltaY < 0) {
        const newScale = scale + Math.max(wheelEvent.deltaY, -2) * -0.015;
        setScale(Math.max(MIN_SCALE, newScale));
    } else if (wheelEvent.deltaY > 0) {
        const newScale = scale + Math.min(wheelEvent.deltaY, 2) * -0.015;
        setScale(Math.min(MAX_SCALE, newScale));
    }
}
