import { css } from '@emotion/css';
import cx from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import useMeasure from 'react-use/lib/useMeasure';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { ViewControls } from './ViewControls';
import { EdgeDatum, NodeDatum } from 'types';
import { usePanning, useZoom } from '../utils/movement';
import { processData } from '../utils/preprocessing';
import { NodeGroup } from './NodeGroup';
import { findNodeById, nodeBounds } from 'utils/utils';
import { processLayout } from 'utils/layout';
import { Edge } from './Edge';
import { EdgeRenderOptions, NodeGraphOptions } from 'editor/editor.types';
import { NodeHoverView } from './NodeHoverView';
import { EdgeFields, NodeFields } from 'utils/preprocessing.types';

const getStyles = (theme: GrafanaTheme2) => ({
    wrapper: css({
        label: 'wrapper',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
    }),

    svg: css({
        label: 'svg',
        height: '100%',
        width: '100%',
        overflow: 'visible',
        cursor: 'move',
    }),

    svgPanning: css({
        label: 'svgPanning',
        userSelect: 'none',
    }),

    noDataMsg: css({
        height: '100%',
        width: '100%',
        display: 'grid',
        placeItems: 'center',
        fontSize: theme.typography.h4.fontSize,
        color: theme.colors.text.secondary,
    }),

    mainGroup: css({
        label: 'mainGroup',
        willChange: 'transform',
    }),

    viewControls: css({
        label: 'viewControls',
        position: 'absolute',
        left: '2px',
        bottom: '3px',
        right: 0,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        pointerEvents: 'none',
    }),

    viewControlsWrapper: css({
        marginLeft: 'auto',
    }),
});

export function NodeGraph({
    nodeFields,
    edgeFields,
    options,
}: {
    nodeFields: NodeFields;
    edgeFields: EdgeFields;
    options: NodeGraphOptions;
}) {
    const [initFinished, setInitFinished] = useState(false);
    const styles = useStyles2(getStyles);

    const [measureRef, { width, height }] = useMeasure();

    const { nodes, edges } = useMemo(
        () => processData(nodeFields, edgeFields, options),
        [nodeFields, edgeFields, options]
    );
    useMemo(() => processLayout(nodes), [nodes]);
    const bounds = useMemo(() => nodeBounds(nodes, true), [nodes]);

    const [nodeClickPosition, setNodeClickPositon] = useState<{ x: number; y: number } | undefined>(undefined);

    const [nodeHover, setNodeHover] = useState<string | undefined>(undefined);
    const [nodeHoverLocked, setNodeHoverLocked] = useState<boolean>(false);

    const clearNodeHover = useCallback(() => {
        if (nodeHoverLocked) {
            return;
        }

        setNodeHover(undefined);
    }, [nodeHoverLocked, setNodeHover]);

    const onNodeHover = useCallback(
        (id: string) => {
            if (nodeHoverLocked) {
                return;
            }

            setNodeHover(id);
        },
        [nodeHoverLocked, setNodeHover]
    );

    const {
        scale,
        onStepDown,
        onStepUp,
        onZoomToFitScale,
        ref: zoomRef,
        isMax: isMaxZoom,
        isMin: isMinZoom,
    } = useZoom(options.viewOptions?.scale);
    const topLevelRef = useCallback(
        (ref: HTMLDivElement) => (measureRef(ref), (zoomRef.current = ref)),
        [measureRef, zoomRef]
    );
    const {
        position,
        isPanning,
        onZoomToFitPosition,
        ref: panRef,
    } = usePanning(scale, bounds, options.viewOptions?.position);

    const onNodeClick = useCallback(
        (id: string, context: 'up' | 'down') => {
            if (context === 'up') {
                if (
                    nodeClickPosition !== undefined &&
                    nodeClickPosition.x === Math.round(position.x) &&
                    nodeClickPosition.y === Math.round(position.y)
                ) {
                    setNodeHoverLocked(true);
                    setNodeHover(id);
                }

                return;
            }

            setNodeClickPositon({ x: Math.round(position.x), y: Math.round(position.y) });
        },
        [nodeClickPosition, position, setNodeHoverLocked, setNodeHover, setNodeClickPositon]
    );

    const onZoomToFit = useCallback(() => {
        const widthScale = width / (bounds.right - bounds.left);
        const heightScale = height / (bounds.bottom - bounds.top);

        const ratio = Math.min(widthScale, heightScale);

        onZoomToFitScale(ratio);
        onZoomToFitPosition();
    }, [width, height, bounds, onZoomToFitPosition, onZoomToFitScale]);

    useEffect(() => {
        if (initFinished || width === 0 || height === 0) {
            return;
        }

        if (options.viewOptions?.type === 'Zoom to fit') {
            onZoomToFit();
        }

        setInitFinished(true);
    }, [width, height, initFinished, options.viewOptions, onZoomToFit, onZoomToFitScale]);

    useEffect(() => {
        //! TODO: if the scale is not refreshed on load then it does not allow the use to move the graph
        onZoomToFitScale(scale / 0.9999);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initFinished]);

    if (options.viewOptions) {
        options.viewOptions.scale = scale;
        options.viewOptions.position = position;
    }

    if (nodes.length === 0 || initFinished === false) {
        return (
            <div ref={topLevelRef} className={styles.wrapper}>
                <div className={styles.noDataMsg}>No data</div>
            </div>
        );
    }

    const nodeHovered = nodeHover === undefined ? undefined : findNodeById(nodeHover, nodes);

    return (
        <div ref={topLevelRef} className={styles.wrapper}>
            <svg
                ref={panRef}
                viewBox={`${-(width / 2)} ${-(height / 2)} ${width} ${height}`}
                className={cx(styles.svg, isPanning && styles.svgPanning)}
            >
                <g
                    className={styles.mainGroup}
                    style={{
                        transform: `scale(${scale}) translate(${Math.floor(position.x)}px, ${Math.floor(
                            position.y
                        )}px)`,
                    }}
                >
                    <MemoEges
                        nodes={nodes}
                        edges={edges}
                        options={options.renderOptions?.find(
                            (option): option is EdgeRenderOptions => option.type === 'edge'
                        )}
                        nodeHoveringId={nodeHover}
                    />
                    <MemoNodeGroups
                        nodes={nodes}
                        nodeHoveringId={nodeHover}
                        onClick={onNodeClick}
                        onMouseEnter={onNodeHover}
                        onMouseLeave={clearNodeHover}
                    />
                    <NodeHoverView
                        scale={scale}
                        onClose={() => {
                            setNodeHoverLocked(false);
                            setNodeHover(undefined);
                        }}
                        isClicked={nodeHoverLocked}
                        node={nodeHovered}
                    />
                </g>
            </svg>
            <div className={styles.viewControls}>
                <div className={styles.viewControlsWrapper}>
                    <ViewControls
                        onMinus={onStepDown}
                        onPlus={onStepUp}
                        onZoomToFit={onZoomToFit}
                        disableZoomIn={isMaxZoom}
                        disableZoomOut={isMinZoom}
                    />
                </div>
            </div>
        </div>
    );
}

const MemoEges = memo(Edges);
const MemoNodeGroups = memo(NodeGroups);

interface EdgesProps {
    nodes: NodeDatum[];
    edges: EdgeDatum[];
    options?: EdgeRenderOptions;
    nodeHoveringId?: string;
}

function Edges(props: EdgesProps) {
    const { nodes, edges, options, nodeHoveringId } = props;

    if (options === undefined) {
        return null;
    }

    const hoveredEdges: React.JSX.Element[] = [];

    const edge = (edge: EdgeDatum, index: number) => {
        const startNode = findNodeById(edge.from, nodes);
        const endNode = findNodeById(edge.to, nodes);

        if (!startNode || !endNode || startNode.groupType !== 'node' || endNode.groupType !== 'node') {
            return;
        }

        const hovering = startNode.id === nodeHoveringId || endNode.id === nodeHoveringId;
        const edgeElement = (
            <Edge
                key={index}
                startNode={startNode}
                endNode={endNode}
                nodes={nodes}
                color={options.color}
                highlight={options.highlight}
                hovering={hovering}
                somethingIsHovered={nodeHoveringId !== undefined}
            />
        );

        if (hovering) {
            hoveredEdges.push(edgeElement);
            return null;
        }

        return edgeElement;
    };

    return (
        <>
            {edges.map(edge)}
            {...hoveredEdges}
        </>
    );
}

interface NodeGroupsProps {
    nodes: NodeDatum[];
    nodeHoveringId?: string;
    onClick: (id: string, context: 'up' | 'down') => void;
    onMouseEnter: (id: string) => void;
    onMouseLeave: (id: string) => void;
}

function NodeGroups(props: NodeGroupsProps) {
    const { nodes, nodeHoveringId, onClick, onMouseEnter, onMouseLeave } = props;

    return (
        <>
            {nodes.map((node, index) => {
                return (
                    <NodeGroup
                        key={index}
                        node={node}
                        nodeHoveringId={nodeHoveringId}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    />
                );
            })}
        </>
    );
}
