import { DataFrame } from '@grafana/data';
import { GraphQLResponse, QueryType, WideSkyQueryJSONTarget } from 'types';

export const RealTimeQuery: QueryType = {
    // Grafana is pretty strict on what can be passed down from
    // a datasource to its panel.
    // For Grafana 8.x, only objects that fulfill the timeSeries or table datasets
    // contracts are allowed to be transferred down to a panel.
    //
    // Problem with timeSeries and table datasets is that they
    // can only house simple data types, array of string and numbers.
    //
    // This is not sufficient for Widesky's use.
    //
    // Fortunately the noted datasets can actually be 'extended' to
    // include some meta data and its type is a key/value pair one.
    //
    // So for Widesky to pass down its complex data down to the panels,
    // its dataset must be disguised to look like a table dataset.
    // Then the important bit of data will be attached to the meta data field of it.
    //
    // Note that, this 'abuse' approach is also use by the official Loki datasource.
    createResponse(target: WideSkyQueryJSONTarget, graphQLRoot: Readonly<GraphQLResponse>) {
        const response: DataFrame = {
            fields: [],
            length: 0,
            // the real bit
            meta: {
                custom: {
                    data: {
                        queryName: target.refId,
                        query: graphQLRoot,
                        type: 'widesky',
                    },
                },
            },
        };

        return [response];
    },
};
